import React, { createContext, useState } from 'react';

const FilterOrderContext = createContext();

function FilterOrderProvider(props) {
  const [brands, setBrands] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [models, setModels] = useState([]);
  const [cities, setCities] = useState([]);
  const [price, setPrice] = useState([]);
  const [types, setTypes] = useState([]);
  const [priceFrom, setPriceFrom] = useState(0);
  const [priceTo, setPriceTo] = useState(0);
  const [odoFrom, setOdoFrom] = useState(0);
  const [odoTo, setOdoTo] = useState(0);
  const [colors, setColors] = useState([]);
  const [odo, setOdo] = useState([]);

  return (
    <FilterOrderContext.Provider
      value={{
        brands,
        setBrands,
        statuses,
        setStatuses,
        cities,
        setCities,
        models,
        setModels,
        price,
        setPrice,
        types,
        setTypes,
        odo,
        setOdo,
        colors,
        setColors,
        priceFrom,
        setPriceFrom,
        priceTo,
        setPriceTo,
        odoFrom,
        setOdoFrom,
        odoTo,
        setOdoTo
      }}
    >
      {props.children}
    </FilterOrderContext.Provider>
  );
}

export { FilterOrderContext, FilterOrderProvider };
