import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { registerMobile } from '../../assets/icons';
import { toastConfig, validateSaudiPhone } from '../../helpers/helper';
import { AddContactUs } from '../../services/apis/contactUs';
import { Button } from '../index';
import './../component.css';
const RegisterMobile = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState('');

  const addContactUs = async () => {
    setLoading(true);
    if (!phone) {
      toast.error(t('contactUsForm.phone'), toastConfig, setLoading(false));
    } else if (!validateSaudiPhone(phone)) {
      toast.error(
        t('contactUsForm.phoneFormat'),
        toastConfig,
        setLoading(false)
      );
    } else {
      let data = {
        fullName: null,
        phoneNumber: '966' + phone.slice(1),
        email: null,
        message: null,
        type: 2,
      };
      try {
        const response = await AddContactUs(data);
        console.log('Success:', response);
        toast.success(t('تم ارسال بنجاح'), toastConfig, setLoading(false));
      } catch (error) {
        if (error.response) {
          if (error.response.status == 400) {
            toast.error(
              error.response.data.message,
              toastConfig,
              setLoading(false)
            );
          } else if (error.response.status == 500) {
            toast.error(
              error.response.data.message,
              toastConfig,
              setLoading(false)
            );
          } else if (error.response.status == 403) {
            toast.error(
              error.response.data.message,
              toastConfig,
              setLoading(false)
            );
          }
        }
      }
    }
  };
  return (
    <div className="bg-[#FFE9334F] w-[90%] mr-auto ml-auto mt-20 p-[42px] rounded-[10px]">
      <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
        <div className="flex items-center justify-center flex-col">
          <div className="font-[700px] text-[23px] text-[#3E0292] text-right">
            سجل جوالك ليتم اشعارك بكل جديد
          </div>
          <div className="mt-5 grid max600:grid-cols-1 grid-cols-[40%_60%] ">
            <Button
              dark={true}
              title={'إرسال'}
              onClick={addContactUs}
              loading={loading}
            />

            <input
              placeholder="اكتب جوالك"
              className="rounded-[10px] border-white p-3"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
            />
          </div>
        </div>
        <div className="flex items-center justify-center flex-col relative">
          <img src={registerMobile} className="registerMobile" />
        </div>
      </div>
    </div>
  );
};

export default RegisterMobile;
