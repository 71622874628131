import React, { createContext, useState } from 'react';

const CompleteOfferContext = createContext();

function CompleteOfferProvider(props) {
  const [mediatorId, setMediatorId] = useState(null);
  const [mediator, setMediator] = useState('');
  const [offer, setOffer] = useState('');
  const [selectedCars, setSelectedCars] = useState([]);

  return (
    <CompleteOfferContext.Provider
      value={{
        mediatorId,
        setMediatorId,
        mediator,
        setMediator,
        offer,
        setOffer,
        selectedCars,
        setSelectedCars,
      }}
    >
      {props.children}
    </CompleteOfferContext.Provider>
  );
}

export { CompleteOfferContext, CompleteOfferProvider };
