import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { phone } from '../assets/icons';
import InputRHF from '../components/inputs/InputRHF';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { signUpSchema } from '../schema/auth';
import { requestOtp, register } from '../services/auth';
import MediatorPopup from './MediatorPopup';
import RegistrationSuccess from './shared/RegistrationSuccess';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

const SignUpForm = () => {
  const { t } = useTranslation();
  const [selectedClientType, setSelectedClientType] = useState(2);
  const [isModal, setIsModal] = useState(false);
  const [isRegisterSuccess, setIsRegisterSuccess] = useState(false);
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);

  const CloseModal = () => {
    setIsModal(false);
  };

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({ resolver: zodResolver(signUpSchema) });

  const handleSignUp = async () => {
    const { phoneNumber, password } = getValues();

    const result = signUpSchema.safeParse({ phoneNumber, password });

    if (!selectedClientType) {
      alert('Please select a client type.');
      return;
    }

    if (!result.success) {
      toast.error(result.error.errors[0].message, { position: 'top-right' });
      return;
    }

    const { phoneNumber: transformedPhoneNumber, password: validPassword } =
      result.data;

    try {
      const otpResponse = await requestOtp(transformedPhoneNumber);
      const otpCode = otpResponse;

      const roleId = selectedClientType;
      const data = {
        phoneNumber: transformedPhoneNumber,
        password: validPassword,
        otpCode,
        roleId,
        imgUrl: '',
      };

      const registrationResponse = await register(data);
      console.log('Registration response:', registrationResponse);

      if (registrationResponse && registrationResponse.id_token) {
        // Success: Store the token and user data
        localStorage.setItem('token', registrationResponse.id_token);
        localStorage.setItem(
          'user_role',
          JSON.stringify(registrationResponse.user_role)
        );
        localStorage.setItem(
          'userRole',
          registrationResponse.user_role?.roleId
        );

        setUserData({
          phoneNumber: transformedPhoneNumber,
          role: registrationResponse.user_role.roleName,
          token: registrationResponse.id_token,
        });

        setIsRegisterSuccess(true);

        setTimeout(() => {
          setIsRegisterSuccess(false);
          navigate('/');
        }, 2000);
      } else {
        toast.error('Registration failed, please try again.', {
          position: 'top-right',
        });
      }
    } catch (error) {
      // Handle actual errors, not status 201 (success)
      console.log('Error during registration:', error);
      toast.error('Sign-up failed, please try again.', {
        position: 'top-right',
      });
    }
  };

  return (
    <div className="rounded-lg max600:order-2 w-full max-w-md space-y">
      <h2 className="text-2xl font-bold text-center text-customPurple sm:mt-10">
        {t('login.label.welcome')}
      </h2>
      <h4 className="text-l text-center mt-3">
        {t('login.label.createNewAccount')}
      </h4>
      <form onSubmit={handleSubmit(handleSignUp)} className="mt-9">
        <div className="mt-9">
          <InputRHF
            label={t('contactUs.label.phone')}
            type="text"
            icon={phone}
            placeholder={t('contactUs.placeholder.phone')}
            {...formRegister('phoneNumber')}
            error={errors.phoneNumber?.message}
          />
        </div>
        <div className="flex space-x-4 justify-center mt-5 rtl:space-x-reverse">
          <button
            type="button"
            className={`px-16 py-4 rounded-lg ${
              selectedClientType === 2
                ? 'bg-teal-100 border-teal-500'
                : 'bg-gray-100'
            }`}
            onClick={() => setSelectedClientType(2)}
          >
            {t('login.label.buyer')}
          </button>

          <button
            type="button"
            className={`px-16 py-4 rounded-lg ${
              selectedClientType === 3
                ? 'bg-teal-200 border-teal-500'
                : 'bg-gray-100'
            }`}
            onClick={() => setSelectedClientType(3)}
          >
            {t('login.label.broker')}
          </button>
        </div>
        <div className="mt-5">
          <InputRHF
            label={t('login.label.password')}
            type="password"
            icon={phone}
            placeholder={t('login.placeholder.password')}
            {...formRegister('password')}
            error={errors.password?.message}
          />
        </div>
        <div className="mt-5">
          <InputRHF
            label={t('login.label.confirmPass')}
            type="password"
            icon={phone}
            placeholder={t('login.placeholder.confirmPass')}
            {...formRegister('confirmPass')}
            error={errors.confirmPass?.message}
          />
        </div>
        <p className="text-start text-gray-500 mt-5">
          <input type="checkbox" className="form-checkbox mr-2 ml-2" />
          {/* {t('login.label.confirm')} */}
          <a
            href="#"
            className="text-sm text-[#3E0292] font-normal hover:underline ml-1"
          >
            {t('login.label.terms')}
          </a>
        </p>

        <div className="flex justify-center mt-5">
          <button
            type="submit"
            className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[15px] text-white px-10 py-4 w-[70%] shadow-lg text-center w-64"
          >
            {t('login.label.createNewAccount')}
          </button>
        </div>
      </form>

      <p className="text-center text-gray-500 mt-5">
        {t('login.label.NoAccount')}
        <a
          href="/login"
          className="text-[#3E0292] font-normal hover:underline ml-1"
        >
          {t('login.label.login')}
        </a>
      </p>
      <MediatorPopup isOpen={isModal} isClose={CloseModal} />
      <RegistrationSuccess isOpen={isRegisterSuccess} />
    </div>
  );
};

export default SignUpForm;
