import { formatMessageTime } from '../../utils/messageUtils';

const Message = ({ sender = true, message, showAvatar = true, userInfo }) => {
  const isSender = message.senderId === userInfo?.userId;

  const renderContent = () => {
    if (message.image) {
      return (
        <img
          src={message.image}
          alt="Shared image"
          className="max-w-[200px] rounded-md hover:opacity-90 cursor-pointer transition-opacity"
          onClick={() => {
            const win = window.open();
            win.document.write(`
              <html>
                <head>
                  <title>Image Preview</title>
                  <style>
                    body {
                      margin: 0;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      min-height: 100vh;
                      background: #000;
                    }
                    img {
                      max-width: 100%;
                      max-height: 100vh;
                      object-fit: contain;
                    }
                  </style>
                </head>
                <body>
                  <img src="${message.image}" alt="Full size image"/>
                </body>
              </html>
            `);
          }}
        />
      );
    }

    if (message.file) {
      const fileUrl = Array.isArray(message.file)
        ? message.file[0]
        : message.file;
      return (
        <div className="flex items-center space-x-2 bg-white/30 rounded-md p-2">
          <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
            <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" />
          </svg>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const win = window.open();
              win.document.write(`
                <html>
                  <head>
                    <title>${message.fileName || 'File Preview'}</title>
                    <style>
                      body {
                        margin: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        min-height: 100vh;
                        background: #fff;
                        padding: 20px;
                      }
                      .content {
                        max-width: 100%;
                        word-break: break-all;
                      }
                    </style>
                  </head>
                  <body>
                    <iframe 
                      src="${fileUrl}" 
                      style="position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;" 
                      frameborder="0"
                    ></iframe>
                  </body>
                </html>
              `);
            }}
            className="text-blue-600 hover:text-blue-800 underline transition-colors"
          >
            {message.fileName || 'View file'}
          </a>
        </div>
      );
    }

    const messageText =
      typeof message.text === 'object' ? message.text.message : message.text;
    return <span className="break-words">{messageText || 'No message'}</span>;
  };

  return (
    <div
      className={`flex items-end gap-2 my-1 ${
        isSender ? 'justify-start' : 'justify-end'
      }`}
    >
      {/* {!isSender && (
        <img
          src={message.senderImage || '/default-avatar.png'}
          alt="avatar"
          className="w-8 h-8 rounded-full object-cover"
        />
      )} */}

      <div className="flex flex-col max-w-[70%]">
        {!isSender && (
          <span className="text-xs text-gray-500 ml-2 mb-1">
            {message.senderName}
          </span>
        )}

        <div
          className={`
            flex flex-col p-3 rounded-2xl
            ${
              isSender
                ? 'bg-[#00CEBC4F] rounded-tr-none'
                : 'bg-[#EFEFEF] rounded-tl-none'
            }
          `}
        >
          {renderContent()}
          <span className="text-[10px] text-gray-500 self-end mt-1">
            {formatMessageTime(message.createdAt)}
          </span>
        </div>
      </div>

      {/* {isSender && showAvatar && (
        <img
          src={message.senderImage || '/default-avatar.png'}
          alt="avatar"
          className="w-8 h-8 rounded-full object-cover"
        />
      )} */}
    </div>
  );
};

export default Message;
