const CheckBox = ({ checked, type = 'checkbox', handleChange, value ,name}) => {
  return (
    <input
      type={type}
      checked={checked}
      className="appearance-none w-5 h-5 rounded-[5px] border border-[#3E0292] checked:bg-[#3E0292] checked:border-transparent focus:outline-none font-400"
      onChange={handleChange}
      value={value}
      name={name}
    />
  );
};

export default CheckBox;
