import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { carIcon } from '../../assets/icons';
import { user } from '../../assets/imgs';
import { defaultLettersCircle, userToken } from '../../helpers/helper';

const GeneralSwiper = ({ car = false, users }) => {
  const navigate = useNavigate();
  const swiperRef = useRef(null);

  const scrollLeft = () => {
    swiperRef.current.scrollBy({ left: -300, behavior: 'smooth' });
  };

  const scrollRight = () => {
    swiperRef.current.scrollBy({ left: 300, behavior: 'smooth' });
  };
  return (
    <div className="relative w-full">
      {/* Left Button */}
      <button
        className="absolute left-5 top-1/2 transform -translate-y-1/2 bg-gray-200 rounded-full p-2"
        onClick={scrollLeft}
      >
        &#8592;
      </button>

      {/* Swiper Container */}
      <div
        ref={swiperRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          width: '100%',
          whiteSpace: 'nowrap',
          alignItems: 'center',
        }}
        // className="flex space-x-4 overflow-x-scroll scrollbar-hide flex justify-center items-center"
      >
        {users?.map((user, index) => (
          <div
            key={user?.id || index}
            className={`p-4 mx-2 rounded-xl shadow-md flex items-center flex-col justify-between font-700 ${
              user.highlighted
                ? 'bg-[#3E0292] text-white w-[300px] h-[300px]'
                : 'bg-[#D7E2FF] text-[#3E0292] w-64 h-48'
            } cursor-pointer`}
            onClick={() => userToken ? navigate(`/profile-info/${user.id}`) : console.log("no action")}
          >
            {car ? (
              <h3 className="text-center font-semibold mb-2 text-[20px]">
                {user.name}
              </h3>
            ) : null}
            {user.img == null ? (
              defaultLettersCircle(user.name, 'w-16', 'h-16')
            ) : (
              <img
                src={user.car == true ? carIcon : user.img}
                alt={user.name}
                className={`${
                  user.car ? 'w-[80%]' : 'w-16 h-16'
                } rounded-full mx-auto mb-4 shadow-lg`}
              />
            )}
            <div>
              {user.car ? null : (
                <h3 className="text-center font-semibold mb-2">{user.name}</h3>
              )}

              {user.car == true ? null : (
                <div className="flex justify-center mt-2">
                  {Array.from({ length: user.stars }).map((_, index) => (
                    <svg
                      key={index}
                      className="w-5 h-5 text-yellow-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.092 3.362a1 1 0 00.95.69h3.542c.969 0 1.371 1.24.588 1.81l-2.865 2.08a1 1 0 00-.364 1.118l1.092 3.362c.3.921-.755 1.688-1.54 1.118L10 13.477l-2.865 2.08c-.784.57-1.838-.197-1.54-1.118l1.092-3.362a1 1 0 00-.364-1.118L3.458 8.789c-.783-.57-.381-1.81.588-1.81h3.542a1 1 0 00.95-.69l1.092-3.362z" />
                    </svg>
                  ))}
                </div>
              )}
            </div>
            {car == true ? null : (
              <>
                {user.text && (
                  <p className="text-center text-sm mt-3">{user.text}</p>
                )}
              </>
            )}
          </div>
        ))}
      </div>

      {/* Right Button */}
      <button
        className="absolute right-5 top-1/2 transform -translate-y-1/2 bg-gray-200 rounded-full p-2"
        onClick={scrollRight}
      >
        &#8594;
      </button>
    </div>
  );
};

export default GeneralSwiper;
