import Environment from '../Environment';
import mainService from '../mainService';

export function getBrands(data) {
  return mainService
    .get(Environment.allBrands, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getModels(data, brandId) {
  return mainService
    .get(Environment.modelsOfBrand + '/' + brandId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getCities(data) {
  return mainService
    .get(Environment.cities, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getMediators(data) {
  return mainService
    .get(Environment.topRatedMediators, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getAllModels(data) {
  return mainService
    .get(Environment.allmodels, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}
