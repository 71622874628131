import React from 'react';
import { useState, useRef, useEffect } from 'react';
import { useController } from 'react-hook-form';
import { Search, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

const FDropdown = ({
  control,
  name,
  options,
  groupedOptions,
  label,
  placeholder,
  icon,
  rules = {},
  error,
}) => {
  const { t } = useTranslation();
  const { field } = useController({ control, name, rules });
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSelectAll, setIsSelectAll] = useState(false);
  const handleToggle = () => setIsOpen(!isOpen);
  const dropdownRef = useRef(null);

  const handleOptionChange = (option) => {
    if (name === 'carBrands') {
      // Handle brand selection with 3 brand limit
      if (Array.isArray(field.value) && field.value.includes(option)) {
        const updatedValue = field.value.filter((item) => item !== option);
        field.onChange(updatedValue);
      } else {
        if (Array.isArray(field.value) && field.value.length >= 3) {
          toast.warning(
            t('addOffer.maxBrandsLimit') || 'Maximum 3 brands can be selected'
          );
          return;
        }
        const updatedValue = [...(field.value || []), option];
        field.onChange(updatedValue);
      }
    } else if (name === 'carType') {
      // Get current selected models
      const currentValue = field.value || [];

      // If deselecting
      if (currentValue.includes(option)) {
        field.onChange(currentValue.filter((val) => val !== option));
        return;
      }

      // Check total number of selected models across all brands
      if (currentValue.length >= 3) {
        toast.warning(
          t('addOffer.maxModelLimit') || 'Maximum 3 models can be selected in total'
        );
        return;
      }

      // Add new model
      field.onChange([...currentValue, option]);
    } else if (name === 'city') {
      if (Array.isArray(field.value) && field.value.includes(option)) {
        const updatedValue = field.value.filter((item) => item !== option);
        field.onChange(updatedValue);
      } else {
        if (Array.isArray(field.value) && field.value.length >= 3) {
          toast.warning(
            t('addOffer.maxCitiesLimit') || 'Maximum 3 cities can be selected'
          );
          return;
        }
        const updatedValue = [...(field.value || []), option];
        field.onChange(updatedValue);
      }
    } else if (name === 'color') {
      if (Array.isArray(field.value) && field.value.includes(option)) {
        const updatedValue = field.value.filter((item) => item !== option);
        field.onChange(updatedValue);
      } else {
        if (Array.isArray(field.value) && field.value.length >= 3) {
          toast.warning(
            t('addOffer.maxColorsLimit') || 'Maximum 3 colors can be selected'
          );
          return;
        }
        const updatedValue = [...(field.value || []), option];
        field.onChange(updatedValue);
      }
    } else if (groupedOptions) {
      // This is for model selection
      const brandId = groupedOptions.find((group) =>
        group.models.some((model) => model.value === option)
      )?.brandId;

      if (!brandId) return;

      // Get current selected models
      const currentValue = field.value || [];

      // If deselecting
      if (currentValue.includes(option)) {
        field.onChange(currentValue.filter((val) => val !== option));
        return;
      }

      // Check if there's already a model selected for this brand
      const existingModelForBrand = currentValue.find((modelId) => {
        const modelBrandId = groupedOptions.find((group) =>
          group.models.some((model) => model.value === modelId)
        )?.brandId;
        return modelBrandId === brandId;
      });

      if (existingModelForBrand) {
        // Replace existing model for this brand
        const newValue = currentValue.filter(
          (val) => val !== existingModelForBrand
        );
        field.onChange([...newValue, option]);
        toast.info(
          t('addOffer.modelReplaced') ||
            'Previous model for this brand has been replaced'
        );
      } else {
        // Check if we already have a model for this brand
        const existingModelCount = currentValue.filter((modelId) => {
          const modelBrandId = groupedOptions.find((group) =>
            group.models.some((model) => model.value === modelId)
          )?.brandId;
          return modelBrandId === brandId;
        }).length;

        if (existingModelCount > 0) {
          toast.warning(
            t('addOffer.maxModelLimit') ||
              'Only one model can be selected per brand'
          );
          return;
        }
        // Add new model
        field.onChange([...currentValue, option]);
      }
    } else {
      // Handle other dropdowns normally
      if (Array.isArray(field.value) && field.value.includes(option)) {
        const updatedValue = field.value.filter((item) => item !== option);
        field.onChange(updatedValue);
      } else {
        const updatedValue = [...(field.value || []), option];
        field.onChange(updatedValue);
      }
    }
  };

  const handleSelectAllChange = () => {
    const allModelIds = groupedOptions
      ? groupedOptions?.flatMap((group) =>
          group?.models?.map((model) => model.value)
        )
      : options.map((opt) => opt.value);

    if (field?.value?.length === allModelIds.length) {
      // Unselect all
      field.onChange([]);
      setIsSelectAll(false);
    }

    // else {
    //   // Select all
    //   field.onChange(allModelIds);
    //   setIsSelectAll(true); // Set select all as true
    // }
  };

  const filteredGroupedOptions = groupedOptions
    ? groupedOptions
        .map((group) => ({
          ...group,
          models: group.models.filter((model) =>
            model.label.toLowerCase().includes(searchTerm.toLowerCase())
          ),
        }))
        .filter((group) => group.models.length > 0)
    : options.filter(
        (option) =>
          typeof option?.label === 'string' &&
          option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
      );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex items-center gap-2">
        {icon && <img src={icon} alt="" />}
        {label && (
          <label className="block text-sm font-400 text-[#3E0292]">
            {label}
          </label>
        )}
      </div>

      <div className="mt-1 relative">
        <div
          className=" w-full bg-white border border-gray-300 rounded-lg shadow-sm flex items-center justify-between px-3 py-2 cursor-pointer"
          onClick={handleToggle}
        >
          {/* <div className="flex flex-wrap gap-2">
            {field?.value?.length > 0 ? (
              field.value.map((value) => {
                const option =
                  options.find((opt) => opt.value === value) ||
                  (groupedOptions || [])
                    .flatMap((g) => g.models)
                    .find((opt) => opt.value === value);
                return (
                  <div
                    key={value}
                    className="flex items-center bg-white border-2 border-blue-300 rounded-full px-2 py-1 text-sm gap-2"
                  >
                    {option?.label}
                    <X
                      className="ml-2 h-4 w-4 cursor-pointer text-blue-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange(value);
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <span className="text-gray-400">{placeholder}</span>
            )}
          </div> */}

          <div className="flex flex-wrap gap-2">
            {isSelectAll ? (
              <div className="flex items-center bg-white border-2 border-blue-300 rounded-full px-2 py-1 text-sm gap-2">
                Selected All
                <X
                  className="ml-2 h-4 w-4 cursor-pointer text-blue-400"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                />
              </div>
            ) : field?.value?.length > 0 ? (
              field.value.map((value) => {
                const option =
                  options.find((opt) => opt.value === value) ||
                  (groupedOptions || [])
                    .flatMap((g) => g.models)
                    .find((opt) => opt.value === value);
                return (
                  <div
                    key={value}
                    className="flex items-center bg-white border-2 border-blue-300 rounded-full px-2 py-1 text-sm gap-2"
                  >
                    {option?.label}
                    <X
                      className="ml-2 h-4 w-4 cursor-pointer text-blue-400"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOptionChange(value);
                      }}
                    />
                  </div>
                );
              })
            ) : (
              <span className="text-gray-400">{placeholder}</span>
            )}
          </div>

          <svg
            className="h-5 w-5"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>

        {isOpen && (
          <div className="absolute mt-1 p-4 w-full bg-[#BAE8EF] border border-gray-300 rounded-3xl shadow-lg z-10 overflow-hidden">
            <div className="p-2 sticky top-0 bg-[#BAE8EF] z-10">
              <div className="relative">
                <input
                  type="text"
                  placeholder={t('addOffer.search')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="block w-full bg-white border border-gray-300 rounded-full p-2 pr-10 focus:outline-none focus:ring-2 focus:ring-blue-500 font-400"
                />
                <Search
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 text-[#BAE8EF]"
                  size={20}
                />
              </div>
            </div>

            <div className="max-h-60 overflow-y-auto custom-scrollbar ">
              <div className="p-2">
                {/* <label className="flex items-center text-[#3E0292]  mb-2 gap-2">
                  <input
                    type="checkbox"
                    checked={
                      Array.isArray(field.value) &&
                      (groupedOptions
                        ? field?.value?.length ===
                          groupedOptions.reduce(
                            (acc, group) => acc + group.models.length,
                            0
                          )
                        : field.value.length === options.length)
                    }
                    // onChange={() => {
                    //   const allModelIds = groupedOptions
                    //     ? groupedOptions?.flatMap((group) =>
                    //         group?.models?.map((model) => model.value)
                    //       )
                    //     : options.map((opt) => opt.value);
                    //   field.onChange(
                    //     field?.value?.length === allModelIds?.length
                    //       ? []
                    //       : allModelIds
                    //   );
                    // }}
                    onChange={handleSelectAllChange}
                    className="appearance-none w-5 h-5 rounded-[5px] border border-[#3E0292] checked:bg-[#3E0292] checked:border-transparent focus:outline-none"
                  />
                  <span className="text-sm font-bold ">
                    {' '}
                    {t('addOffer.selectAll')}
                  </span>
                </label> */}

                {groupedOptions && groupedOptions.length > 0
                  ? filteredGroupedOptions.map((group) => (
                      <div key={group.brandId}>
                        <div className="font-normal  mb-2 mt-4 bg-blue-200 p-2 rounded-lg gap-2">
                          {group.brandLabel}
                          <span> ({t('addOffer.selectOneModelAtLeast')})</span>
                        </div>
                        {group.models.map((option) => (
                          <label
                            key={option.value}
                            className="flex items-center mb-2 gap-2 pl-4"
                          >
                            <input
                              type="checkbox"
                              checked={
                                Array.isArray(field.value) &&
                                field.value.includes(option.value)
                              }
                              onChange={() => handleOptionChange(option.value)}
                              className="appearance-none w-5 h-5 rounded-[5px] border border-[#3E0292] checked:bg-[#3E0292] checked:border-transparent focus:outline-none"
                            />
                            {option.icon && (
                              <img
                                src={option.icon}
                                alt={option.label}
                                className="ml-2 h-5 w-5"
                              />
                            )}
                            <span className="text-lg">{option.label}</span>
                          </label>
                        ))}
                      </div>
                    ))
                  : options
                      .filter(
                        (option) =>
                          typeof option?.label === 'string' &&
                          option?.label
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                      )
                      .map((option) => (
                        <label
                          key={option.value}
                          className="flex items-center mb-2 gap-2"
                        >
                          <input
                            type="checkbox"
                            checked={
                              Array.isArray(field.value) &&
                              field.value.includes(option.value)
                            }
                            onChange={() => handleOptionChange(option.value)}
                            className="appearance-none w-5 h-5 rounded-[5px] border border-[#3E0292] checked:bg-[#3E0292] checked:border-transparent focus:outline-none"
                          />
                          {option.icon && (
                            <img
                              src={option.icon}
                              alt={option.label}
                              className="ml-2 h-5 w-5"
                            />
                          )}
                          <span className="text-lg">{option.label}</span>
                        </label>
                      ))}
              </div>
            </div>

            <div className="flex justify-center gap-4 p-2 bg-[#BAE8EF] sticky bottom-0 mt-2">
              <button
                onClick={handleToggle}
                className="bg-[#00CEBC] text-white px-10 py-2 rounded-md text-base font-500	"
              >
                {t('addOffer.add')}
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  field.onChange([]);
                  setIsSelectAll(false);
                  handleToggle();
                }}
                className="bg-[#FF4342] text-white px-10 py-2 rounded-md text-base font-500	"
              >
                {t('addOffer.cancel')}
              </button>
            </div>
          </div>
        )}
      </div>

      {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
    </div>
  );
};

export default FDropdown;
