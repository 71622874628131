import { useTranslation } from 'react-i18next';
import { userRole, userToken } from '../../helpers/helper';
import { getOrdersStatusStatisticsMediators } from '../../services/apis/order';
import { useEffect, useState } from 'react';
import CardSkeleton from '../skeleton/CardSkeleton';
import { useNavigate } from 'react-router-dom';

const WhyMshrai = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStatisticsOrderMediators();
  }, []);

  const getStatisticsOrderMediators = async () => {
    !userToken ? setLoading(false) : setLoading(true);
    const response = await getOrdersStatusStatisticsMediators('');
    console.log(response);
    setStatistics(response.data, setLoading(false));
  };
  return (
    <div className="w-[90%] mr-auto ml-auto mt-10">
      {!userToken && (
        <div className="font-bold text-[50px] text-[#00CEBC]">
          {t('aboutMshraiHomePage.whyMshrai')}
        </div>
      )}
      {loading ? (
        <div
          className={
            !userToken
              ? 'grid md:grid-cols-3 md:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 gap-x-4 mt-5'
              : 'grid md:grid-cols-2 md:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5'
          }
        >
          <CardSkeleton number={!userToken ? 3 : 2} height={'100px'} />
        </div>
      ) : (
        <div
          className={
            !userToken
              ? 'grid md:grid-cols-3 md:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 gap-x-4 mt-5'
              : 'grid md:grid-cols-2 md:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5'
          }
        >
          <div className="bg-[#FFF8C0] rounded-[10px] p-3 text-center w-[100%] max600:mt-5">
            <div className="flex items-center justify-around">
              <div className="bg-[#444444] w-[5px] h-[5px] rounded-full"></div>
              {userRole == 3 && userToken ? (
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() =>
                    navigate('/orders', {
                      state: { filter: 'un_offered_interested_requests' },
                    })
                  }
                >
                  <div className="text-[#444444] text-[16px] font-700">
                    {t('aboutMshraiHomePage.found')}
                  </div>
                  <div className="text-[#3E0292] text-[16px] font-700 mx-3">
                    {statistics.unOfferedInterestRequestsCount}
                  </div>
                  <div className="text-[#444444] text-[16px] font-700">
                    {t('aboutMshraiHomePage.orders')}
                  </div>
                </div>
              ) : (
                <div className="text-[#444444] text-[16px] font-700">
                  {t('aboutMshraiHomePage.service')}
                </div>
              )}
            </div>
          </div>
          <div className="bg-[#FFF8C0] rounded-[10px] p-3 text-center w-[100%] max600:mt-5">
            <div className="flex items-center justify-around">
              <div className="bg-[#444444] w-[5px] h-[5px] rounded-full"></div>
              {userRole == 3 && userToken ? (
                <div
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() =>
                    navigate('/orders', { state: { dateOption: 'today' } })
                  }
                >
                  <div className="text-[#444444] text-[16px] font-700">
                    {t('aboutMshraiHomePage.add')}
                  </div>
                  <div className="text-[#3E0292] text-[16px] font-700 mx-3">
                    {statistics.openBuyerRequestsCountToday}
                  </div>
                  <div className="text-[#444444] text-[16px] font-700">
                    {t('aboutMshraiHomePage.ordersBuyer')}
                  </div>
                </div>
              ) : (
                <div className="text-[#444444] text-[16px] font-700">
                  {t('aboutMshraiHomePage.request')}
                </div>
              )}
            </div>
          </div>
          {!userToken && (
            <div className="bg-[#FFF8C0] rounded-[10px] p-3 text-center w-[100%] max600:mt-5">
              <div className="flex items-center justify-around">
                <div className="bg-[#444444] w-[5px] h-[5px] rounded-full"></div>
                <div className="text-[#444444] text-[16px] font-700">
                  {t('aboutMshraiHomePage.mediator')}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default WhyMshrai;
