import GInput from '../../inputs/GInput';
import { arrowLeftOrder } from '../../../assets/icons';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import Button from '../../buttons/Button';
import { FilterOrderContext } from '../../../context/FilterOrderContext';
import { languageAr } from '../../../helpers/helper';
import { getAllModels, getModels } from '../../../services/apis/home';
import { useTranslation } from 'react-i18next';
const items = ['النترا', 'تست'];
const Model = ({ back }) => {
  const { t } = useTranslation();
  const filterOrderContext = useContext(FilterOrderContext);
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { modelIds: [] },
  });
  const [selectedModels, setSelectedModels] = useState([]);
  const [models, setModels] = useState([]);

  const handleModelChange = (model) => {
    const currentModels = getValues('models') || [];

    const updatedModels = currentModels.some(
      (selected) => selected.value === model.value
    )
      ? currentModels.filter((i) => i.value !== model.value)
      : [...currentModels, model];
    setSelectedModels(updatedModels);
    filterOrderContext.setModels(updatedModels);

    setValue('models', updatedModels);
  };

  useEffect(() => {
    getModels();
  }, []);

  useEffect(() => {
    setSelectedModels(filterOrderContext.models);
  }, [filterOrderContext.models]);

  const getModels = async () => {
    const response = await getAllModels('');
    let newModels = [];
    console.log(response);
    const brands = filterOrderContext?.brands?.map((item) => item.value);
    const mergedArray = [];
    return new Promise((resolve) => {
      let data = {};
      brands?.forEach((item) => {
        data[item] = response[item];
        mergedArray?.push(...data[item]);
      });
      let newModels = [];
      mergedArray?.forEach((element) => {
        newModels.push({
          value: element.id,
          label: languageAr ? element.nameAr : element.nameEn,
        });
      });
      setModels(newModels);
      console.log(mergedArray);
      resolve({ data });
    });
  };

  const onSubmit = (data) => {
    filterOrderContext.setModels(data.models, back());
  };
  return (
    <div>
      <img
        src={arrowLeftOrder}
        className="my-2 cursor-pointer"
        onClick={back}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <GInput
          title={t('filterModal.selectModel')}
          items={models}
          type={'modelIds'}
          selectedItems={selectedModels}
          handleCheckboxChange={handleModelChange}
          register={register}
        />
        <div className="grid grid-cols-2 gap-x-2 mt-10">
          <Button
            type="submit"
            dark={false}
            className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]"
            title={t('filterModal.confirm')}
          />
          <Button
            type="submit"
            dark={false}
            className="bg-[#FF4342] border-[#FF4342] rounded-[10px]"
            title={t('filterModal.cancel')}
          />
        </div>
      </form>
    </div>
  );
};

export default Model;
