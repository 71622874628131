import { Spinner } from 'flowbite-react';

const ButtonBadge = ({
  className,
  title,
  dark,
  onClick,
  type = 'button',
  value,
  loading = false,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${dark == true ? `bg-[#3E0292] border border-[#3E0292] rounded-[14px] px-5 py-2` : className} border rounded-[14px] px-5 py-2 flex flex-row items-center justify-center`}
    >
      <div className="text-[15px] text-white font-[Noto Sans Arabic] px-2">
        <div className="flex flex-row items-center justify-around">
          <div className="font-500">{title}</div>
          {loading ? (
            <Spinner color="success" aria-label="Default status example" />
          ) : null}
        </div>
      </div>
      <div className="bg-[#FFFFFFB2] rounded-[5px] text-[#3E0292] text-[16px] font-[500px] flex items-center justify-center w-[28px] h-[25px]">
        {value}
      </div>
    </button>
  );
};

export default ButtonBadge;
