import Environment from '../Environment';
import mainService from '../mainService';

export function uploadFiles(data) {
  return mainService
    .post(Environment.uploadFiles, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}
