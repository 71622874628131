import {
  Home,
  Privacy,
  Terms,
  ContactUs,
  AboutUs,
  Login,
  SignUp,
  Orders,
  AddOrder,
  Profile,
  ForgetPassword,
  Order,
  Offers,
  MOffers,
  MAddOffer,
  MEditOffer,
  Favourites,
  Offer,
  OrderStatistics,
  VerifyOTP,
  notifications,
  Chat,
  CarOwner,
  CarOffersOwner,
  OfferDetailsOwner,
  CarOwnerAdd,
  ProfileDetails,
  OrderDetals,
  CarOwnerEdit,
  Download,
  SavedOffers,
  CreateOffer,
  SelectedOffer,
  EditOffer,
  MediationOrders,
  MOffer,
} from './index';

const routeConfigs = [
  { component: Home, path: '/', exact: true },
  { component: Terms, path: '/terms', exact: true },
  { component: Privacy, path: '/privacy', exact: true },
  { component: ContactUs, path: '/contact-us', exact: true },
  { component: AboutUs, path: '/about-us', exact: true },
  { component: Login, path: '/login', exact: true },
  { component: SignUp, path: '/signUp', exact: true },

  {
    component: Orders,
    path: '/orders',
    exact: true,
  },
  {
    component: AddOrder,
    path: '/orders/add',
    exact: true,
  },
  {
    component: AddOrder,
    path: '/orders/add/:orderId',
    exact: true,
  },
  { component: Profile, path: '/profile', exact: true },
  { component: ForgetPassword, path: '/forgetPass', exact: true },

  {
    component: Order,
    path: '/orders/:id',
    exact: true,
  },
  // {
  //   component: Offers,
  //   path: '/orders/:id/offers',
  //   exact: true,
  // },
  {
    component: MOffers,
    path: '/offers',
    exact: true,
  },
  {
    component: MOffer,
    path: '/offers/:id',
    exact: true,
  },
  {
    component: MAddOffer,
    path: '/offers/add',
    exact: true,
  },
  {
    component: MEditOffer,
    path: '/offers/:id/edit',
    exact: true,
  },
  {
    component: EditOffer,
    path: '/offers/:id/update',
    exact: true,
  },
  {
    component: SavedOffers,
    path: '/saved-offers',
    exact: true,
  },
  {
    component: SelectedOffer,
    path: '/offers/:id/add',
    exact: true,
  },
  {
    component: CreateOffer,
    path: '/offers/create',
    exact: true,
  },
  {
    component: Favourites,
    path: '/orders/favourites',
    exact: true,
  },
  {
    component: MediationOrders,
    path: '/mediation-requests',
    exact: true,
  },
  {
    component: Offer,
    path: '/orders/:id/offers/:offerId',
    exact: true,
  },
  {
    component: OrderStatistics,
    path: '/orders/:id/statistics',
    exact: true,
  },
  {
    component: VerifyOTP,
    path: '/verifyOtp',
    exact: true,
  },
  {
    component: notifications,
    path: '/notifications',
    exact: true,
  },
  {
    component: Chat,
    path: '/chats',
    exact: true,
  },
  {
    component: CarOwner,
    path: '/car-owner',
    exact: true,
  },
  {
    component: CarOffersOwner,
    path: '/car-owner/:id/offers',
    exact: true,
  },
  {
    component: OfferDetailsOwner,
    path: '/car-owner/:id/offers/:offerId',
    exact: true,
  },
  {
    component: CarOwnerAdd,
    path: '/car-owner/add',
    exact: true,
  },
  {
    component: ProfileDetails,
    path: '/profile-info/:userId',
    exact: true,
  },
  {
    component: OrderDetals,
    path: '/car-owner/:id/details',
    exact: true,
  },
  {
    component: CarOwnerEdit,
    path: '/car-owner/:id/edit',
    exact: true,
  },
  {
    component: Download,
    path: '/download-apps',
    exact: true,
  },
];

export const mRoutes = routeConfigs.map((route) => ({
  component: route.component,
  path: route.path,
  exact: route.exact,
}));
