import { Navbar, MegaMenu, Tooltip } from 'flowbite-react';
import { useState, useEffect, useContext } from 'react';
import { handleNotificationAction } from '../utils/notificationUtils';
import { useTranslation } from 'react-i18next';
import {
  logo,
  logoutIcon,
  notification,
  userArabic,
  userCar,
  setting,
  // notFoundNotifications,
  mshraiLogoM,
  arrowBack,
  doubleCheck,
  LogoEn,
  profileUser,
  key,
  userBlock,
  britichFlag,
  saudiFlag,
} from '../assets/icons';
import { arabic, english } from '../assets/imgs';
import {
  Button,
  ButtonBadge,
  Input,
  MConfirmation,
  Mediators,
} from '../components';
import i18n from './../languages/i18n';
import { ToggleSwitch } from 'flowbite-react';
import { Lang, navbarTheme, SwitchTheme } from '../helpers/contents';
import { useNavigate } from 'react-router-dom';
import Environment from '../services/Environment';
import { get } from '../services/Request';
import {
  addNotificationSetting,
  getNotifications,
  getNotificationSetting,
} from '../services/apis/notification';
import {
  defaultLettersCircle,
  languageAr,
  toastConfig,
  userRole,
} from '../helpers/helper';
import { NotificationContext } from '../context/NotificationContext';
import { toast } from 'react-toastify';
import { FaQuestionCircle, FaUsers } from 'react-icons/fa';
import { getMediators, unBlockUser, getUserInfo } from '../services/apis/user';
import { getConfirmationRequest } from '../services/apis/order';
import { userToken } from '../helpers/helper';

const Header = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const notificationContext = useContext(NotificationContext);
  const [switch1, setSwitch1] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [mediators, setMediators] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [activeLink, setActiveLink] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenMediators, setIsOpenMediators] = useState(false);
  const [isOpenSetting, setIsOpenSetting] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userProfileLinks, setUserProfileLinks] = useState([
    { id: 1, nameAr: 'الملف الشخصي', nameEn: 'Profile', icon: profileUser },
    {
      id: 2,
      nameAr: 'تعديل البيانات الشخصية',
      nameEn: 'Edit personal data',
      icon: key,
    },
    {
      id: 3,
      nameAr: 'الوسطاء المحظورين',
      nameEn: 'Blocked mediators',
      icon: userBlock,
    },
    {
      id: 4,
      nameAr: 'عروضي',
      nameEn: 'My offers',
      icon: userBlock,
    },
  ]);

  const newLinksProfile =
    userRole == 3
      ? userProfileLinks.filter((item) => item.id !== 3)
      : userProfileLinks.filter((item) => item.id !== 4);

  const [email, setEmail] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [live, setLive] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [phoneWhatsApp, setPhoneWhatsApp] = useState('');
  const [countNotifications, setCountNotifications] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [userId, setUserId] = useState(localStorage.getItem('userId'));
  const [confirmationId, setConfirmationId] = useState(null);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState(false);
  const [hideActionModal, setHideActionModal] = useState(false);
  const [openPaidRequestModal, setOpenPaidRequestModal] = useState(false);


  const handleUserInfo = async () => {
    const response = await getUserInfo();
    setUserInfo(response);
  };

  const getConfirmationRequestData = async (confirmationId) => {
    const response = await getConfirmationRequest('', confirmationId);
    if (response.data) {
      response.data.medInfo = response.data.otherUserInfo;
      response.data.medInfo.avgRating = response.data.otherUserInfo.medAvgRating;
      response.data.cars = [];
      response.data.confirmationCars.forEach((car) => {
        response.data.cars.push(car.offerCar);
      });
    }
  };

  useEffect(() => {
    if(userToken){
    handleUserInfo();
    getAllNotifications();
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsLoggedIn(!!token);
  }, []);

  const getAllNotifications = async () => {
    const response = await getNotifications('');
    setNotifications(response.data.page.content);
    setCountNotifications(response.data.unreadCount);
  };

  const getAllBlockMediators = async () => {
    const response = await getMediators('');
    setMediators(response.data.items);
  };
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  const logout = () => {
    localStorage.removeItem('token');
    localStorage.setItem('i18nextLng', 'ar');
    window.location.href = '/';
  };

  document.body.dir = i18n.dir();

  const changeLanguage = (lng) => {
    localStorage.setItem('i18nextLng', lng);
    window.location.reload();
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path.includes('about-us')) {
      setActiveLink('about-us');
    } else if (path.includes('contact-us')) {
      setActiveLink('contact-us');
    } else if (path.includes('orders')) {
      setActiveLink('orders');
      navigate('/orders');
    } else if (path.includes('car-owner')) {
      setActiveLink('car-owner');
    } else if (path.includes('mediationRequests')) {
      setActiveLink('mediationRequests');
    } else if (path.includes('chats')) {
      setActiveLink('chats');
    } else if (path.includes('wasset')) {
      setActiveLink('wasset');
    } else {
      setActiveLink('');
    }
  }, []);

  const updateNotificationSetting = async () => {
    // const response = await addNotificationSetting(notificationContext.data)
    // await getAllNotificationSetting()
    setLoading(true);
    let data = {
      appNotificationEnabled: true,
      emailNotificationEnabled: notificationContext.email,
      whatsappNotificationEnabled: notificationContext.whatsApp,
      whatsappPhoneNumber:
        notificationContext.phoneWhatsApp == ''
          ? ''
          : '966' + notificationContext.phoneWhatsApp.slice(1),
      emailAddress: notificationContext.emailAddress,
    };
    try {
      const response = await addNotificationSetting(data);
      console.log('Success:', response);
      setLoading(false, getAllNotificationSetting());
      toast.success(t('header.successUpdateNotificationSetting'), toastConfig);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          if (error.response.data.message == 'Phone number is not valid') {
            toast.error(
              t('header.phoneNotValid'),
              toastConfig,
              setLoading(false)
            );
          } else if (error.response.data.message == 'Email is not valid') {
            toast.error(
              t('header.emailNotValid'),
              toastConfig,
              setLoading(false)
            );
          } else if (
            error.response.data.message == 'Whatsapp phone number is required'
          ) {
            toast.error(
              t('header.phoneWhatsappRequired'),
              toastConfig,
              setLoading(false)
            );
          } else if (
            error.response.data.message == 'Email address is required'
          ) {
            toast.error(
              t('header.emailRequired'),
              toastConfig,
              setLoading(false)
            );
          } else {
            toast.error(
              error.response.data.message,
              toastConfig,
              setLoading(false)
            );
          }
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        }
      }
    }
    // console.log(response)
  };

  const getAllNotificationSetting = async () => {
    // setLoading(true)
    // let data ={
    //   appNotificationEnabled: live,
    //   emailNotificationEnabled: email,
    //   whatsappNotificationEnabled: whatsApp,
    //   whatsappPhoneNumber: null,
    //   emailAddress: emailAddress
    // }

    // console.log(notificationContext.data)
    // return true
    const response = await getNotificationSetting('');
    console.log(response);
    notificationContext.setEmail(response.data.emailNotificationEnabled);
    notificationContext.setLive(response.data.appNotificationEnabled);
    notificationContext.setPhoneWhatsApp(
      response.data.whatsappPhoneNumber == ''
        ? ''
        : '0' + response.data.whatsappPhoneNumber.slice(3)
    );
    notificationContext.setWhatsApp(response.data.whatsappNotificationEnabled);
    notificationContext.setEmailAddress(response.data.emailAddress);
    // setOrder(response.data,setLoading(false))
    // notificationContext.setData({appNotificationEnabled:response.data.appNotificationEnabled,emailAddress:response.data.emailAddress,emailNotificationEnabled:response.data.emailNotificationEnabled,whatsappNotificationEnabled:response.data.whatsappNotificationEnabled,whatsappPhoneNumber:response.data.whatsappPhoneNumber})
  };

  let buttonLang = (
    <div className="mx-3" onClick={() => changeLanguage('ar')}>
      <div className="flex flex-row items-center justify-around bg-[#C7F1EF] p-2 rounded-[10px] shadow-md w-[80px]">
        <div>
          <img src={saudiFlag} className="w-[25px]" alt="" />
        </div>
        <div className="text-[12px] text-[#3E0292] font-bold">عربي</div>
      </div>
    </div>
  );
  if (document.body.dir === 'rtl') {
    buttonLang = (
      <div
        className="mx-3 cursor-pointer sm:hidden md:hidden lg:block xl:block 2xl:block min-[200px]:block"
        onClick={() => changeLanguage('en')}
      >
        <div className="flex flex-row items-center justify-around bg-[#C7F1EF] p-2 rounded-[10px] shadow-md w-[80px]">
          <div className="text-[12px] text-[#3E0292] font-bold">Eng</div>
          <div>
            <img src={britichFlag} className="w-[25px]" alt="" />
          </div>
        </div>
      </div>
    );
  } else {
    buttonLang = (
      <div
        className=" mx-3 cursor-pointer sm:hidden md:hidden lg:block xl:block 2xl:block min-[200px]:block"
        onClick={() => changeLanguage('ar')}
      >
        <div className="flex flex-row items-center justify-around bg-[#C7F1EF] p-2 rounded-[10px] shadow-md w-[80px]">
          <div>
            <img src={saudiFlag} className="w-[25px]" alt="" />
          </div>
          <div className="text-[12px] text-[#3E0292] font-bold">عربي</div>
        </div>
      </div>
    );
  }

  const actionUnblockUser = async (userId) => {
    const response = await unBlockUser('', userId);
    console.log(response);
    toast.success(
      t('profileInfo.unBlockMessage'),
      toastConfig,
      getAllBlockMediators()
    );
  };

  const action = (id) => {
    console.log(id);
    if (id == 1) {
      navigate(`/profile-info/${userInfo.userId}`);
    } else if (id == 2) {
      navigate(`/profile`);
    } else if (id == 4) {
      navigate('/offers');
    } else {
      setIsOpenMediators(true, getAllBlockMediators());
    }
  };
  return (
    // <div className="p-5 w-[95%] mr-auto ml-auto">
    <Navbar
      theme={navbarTheme}
      fluid
      rounded
      style={{ direction: i18n.language == Lang.ar ? 'rtl' : 'ltr' }}
      className="mx-auto w-[95%] p-5"
    >
      <Navbar.Brand href="/">
        <img
          src={i18n.language == Lang.ar ? logo : LogoEn}
          className="mr-3 lg:w-[170px] lg:h-[64px] md:w-[170px] md:h-[64px] sm:w-[170px] sm:h-[64px] xs:w-[170px] xs:h-[64px]"
          alt="mshrai"
        />
      </Navbar.Brand>
      <div className="flex md:order-2">
        {!isLoggedIn ? (
          <div className="flex flex-row items-center">
            <Button
              title={t('home.menu.login')}
              dark={true}
              className="max600:hidden min600:inline-flex mx-5"
              onClick={() => navigate('/login')}
            />

            <Button
              title={t('home.menu.signUp')}
              dark={true}
              className="max600:hidden min600:inline-flex mx-3"
              onClick={() => navigate('/signUp')}
            />
            <div className="max600:hidden min600:inline-flex">{buttonLang}</div>
          </div>
        ) : (
          <div className="flex flex-row items-center max600:hidden min600:inline-flex">
            <div
              className="flex flex-row items-center bg-[#78E1D766] p-3 rounded-[20px] relative cursor-pointer"
              onClick={() => setOpenUserMenu(!openUserMenu)}
            >
              {!userInfo.profileImg ? (
                <div className="bg-[#73D3CB] rounded-full p-2">
                  <div
                  // onClick={() => navigate('/profile')}
                  >
                    {defaultLettersCircle(userInfo.fullName)}
                  </div>
                </div>
              ) : (
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src={userInfo.profileImg}
                />
              )}
              <div className="flex flex-col items-center">
                <div className="font-700 text-[15px] text-[#3E0292]">
                  {t('home.menu.hello')}{' '}
                  {userInfo ? userInfo.fullName : t('home.menu.dear')}
                </div>
                <div className="flex flex-row items-center">
                  <div>
                    <img src={userCar} />
                  </div>
                  <div className="font-400 text-[15px] text-[#3E0292] mx-2">
                    {userRole == 3
                      ? t('home.menu.mediator')
                      : t('home.menu.buyer')}
                  </div>
                </div>
              </div>
              {openUserMenu && (
                <div className="absolute bg-[#C9F3EF] rounded-[20px] p-3 top-[80px] w-[100%] left-0 z-10">
                  {newLinksProfile.map((profile, index) => (
                    <div
                      key={index}
                      className={`flex flex-row items-center ${
                        index == 0 ? 'mt-0' : 'mt-2'
                      } cursor-pointer`}
                      onClick={() => action(profile.id)}
                    >
                      <div className="bg-[#7FE7DD] rounded-[5px] p-2">
                        <img src={profile.icon} className="w-[20px] h-[20px]" />
                      </div>
                      <div className="text-[#3E0292] text-[12px] font-400 mx-2">
                        {languageAr ? profile.nameAr : profile.nameEn}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {buttonLang}
            <div className="relative">
              <div
                className="bg-[#C7F1EF] rounded-[10px] w-[35px] h-[35px] flex items-center justify-center cursor-pointer relative"
                onClick={() => setIsOpenMediators(false, setIsOpen(!isOpen))}
              >
                <img src={notification} />
                <div className="p-1 bg-red-500 text-white rounded-full absolute left-3 bottom-5 text-[11px]">
                  {countNotifications}
                </div>
              </div>
              {isOpen && (
                <div
                  className={`absolute ${
                    i18n.language == Lang.ar ? 'left-0' : 'right-0'
                  } z-10 mt-2 w-80 max600:w-[230px] bg-purple-800 text-white rounded-lg shadow-lg p-5 max-h-[500px] overflow-hidden flex flex-col`}
                >
                  <div className="mb-4">
                    <div className="flex flex-row justify-between mb-4">
                      <div className="text-[20px] font-500 text-[#ffffff] cursor-pointer">
                        {isOpenSetting
                          ? t('home.notifications.titleSettings')
                          : t('home.notifications.title')}
                      </div>
                      <div
                        className="bg-[#00CEBC] w-[36px] h-[36px] rounded-[10px] flex items-center justify-center cursor-pointer"
                        onClick={() =>
                          setIsOpenSetting(
                            !isOpenSetting,
                            getAllNotificationSetting()
                          )
                        }
                      >
                        <img src={isOpenSetting ? arrowBack : setting} />
                      </div>
                    </div>
                    {isOpenSetting ? null : (
                      <div className="flex items-center">
                        <img src={doubleCheck} />
                        <div className="text-[15px] underline font-400 mx-3">
                          {t('home.notifications.allRead')}
                        </div>
                      </div>
                    )}
                  </div>
                  {isOpenSetting ? (
                    <div className="flex flex-col h-[400px]">
                      <div className="text-[15px] font-500 text-[#ffffff]">
                        {t('home.notifications.recieveNotifications')}
                      </div>
                      <div className="mt-5">
                        <ToggleSwitch
                          theme={SwitchTheme}
                          checked={true}
                          label={t('home.notifications.live')}
                          onChange={() => notificationContext.setLive(true)}
                        />
                      </div>
                      <div className="mt-5">
                        <ToggleSwitch
                          theme={SwitchTheme}
                          checked={notificationContext.email}
                          label={t('home.notifications.fromEmail')}
                          onChange={() =>
                            notificationContext.setEmail(
                              !notificationContext.email,
                              notificationContext.email == false
                                ? notificationContext.setEmailAddress(
                                    userInfo.email
                                  )
                                : notificationContext.setEmailAddress('')
                            )
                          }
                        />
                      </div>
                      <Input
                        placeholder={t('home.notifications.enterEmail')}
                        value={notificationContext.emailAddress}
                        onChange={(e) =>
                          notificationContext.setEmailAddress(e.target.value)
                        }
                        type={'email'}
                      />
                      <Input
                        placeholder={t('home.notifications.enterPhoneWhatsApp')}
                        value={notificationContext.phoneWhatsApp}
                        onChange={(e) =>
                          notificationContext.setPhoneWhatsApp(e.target.value)
                        }
                        type={'text'}
                      />
                      <div className="mt-5">
                        <ToggleSwitch
                          theme={SwitchTheme}
                          checked={notificationContext.whatsApp}
                          label={t('home.notifications.fromWhatsapp')}
                          onChange={() =>
                            notificationContext.setWhatsApp(
                              !notificationContext.whatsApp,
                              notificationContext.whatsApp == false
                                ? notificationContext.setPhoneWhatsApp(
                                    '0' + userInfo.phone.slice(3)
                                  )
                                : notificationContext.setPhoneWhatsApp('')
                            )
                          }
                        />
                      </div>

                      <Button
                        loading={loading}
                        onClick={updateNotificationSetting}
                        dark={true}
                        className={'bg-[#ffffff] mt-5 w-[70%] mr-auto ml-auto'}
                        textColor={'#00CEBC'}
                        title={'حفظ'}
                      />
                    </div>
                  ) : (
                    <ul className="space-y-2 flex-1 overflow-y-auto p-[5px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                      {notifications
                        ?.slice(0, 5)
                        ?.map((notification, index) => (
                          <li
                            key={notification.id}
                            className="bg-blue-200 p-2 rounded-lg flex items-center justify-between cursor-pointer"
                            onClick={() => {
                              handleNotificationAction({
                                notification,
                                navigate,
                                userRole,
                                userId,
                                callbacks: {
                                  onSetConfirmationId: setConfirmationId,
                                  onSetOpenConfirmRequestModal: setOpenConfirmRequestModal,
                                  onSetHideActionModal: setHideActionModal,
                                  onSetOpenPaidRequestModal: setOpenPaidRequestModal,
                                  onGetConfirmationRequestData: getConfirmationRequestData
                                }
                              });
                              setIsOpen(false);
                            }}
                          >
                            <div className="flex flex-row">
                              <div>
                                <img src={mshraiLogoM} />
                              </div>
                              <div className="mx-3">
                                <div className="text-[#000000] text-[13px] font-400">
                                  {/* {t('home.notifications.message')} */}
                                  {languageAr
                                    ? notification.messageAr
                                    : notification.messageEn}
                                </div>
                                <div className="text-[10px] text-[#7B7B7B] font-500">
                                  {notification.createdAt}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  <div
                    className="text-white bg-purple-800 p-3 w-full text-center cursor-pointer mt-auto border-t border-gray-700"
                    onClick={() => navigate('/notifications')}
                  >
                    {t('notifications.showMore')}
                  </div>
                </div>
              )}
            </div>
            <div
              className="bg-[#FF000026] rounded-[10px] w-[35px] h-[35px] mx-3 flex items-center justify-center cursor-pointer"
              onClick={logout}
            >
              <img src={logoutIcon} />
            </div>
            {/* {buttonLang} */}
          </div>
        )}
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        {!isLoggedIn ? (
          <div className="flex flex-col items-center gap-3">
            <Button
              title={t('home.menu.login')}
              className="max600:inline-flex min600:hidden"
              dark={true}
              onClick={() => navigate('/login')}
            />
            <Button
              title={t('home.menu.signUp')}
              dark={true}
              className="max600:inline-flex min600:hidden"
              onClick={() => navigate('/signUp')}
            />
            <div className="max600:inline-flex min600:hidden">{buttonLang}</div>
          </div>
        ) : (
          <div className="flex flex-row items-center max600:inline-flex min600:hidden">
            <div
              onClick={() => setOpenUserMenu(!openUserMenu)}
              className="flex flex-row items-center bg-[#78E1D766] p-3 rounded-[20px] relative cursor-pointer"
            >
              {!userInfo.profileImg ? (
                <div className="bg-[#73D3CB] rounded-full p-2">
                  <div
                  // onClick={() => navigate('/profile')}
                  >
                    {defaultLettersCircle(userInfo.fullName)}
                  </div>
                </div>
              ) : (
                <img
                  className="w-[45px] h-[45px] rounded-full"
                  src={userInfo.profileImg}
                />
              )}
              <div className="flex flex-col items-center">
                <div className="font-700 text-[15px] text-[#3E0292]">
                  {t('home.menu.hello')}{' '}
                  {userInfo ? userInfo.fullName : t('home.menu.dear')}
                </div>
                <div className="flex flex-row items-center">
                  <div>
                    <img src={userCar} />
                  </div>
                  <div className="font-400 text-[15px] text-[#3E0292] mx-2">
                    {userRole == 3
                      ? t('home.menu.mediator')
                      : t('home.menu.buyer')}
                  </div>
                </div>
              </div>
              {openUserMenu && (
                <div className="absolute bg-[#C9F3EF] rounded-[20px] p-3 top-[80px] w-[100%] left-0 z-10">
                  {newLinksProfile.map((profile, index) => (
                    <div
                      key={index}
                      className={`flex flex-row items-center ${
                        index == 0 ? 'mt-0' : 'mt-2'
                      } cursor-pointer`}
                      onClick={() => action(profile.id)}
                    >
                      <div className="bg-[#7FE7DD] rounded-[5px] p-2">
                        <img src={profile.icon} className="w-[20px] h-[20px]" />
                      </div>
                      <div className="text-[#3E0292] text-[12px] font-400 mx-2">
                        {languageAr ? profile.nameAr : profile.nameEn}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {buttonLang}
            <div className="relative">
              <div
                className="bg-[#C7F1EF] rounded-[10px] w-[35px] h-[35px] flex items-center mx-3 justify-center cursor-pointer relative"
                onClick={() => setIsOpenMediators(false, setIsOpen(!isOpen))}
              >
                <img src={notification} />
                <div className="p-1 bg-red-500 text-white rounded-full absolute left-3 bottom-5 text-[11px]">
                  {countNotifications}
                </div>
              </div>
              {isOpen && (
                <div
                  className={`absolute ${
                    i18n.language == Lang.ar ? 'left-0' : 'right-0'
                  } z-10 mt-2 w-80 max600:w-[230px] bg-purple-800 text-white rounded-lg shadow-lg p-4`}
                >
                  <div className="mb-4">
                    <div className="flex items-center justify-between mb-4">
                      <div className="text-[20px] font-500 text-[#ffffff] cursor-pointer">
                        {isOpenSetting
                          ? t('home.notifications.titleSettings')
                          : t('home.notifications.title')}
                      </div>
                      <div
                        className="bg-[#00CEBC] w-[36px] h-[36px] rounded-[10px] flex items-center justify-center cursor-pointer"
                        onClick={() => setIsOpenSetting(!isOpenSetting)}
                      >
                        <img src={isOpenSetting ? arrowBack : setting} />
                      </div>
                    </div>
                    {isOpenSetting ? null : (
                      <div className="flex items-center">
                        <img src={doubleCheck} />
                        <div className="text-[15px] underline font-400 mx-3">
                          {t('home.notifications.allRead')}
                        </div>
                      </div>
                    )}
                  </div>

                  {isOpenSetting ? (
                    <div className="flex flex-col h-[400px]">
                      <div className="text-[15px] font-500 text-[#ffffff]">
                        {t('home.notifications.recieveNotifications')}
                      </div>
                      <div className="mt-5">
                        <ToggleSwitch
                          theme={SwitchTheme}
                          disabled
                          checked={live}
                          label={t('home.notifications.live')}
                          onChange={setLive}
                        />
                      </div>
                      <div className="mt-5">
                        <ToggleSwitch
                          theme={SwitchTheme}
                          checked={email}
                          label={t('home.notifications.fromEmail')}
                          onChange={setEmail}
                        />
                      </div>
                      <Input placeholder={t('home.notifications.enterEmail')} />
                      <div className="mt-5">
                        <ToggleSwitch
                          theme={SwitchTheme}
                          checked={whatsApp}
                          label={t('home.notifications.fromWhatsapp')}
                          onChange={setWhatsApp}
                        />
                      </div>
                    </div>
                  ) : (
                    <ul className="space-y-2 h-[400px] overflow-y-scroll p-[5px]">
                      {notifications
                        ?.slice(0, 5)
                        ?.map((notification, index) => (
                          <li
                            key={notification.id}
                            className="bg-blue-200 p-2 rounded-lg flex items-center justify-between cursor-pointer"
                            onClick={() => {
                              handleNotificationAction({
                                notification,
                                navigate,
                                userRole,
                                userId,
                                callbacks: {
                                  onSetConfirmationId: setConfirmationId,
                                  onSetOpenConfirmRequestModal: setOpenConfirmRequestModal,
                                  onSetHideActionModal: setHideActionModal,
                                  onSetOpenPaidRequestModal: setOpenPaidRequestModal,
                                  onGetConfirmationRequestData: getConfirmationRequestData
                                }
                              });
                              setIsOpen(false);
                            }}
                          >
                            <div className="flex flex-row">
                              <div>
                                <img src={mshraiLogoM} />
                              </div>
                              <div className="mx-3">
                                <div className="text-[#000000] text-[13px] font-400">
                                  {languageAr
                                    ? notification.messageAr
                                    : notification.messageEn}
                                </div>
                                <div className="text-[10px] text-[#7B7B7B] font-500">
                                  {notification.createdAt}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                    </ul>
                  )}
                  <div
                    className="text-white bg-purple-800 absolute bottom-0 p-3 w-[100%] text-center right-0 cursor-pointer"
                    onClick={() => navigate('/notifications')}
                  >
                    {t('notifications.showMore')}
                  </div>
                </div>
              )}
            </div>
            <div
              className="bg-[#FF000026] rounded-[10px] w-[35px] h-[35px] flex items-center mx-3 justify-center cursor-pointer"
              onClick={logout}
            >
              <img src={logoutIcon} />
            </div>
          </div>
        )}
        {!isLoggedIn ? (
          <div className="max600:mt-10 flex min600:flex-row max600:flex-col items-center">
            <Navbar.Link
              href={'/'}
              onClick={() => handleLinkClick('')}
              className={
                activeLink === ''
                  ? ' text-[#3E0292] text-[16px] mx-5 border-b border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] mx-5 font-500'
              }
            >
              {t('home.menu.home')}
            </Navbar.Link>
            <Navbar.Link
              onClick={() => handleLinkClick('contact-us')}
              className={
                activeLink === 'contact-us'
                  ? 'text-[#3E0292] text-[16px] max600:mx-0 mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] mx-5 font-500'
              }
              href="/contact-us"
            >
              {t('home.menu.contactUs')}
            </Navbar.Link>
            <Navbar.Link
              onClick={() => handleLinkClick('about-us')}
              className={
                activeLink === 'about-us'
                  ? 'text-[#3E0292] text-[16px] mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] mx-5 font-500'
              }
              href="/about-us"
            >
              {t('home.menu.aboutUs')}
            </Navbar.Link>
          </div>
        ) : (
          <div className="max600:mt-10 flex min600:flex-row max600:flex-col items-center">
            <Navbar.Link
              href={'/'}
              onClick={() => handleLinkClick('')}
              className={
                activeLink === ''
                  ? ' text-[#3E0292] text-[16px] hover:text-[#3E0292] max600:mx-5 mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] max600:mx-5 mx-5 font-500'
              }
            >
              {t('home.menu.home')}
            </Navbar.Link>
            <Navbar.Link
              href="/orders"
              onClick={() => handleLinkClick('orders')}
              className={
                activeLink === 'orders'
                  ? ' text-[#3E0292] text-[16px] hover:text-[#3E0292] max600:mx-5 mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] max600:mx-5 mx-5 font-500'
              }
            >
              {userRole == 3 ? t('home.menu.orders') : t('home.menu.myOrders')}
            </Navbar.Link>
            {userRole == 3 && (
              <Navbar.Link
                href="/mediation-requests"
                onClick={() => handleLinkClick('mediationRequests')}
                className={
                  activeLink === 'mediation-requests'
                    ? ' text-[#3E0292] text-[16px] hover:text-[#3E0292] max600:mx-5 mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                    : ' text-[#444444] text-[16px] hover:text-[#3E0292] max600:mx-5 mx-5 font-500'
                }
              >
                {t('home.menu.mediationRequests')}
              </Navbar.Link>
            )}
            <Navbar.Link
              href="/chats"
              onClick={() => handleLinkClick('chats')}
              className={
                activeLink === 'chats'
                  ? 'text-[#3E0292] text-[16px] hover:text-[#3E0292] mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] mx-5 font-500'
              }
            >
              {t('home.menu.chats')}
            </Navbar.Link>
            <Navbar.Link
              href="/car-owner"
              onClick={() => handleLinkClick('car-owner')}
              className={
                activeLink === 'car-owner'
                  ? 'text-[#3E0292] text-[16px] hover:text-[#3E0292] mx-5 min600:border-b-[2px] min600:border-b-[#3E0292] max600:border-b-[2px] max600:border-b-[#3E0292] font-500'
                  : ' text-[#444444] text-[16px] hover:text-[#3E0292] mx-5 font-500'
              }
            >
              <div className='flex flex-row items-center'>
              <div>
              {t('home.menu.carOwner')}
              </div>
              <Tooltip content={t("home.menu.messageMyCars")} style="dark" placement="bottom">
              <div className={languageAr ? 'mr-2' : 'ml-2'}>
                <FaQuestionCircle/>
              </div>
              </Tooltip>
              </div>
            </Navbar.Link>
          </div>
        )}
      </Navbar.Collapse>
      <MConfirmation
        openModal={isOpenMediators}
        showFooter={false}
        setOpenModal={setIsOpenMediators}
        title={t('header.mediatorsBlocked')}
        content={
          <Mediators
            mediators={mediators}
            getAllBlockMediators={getAllBlockMediators}
          />
        }
      />
    </Navbar>
  );
};

export default Header;
