import { CompleteOfferProvider } from '../../../context/CompleteOfferContext';
import OrderDetails from './OrderDetails';
const Order = () => {
  return (
    <CompleteOfferProvider>
      <OrderDetails />
    </CompleteOfferProvider>
  );
};

export default Order;
