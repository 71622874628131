import { useTranslation } from 'react-i18next';
import {
  BMW,
  Calendar,
  car,
  carIcon,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  star,
  statisticsOrder,
  userMail,
} from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import {
  convertDate,
  defaultLettersCircle,
  languageAr,
  userRole,
} from '../../helpers/helper';
import { ButtonIcon, ButtonBadge, MConfirmation, Button } from './../index';
import CarColor from './CarColor';
import { useNavigate } from 'react-router-dom';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { FaShare } from 'react-icons/fa';
import { useState } from 'react';
import { MdShare } from 'react-icons/md';
import { Tooltip } from 'flowbite-react';

const CardOrder = ({ onClick, order, goStatistics, goOffers }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openShare, setOpenShare] = useState(false);
  const [loading, setLoading] = useState(false);

  const shareUrl = `https://staging-v2.mshrai.pages.dev/orders/${order?.id}`;
  const title = 'Check out this amazing mshrai site!';

  const handleShare = async () => {
    const shareData = {
      title: 'Share order',
      text: 'Hey, look at this amazing mshrai site!',
      url: shareUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        toast.error('Link shared successfully!', toastConfig);
      } catch (err) {
        toast.error('Error sharing the link: ' + err.message, toastConfig);
      }
    } else {
      toast.error(
        'Web Share API is not supported on this device.',
        toastConfig
      );
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(t('orderDetails.copyLink'), toastConfig);
      })
      .catch((err) => {
        toast.success('Failed to copy link.', toastConfig);
      });
  };
  return (
    <div className="rounded-[20px] bg-[#D7E2FF] cursor-pointer flex flex-col justify-between shadow-lg h-[100%]">
      <div>
        <div className="flex flex-row justify-between">
          <div
            className={`bg-[#3E0292] flex flex-row items-center ${
              languageAr
                ? 'rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]'
                : 'rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]'
            } text-[14px] text-[#ffffff] p-4 h-[50px]`}
          >
            <div>{t('listOrders.numberOrder')}</div>
            <div className="px-2">:</div>
            <div>#{order?.id}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center p-2 bg-[#E6EDFF] rounded-[7px] mx-5 mt-[5px]">
              <div>
                <img src={Calendar} className="w-[20px] h-[20px]" />
              </div>
              <div className="text-[13px] font-[600px] text-[#000000] px-2">
                {convertDate(order?.createdAt)}
              </div>
              <div className="text-[10px] font-[600px] text-[#6A6A6A]">
                {order?.createdAt.split('T')[1]}
              </div>
            </div>
            <div className="flex flex-row items-center pt-2">
              <div className="text-[#3E0292] text-[13px] font-500">
                {t('home.orders.status')}
              </div>
              <div className="text-[#3E0292] text-[13px] font-500 mx-2">:</div>
              <div className="text-[#3E0292] text-[13px] font-500">
                {order.status == 'OPEN'
                  ? t('home.orders.open')
                  : order.status == 'CLOSED'
                    ? t('home.orders.close')
                    : order.status == 'COMPLETED'
                      ? t('home.orders.complete')
                      : t('home.orders.open')}
              </div>
              <div
                className={`${
                  order.status == 'OPEN'
                    ? 'bg-[#04C500CC]'
                    : order.status == 'CLOSED'
                      ? 'bg-[#ff0000CC]'
                      : 'bg-[#3E0292CC]'
                }  w-[10px] h-[10px] rounded-full mx-2`}
              ></div>
              <Tooltip content={t('orderDetails.buttonShare')} style="dark">
              <div onClick={() => setOpenShare(true)} className="mx-3">
                <MdShare className="text-[#3E0292] text-[25px]" />
              </div>
              </Tooltip>
            </div>
          </div>
        </div>
        {userRole == 3 && (
          <div
            className="flex flex-row items-center p-2 m-[10px] rounded-[28px] bg-[#00CEBC] cursor-pointer"
            onClick={() => navigate(`/profile-info/${order?.buyerInfo?.id}`)}
          >
            <div className="rounded-full p-1 bg-[#73D3CB]">
              {order?.buyerInfo?.profileImg == '' ? (
                defaultLettersCircle(order?.buyerInfo?.fullName)
              ) : (
                <img
                  className="w-[36px] h-[36px] rounded-full"
                  src={order?.buyerInfo?.profileImg}
                />
              )}
            </div>
            <div className="flex flex-col mx-3">
              <div className="flex flex-row items-center text-[#ffffff] text-[15px] font-700">
                <div>{t('listOffers.buyer')}</div>
                <div className="px-2">:</div>
                <div>{order?.buyerInfo?.fullName}</div>
              </div>
            </div>
          </div>
        )}
        <div onClick={onClick}>
          {order?.cars?.length == 0 ? null : (
            <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
                {order.cars.slice(0, 2).map((car, index) => (
                  <div
                    key={index}
                    className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                  >
                    <div>
                      <img src={car.image} className="w-[20px] h-[2s0px]" />
                    </div>
                    <div className="text-[#0000000] text-[12px] font-400 px-2">
                      {languageAr ? car?.brand : car?.brandEn}
                    </div>
                  </div>
                ))}
              </div>
              {order?.cars.length > 2 ? (
                <div
                  className={`bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500 ${
                    order?.cars.length > 2 ? 'mx-2' : ''
                  }`}
                >
                  +{order.cars.length - 2}
                </div>
              ) : null}
            </div>
          )}


            <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
            {order?.cars?.slice(0, 2)?.map((car,index)=>(
            <div key={index}>
           {car?.models?.length == 0 ? null : (
              <div>
                {car?.models?.slice(0, 2)?.map((model, i) => (
                  <div
                    key={i}
                    className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                  >
                    <div>
                      <img src={carIcon} className="w-[20px] h-[2s0px]" />
                    </div>
                    <div className="text-[#0000000] text-[12px] font-400 px-2">
                      {languageAr ? model?.brandTypes : model?.brandTypesEn}
                    </div>
                  </div>
                ))}
              </div>
              )}
              </div>
               ))}
               </div>

                  {order?.cars?.length > 2 ? (
                <div
                  className={`bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500 ${
                    order?.cars?.length > 2 ? 'mx-2' : ''
                  }`}
                >
                  +{order?.cars?.length - 2}
                </div>
              ) : null}
            </div>

     

          {order?.cities?.length == 0 ? null : (
            <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
                {order.cities.slice(0, 2).map((city, index) => (
                  <div
                    key={index}
                    className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                  >
                    <div>
                      <img src={location} />
                    </div>
                    <div className="text-[#0000000] text-[12px] font-400 px-2">
                      {languageAr ? city?.city : city?.cityEn}
                    </div>
                  </div>
                ))}
              </div>
              {order?.cities.length > 2 ? (
                <div
                  className={`bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500 ${
                    order?.cities.length > 2 ? 'mx-2' : ''
                  }`}
                >
                  +{order.cities.length - 2}
                </div>
              ) : null}
            </div>
          )}
          {order?.colors.length == 0 ? null : (
            <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 w-[90%]">
                {order?.colors.slice(0, 2).map((color, index) => (
                  <div
                    key={index}
                    className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                  >
                    <div>
                      <CarColor
                        color={
                          color?.hexCode == '#FFFFFF'
                            ? '#808080'
                            : color?.hexCode
                        }
                      />
                    </div>
                    <div
                      style={{
                        color:
                          color?.hexCode == '#FFFFFF'
                            ? '#808080'
                            : color?.hexCode,
                      }}
                      className={`text-[12px] font-400 px-2`}
                    >
                      {languageAr ? color?.nameAr : color?.nameEn}
                    </div>
                  </div>
                ))}
              </div>
              {order.colors.length > 2 ? (
                <div
                  className={`bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500 ${
                    order?.colors.length > 2 ? 'mx-2' : ''
                  }`}
                >
                  +{order.colors.length - 2}
                </div>
              ) : null}
            </div>
          )}
          {/* <div className="flex flex-row items-center bg-[#E6EDFF] rounded-[12px] p-3 mt-3 w-[90%] mr-auto ml-auto">
                  <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%]">
                      <div className="bg-[#ffffff] rounded-[12px] p-3 flex flex-row items-center">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                      </div>
                  </div>
                  <div className="bg-[#3E0292] rounded-full flex items-center justify-center text-[#ffffff] w-[27px] h-[27px] text-[11px] font-500">+0</div>
            </div> */}
          {order?.odoFrom == null && order?.odoTo == null ? null : (
            <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
              <div>
                <img src={kilometers} />
              </div>
              <div className="flex flex-row items-center px-2">
                <div className="text-[#BBBBBB] text-[10px] font-400">
                  {t('listOrders.from')}
                </div>
                <div className="text-[#000000] text-[13px] font-400 px-2">
                  {order?.odoFrom}
                </div>
                <div className="text-[#BBBBBB] text-[10px] font-400">
                  {t('listOrders.km')}
                </div>
                <div className="text-[#000000] text-[10px] font-400 px-2">
                  /
                </div>
                <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                  {t('listOrders.to')}
                </div>
                <div className="text-[#000000] text-[13px] font-400">
                  {order?.odoTo}
                </div>
                <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                  {t('listOrders.km')}
                </div>
              </div>
            </div>
          )}

          {order?.yearFrom == null && order?.yearTo == null ? null : (
            <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mt-3 w-[90%] mr-auto ml-auto">
              <div>
                <img src={Calendar} />
              </div>
              <div className="flex flex-row items-center px-2">
                <div className="text-[#BBBBBB] text-[10px] font-400">
                  {t('listOrders.from')}
                </div>
                <div className="text-[#000000] text-[13px] font-400 px-2">
                  {order?.yearFrom}
                </div>
                <div className="text-[#000000] text-[10px] font-400 px-2">
                  /
                </div>
                <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                  {t('listOrders.to')}
                </div>
                <div className="text-[#000000] text-[13px] font-400">
                  {order?.yearTo}
                </div>
              </div>
            </div>
          )}

          {order.isOpenBudget ? (
            <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mt-3 mb-3 w-[90%] mr-auto ml-auto">
              <div>
                <img src={moneyGreen} />
              </div>
              <div className="text-[12px] font-500 text-[#00CEBC]">
                {t('listOrders.price')}
              </div>
              <div className="text-[12px] font-500 text-[#00CEBC] px-2">:</div>
              <div className="text-[12px] font-500 text-[#00CEBC]">
                {t('listOrders.noLimit')}
              </div>
            </div>
          ) : (
            <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mt-3 mb-3 w-[90%] mr-auto ml-auto">
              <div>
                <img src={moneyGreen} />
              </div>
              <div className="flex flex-row items-center px-2">
                <div className="text-[12px] font-500 text-[#00CEBC]">
                  {t('listOrders.price')}
                </div>
                <div className="text-[12px] font-500 text-[#00CEBC] px-2">
                  :
                </div>
                <div className="text-[12px] font-500 text-[#00CEBC]">
                  {order?.priceFrom}
                </div>
                <div className="text-[12px] font-500 text-[#00CEBC] px-2">
                  /
                </div>
                <div className="text-[12px] font-500 text-[#00CEBC] px-2">
                  {order?.priceTo}
                </div>
                <div className="text-[12px] font-500 text-[#00CEBC]">
                  {t('listOrders.Rial')}
                </div>
              </div>
              {/* <div className="text-[12px] font-500 text-[#00CEBC] px-2">السعر : 500.125 / 1,000.000 ريال سعودى</div> */}
            </div>
          )}
        </div>
      </div>
      {userRole == 2 && (
        <div
          className={`grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[90%] mr-auto ml-auto gap-x-2 ${
            userRole == 2 ? 'mt-5 mb-[-30px]' : ''
          }`}
        >
          <ButtonBadge
            value={order?.countOfOffers}
            disabled={order?.countOfOffers > 0 ? false : true}
            className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg"
            onClick={goOffers}
            dark={false}
            title={t('listOrders.offers')}
          />
          <ButtonIcon
            dark={true}
            className="shadow-lg"
            title={t('listOrders.statistics')}
            onClick={goStatistics}
            icon={statisticsOrder}
          />
        </div>
      )}

      {/* share model */}
      <MConfirmation
        loading={loading}
        openModal={openShare}
        showFooter={false}
        setOpenModal={setOpenShare}
        title={t('orderDetails.titleShareOrder')}
        content={
          <div className="grid grid-cols-3 gap-3">
            <div className="flex flex-row items-center justify-between">
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <Button
              dark={true}
              title={t('orderDetails.other')}
              onClick={handleShare}
            />
            <Button
              dark={true}
              title={t('orderDetails.copyLinkButton')}
              onClick={handleCopyLink}
            />
          </div>
        }
      />
    </div>
  );
};

export default CardOrder;
