import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';

const CompleteProfileModal = ({ isOpen, onClose, onConfirm }) => {
  const { t, i18n } = useTranslation();
  const isRTL = i18n.language === 'ar';

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        className={`bg-white p-6 rounded-xl max-w-md w-full  ${isRTL ? 'rtl' : 'ltr'}`}
      >
        <h2 className="text-xl font-bold mb-4 text-center">
          {t('common.confirm')}
        </h2>
        <p className="mb-6 text-center">{t('common.message')}</p>
        <div className="flex justify-center gap-4">
          <Button
            onClick={onClose}
            className="bg-gray-200"
            title={t('common.no')}
          />
          <Button
            onClick={onConfirm}
            className="bg-[#00CEBC]"
            title={t('common.yes')}
          />
        </div>
      </div>
    </div>
  );
};

export default CompleteProfileModal;
