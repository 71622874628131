import Environment from '../Environment';
import mainService from '../mainService';

export function getUserData(data) {
  return mainService
    .get(Environment.userInfo, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getUserInfo() {
  return mainService
    .get(Environment.userInfo)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function blockUser(data, userId) {
  return mainService
    .post(Environment.blockUser + '/' + userId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function unBlockUser(data, userId) {
  return mainService
    .delete(Environment.blockUser + '/' + userId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getMediators(data) {
  return mainService
    .get(Environment.listMediators, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function closeAccount(data) {
  return mainService
    .post(Environment.cancelAccount, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function activateAccount(data, phone) {
  return mainService
    .post(Environment.activateAccount + '/' + phone, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}
