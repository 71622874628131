import { useTranslation } from 'react-i18next';
import { goTo, lamborgini } from '../../assets/icons';

const CarsType = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-[#00CEBC12] px-20 py-10 mt-20">
      <div className="flex flex-row items-center justify-between">
        <div className="font-700 max600:text-[25px] text-[45px] text-[#444444] underline">
          {t('home.carType.title')}
        </div>
        <div>
          <img src={goTo} />
        </div>
      </div>
      <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 gap-4 mt-5">
        <div className="rounded-[15px] bg-[#BBF7F1] max600:mt-5">
          <div className="text-[20px] text-[#444444] font-700 p-3">
            لامبورجينى
          </div>
          <div className="text-right flex justify-end">
            <img src={lamborgini} />
          </div>
        </div>
        <div className="rounded-[15px] bg-[#BBF7F1] max600:mt-5">
          <div className="text-[20px] text-[#444444] font-700 p-3">
            لامبورجينى
          </div>
          <div className="text-right flex justify-end">
            <img src={lamborgini} />
          </div>
        </div>
        <div className="rounded-[15px] bg-[#BBF7F1] max600:mt-5">
          <div className="text-[20px] text-[#444444] font-700 p-3">
            لامبورجينى
          </div>
          <div className="text-right flex justify-end">
            <img src={lamborgini} />
          </div>
        </div>
        <div className="rounded-[15px] bg-[#BBF7F1] max600:mt-5">
          <div className="text-[20px] text-[#444444] font-700 p-3">
            لامبورجينى
          </div>
          <div className="text-right flex justify-end">
            <img src={lamborgini} />
          </div>
        </div>
        <div className="rounded-[15px] bg-[#BBF7F1] max600:mt-5">
          <div className="text-[20px] text-[#444444] font-700 p-3">
            لامبورجينى
          </div>
          <div className="text-right flex justify-end">
            <img src={lamborgini} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarsType;
