import Slider from 'react-slick';
import { user } from '../../assets/imgs';
import { carIcon } from '../../assets/icons';

const UserCardSwiper = ({ name, testimonials, car = false }) => {
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: '60px',
    slidesToShow: 3,
    speed: 500,
  };

  return (
    <Slider {...settings}>
      {testimonials.map((testimonial, index) => (
        <div
          className={`p-4 mx-2 rounded-xl shadow-md ${testimonial.highlighted ? 'bg-[#3E0292] text-white' : 'bg-[#D7E2FF] text-[#3E0292]'} w-64`}
        >
          {car ? (
            <h3 className="text-center font-semibold mb-2 text-[20px]">
              {testimonial.name}
            </h3>
          ) : null}
          <img
            src={car == true ? carIcon : user}
            alt={name}
            className={`${car ? 'w-[80%]' : 'w-24 h-24'} rounded-full mx-auto mb-4`}
          />
          {car ? null : (
            <h3 className="text-center font-semibold mb-2">
              {testimonial.name}
            </h3>
          )}
          {car == true ? null : (
            <div className="flex justify-center mt-2">
              {Array.from({ length: testimonial.stars }).map((_, index) => (
                <svg
                  key={index}
                  className="w-5 h-5 text-yellow-400"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.092 3.362a1 1 0 00.95.69h3.542c.969 0 1.371 1.24.588 1.81l-2.865 2.08a1 1 0 00-.364 1.118l1.092 3.362c.3.921-.755 1.688-1.54 1.118L10 13.477l-2.865 2.08c-.784.57-1.838-.197-1.54-1.118l1.092-3.362a1 1 0 00-.364-1.118L3.458 8.789c-.783-.57-.381-1.81.588-1.81h3.542a1 1 0 00.95-.69l1.092-3.362z" />
                </svg>
              ))}
            </div>
          )}
          {car == true ? null : (
            <>
              {testimonial.text && (
                <p className="text-center text-sm mt-3">{testimonial.text}</p>
              )}
            </>
          )}
        </div>
      ))}
    </Slider>
  );
};

export default UserCardSwiper;
