import {
  Button,
  Dropdown,
  FDropdown,
  MConfirmation,
  Textarea,
  WayConnection,
  YearDropdown,
  GeneralDropdown,
  CheckBox,
  Input,
} from './../../../../components';
import {
  userCar,
  userMail,
  LetterIcon,
  uploadImage,
  uploadImages,
  SuccessAddOffer,
} from './../../../../assets/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from './../../../../languages/i18n';
import Counter from './../../../../components/inputs/Counter';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import {
  getAllCarBrands,
  getColors,
  getCities,
  getModelsOfBrand,
  addSavedOffer,
  createNewOffer,
} from './../../../../services/apis/offer';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaBullseye, FaTimes, FaUpload } from 'react-icons/fa';
import { Field, Formik } from 'formik';
import { addOrderCar } from './../../../../services/apis/owner';
import { toast } from 'react-toastify';
import { toastConfig } from './../../../../helpers/helper';
import { uploadFiles } from './../../../../services/apis/general';
import { getUserInfo } from '../../../../services/apis/user';

const CreateOffer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state.buyerId, location.state.requestId);
  const isArabic = i18n.language === 'ar';
  const [openModal, setOpenModal] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [cities, setCities] = useState([]);
  const [colors, setColors] = useState([]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [loading, setLoading] = useState(false);
  const [userType, setUserType] = useState('true');
  const [userInfo, setUserInfo] = useState('');
  const [priceType, setPriceType] = useState(2);

  const handleChangePriceType = (e) => {
    setPriceType(e.target.value);
  };

  const handleChangeUserType = (e) => {
    console.log(e.target.value);

    setUserType(e.target.value);
  };

  const yearOptions = Array.from({ length: 25 }, (_, index) => 2000 + index);

  const closeModal = () => {
    setOpenModal(false);
  };

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      description: '',
      brandId: 0,
      modelId: 0,
      price: 0,
      modelYear: 0,
      cityId: 0,
      colorId: 0,
      commission: 0,
      commissionType: 'CASH',
      odo: 0,
      filesUrl: [],
    },
  });

  const description = watch('description', '');

  const handleAddOfferSubmit = async (data) => {
    setLoading(true);
    console.log(data);
    try {
      // Validate that carBrands and carType have corresponding entries
      if (!data.brandId) {
        toast.error(t('myOrders.atleastBrand'), toastConfig, setLoading(false));
        return;
      }
      let newCars = [];
      let obj = {
        description: data.description || '',
        brandId: Number(data.brandId) || 0,
        modelId: Number(data.modelId) || 0,
        price: Number(data.price) || 0,
        modelYear: Number(data.modelYear) || 0,
        cityId: Number(data.cityId) || null,
        colorId: Number(data.colorId) || null,
        commission:Number(data.commission) || 0,
          // userType == 'true'
          //   ? null
          //   : priceType == 1
          //     ? data.commission + '%' || 0 + '%'
          //     : 
          //     Number(data.commission) || 0,
        odo: Number(data.odo) || 0,
        commissionType: priceType,
        filesUrl: files.concat(images),
      };

      newCars.push(obj);

      const payload = {
        // car: {
        requestId: location?.state?.requestId,
        cars: newCars,
        buyerId: location?.state?.buyerId,
        isCarOwner: userType,
        // },
      };

      const response = await createNewOffer(payload);

      // Check the response structure and status
      // if (response && response.data && response.data.id) {
      setOpenModal(true);
      toast.success(
        t('offerCycle.addOfferSuccess'),
        toastConfig,
        setLoading(false)
      );
      setTimeout(() => {
        navigate('/');
      }, 1500);
      // } else {
      //   throw new Error('Unexpected response format');
      // }
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status == 400) {
          // toast.error(
          //   error.response.data.message,
          //   toastConfig,
          //   setLoading(false)
          // );
          if (
            error.response.data.message ==
            `Model with ID: 0 is not associated with Brand ID: ${data.brandId}.`
          ) {
            toast.error(
              t(`myOrders.validationModel`),
              toastConfig,
              setLoading(false)
            );
          } else if (
            error.response.data.message == 'Invalid model year value.'
          ) {
            toast.error(
              t(`myOrders.validationModelYear`),
              toastConfig,
              setLoading(false)
            );
          } else if (error.response.data.message == 'Invalid price value.') {
            toast.error(
              t(`myOrders.validationPrice`),
              toastConfig,
              setLoading(false)
            );
          } else if (error.response.data.message == 'Commission is required.') {
            toast.error(
              t(`myOrders.commissionRequired`),
              toastConfig,
              setLoading(false)
            );
          } else if (
            error.response.data.message ==
            'There is pending offer, only one offer can mediator add to request'
          ) {
            toast.error(
              t(`myOrders.pendingOffer`),
              toastConfig,
              setLoading(false)
            );
          } else if (
            error.response.data.message ==
            '{cars[0].description=يجب أن يكون الحجم بين 0 و1000}'
          ) {
            toast.error(
              t(`myOrders.lengthDescription`),
              toastConfig,
              setLoading(false)
            );
          }
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        }
      }
      console.error('Error submitting offer:', error);
    }
  };

  useEffect(() => {
    // Fetch all brands, colors, and cities on component load
    const fetchInitialData = async () => {
      const brandData = await getAllCarBrands();
      const colorData = await getColors();
      const cityData = await getCities();
      const userDate = await getUserInfo();
      setUserInfo(userDate);

      if (brandData) {
        const formattedBrands = brandData.map((brand) => ({
          value: brand.id,
          label: isArabic ? brand.nameAr : brand.nameEn,
          icon: brand.image,
        }));
        setBrands(formattedBrands);
      }

      if (colorData) {
        const formattedColors = colorData?.data?.items?.map((color) => ({
          value: color.id,
          label: isArabic ? color.nameAr : color.nameEn,
        }));
        setColors(formattedColors);
      }

      if (cityData) {
        const formattedCities = cityData.map((city) => ({
          value: city.id,
          label: isArabic ? city.nameAr : city.nameEn,
        }));
        setCities(formattedCities);
      }
    };

    fetchInitialData();
  }, []);

  const otherAction = async (brandId) => {
    console.log(brandId);
    const response = await getModelsOfBrand(brandId);
    console.log(response);
    const allModels = response.flat().map((model) => ({
      value: model.id,
      label: isArabic ? model.nameAr : model.nameEn,
    }));
    setModels(allModels);
  };

  const handleChangeFiles = (e, type) => {
    let files = Array.from(e.target.files);
    uploadFile(files, type);
  };

  const uploadFile = async (data, type) => {
    setLoadingFiles(true);
    const formData = new FormData();

    data.forEach((image, index) => {
      formData.append(`files`, data[index]);
    });
    console.log(formData);
    try {
      const response = await uploadFiles(formData);
      console.log('Success:', response);
      // setFiles(response);
      if (type == 'files') {
        setFiles(response);
      } else {
        setImages(response);
      }
      toast.success(
        t('myOrders.fileUploaded'),
        toastConfig,
        setLoadingFiles(false)
      );
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoadingFiles(false)
          );
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoadingFiles(false)
          );
        }
      }
    }
  };

  const removeItem = (index, type) => {
    if (type == 'images') {
      const updatedImages = images.filter((_, i) => i !== index);
      setImages(updatedImages);
    } else if (type == 'files') {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
    }
  };

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-10">
      <div className="w-[90%] mr-auto ml-auto">
        <div className="text-[17px] text-[#000000] font-500 text-center mb-10">
          {t('offerCycle.titleAddOffer')}
        </div>
        <form onSubmit={handleSubmit(handleAddOfferSubmit)}>
          <div className="flex flex-row items-center mb-3">
            <div className="flex flex-row items-center">
              <CheckBox
                type={'radio'}
                value={true}
                checked={userType == 'true'}
                handleChange={handleChangeUserType}
              />
              <div className="mx-3">{t('offerCycle.owner')}</div>
            </div>
            <div className="flex flex-row items-center">
              <CheckBox
                type={'radio'}
                value={false}
                checked={userType == 'false'}
                handleChange={handleChangeUserType}
              />
              <div className="mx-3">{t('offerCycle.mediator')}</div>
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mb-4 gap-4">
            <GeneralDropdown
              otherAction={otherAction}
              useAction={true}
              maxWidth={false}
              control={control}
              name="brandId"
              icon={userMail}
              label={t('myOrders.brandCar')}
              placeholder={t('myOrders.selectBrandCar')}
              options={brands}
            />
            <GeneralDropdown
              maxWidth={false}
              control={control}
              name="modelId"
              icon={LetterIcon}
              label={t('myOrders.carType')}
              placeholder={t('myOrders.selectCarType')}
              options={models}
            />
            <GeneralDropdown
              maxWidth={false}
              control={control}
              name="cityId"
              icon={userMail}
              label={t('myOrders.city')}
              placeholder={t('myOrders.selectCity')}
              options={cities}
            />
            <GeneralDropdown
              maxWidth={false}
              control={control}
              name="colorId"
              icon={LetterIcon}
              label={t('myOrders.color')}
              placeholder={t('myOrders.selectColor')}
              options={colors}
            />
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-4">
            <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[100%]">
              <YearDropdown
                maxWidth={false}
                control={control}
                name="modelYear"
                icon={userMail}
                label={t('listOrdersCarOwner.year')}
                placeholder={t('myOrders.from')}
                options={yearOptions.map((year) => ({
                  value: year,
                  label: year,
                }))}
              />
            </div>
            <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 gap-x-4">
              <Controller
                name="odo"
                control={control}
                render={({ field }) => (
                  <Counter
                    {...field}
                    icon={LetterIcon}
                    label={t('myOrders.kilometers')}
                    placeholder={t('myOrders.from')}
                    step={10000}
                  />
                )}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5">
            <div className="flex flex-col">
              {/* {userType == 'false' && (
                <div className="flex flex-row items-center">
                  <div className="flex flex-row items-center">
                    <CheckBox
                      type={'radio'}
                      value={2}
                      checked={priceType == 2}
                      handleChange={handleChangePriceType}
                    />
                    <div className="mx-3">{t('offerCycle.cash')}</div>
                  </div>
                  <div className="flex flex-row items-center">
                    <CheckBox
                      type={'radio'}
                      value={1}
                      checked={priceType == 1}
                      handleChange={handleChangePriceType}
                    />
                    <div className="mx-3">{t('offerCycle.percentage')}</div>
                  </div>
                </div>
              )} */}
              <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 gap-x-4">
                <Controller
                  name="price"
                  control={control}
                  render={({ field }) => (
                    <Counter
                      {...field}
                      icon={userMail}
                      label={t('myOrders.price')}
                      placeholder={t('myOrders.from')}
                      className="mt-2 w-[100%]"
                      step={10000}
                    />
                  )}
                />
                {userType == 'false' && (
                  <Controller
                    name="commission"
                    control={control}
                    render={({ field }) => (
                      <Input
                        {...field}
                        icon={userMail}
                        label={t('offerCycle.commission')}
                        placeholder={
                          // priceType == 1
                          //   ? t('offerCycle.commissionPercentage')
                          //   : 
                            t('offerCycle.commissionRial')
                        }
                        // className="mt-2 w-[100%] p-0"
                        type={'number'}
                        min={'0'}
                        // max={priceType == 1 && '100'}
                        className="rounded-lg border-[#D9D9D9] text-[#9E9E9E] text-[13px] w-[100%] mt-3 p-1 font-400"
                        useClassName={true}
                        // step={10000}
                      />
                    )}
                  />
                )}
              </div>

              <div class="w-full mx-auto mt-5">
                <label class="block border bg-[#ffffff] border-gray-300 rounded-lg p-2 flex items-center cursor-pointer w-[100%] p-3">
                  <FaUpload className="text-[#3E0292]" />
                  <div className="text-[#3E0292] font-400 mx-3">
                    {t('myOrders.addImages')}
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    multiple={true}
                    label={t('myOrders.addImages')}
                    placeholder={t('myOrders.selectImages')}
                    onChange={(e) => handleChangeFiles(e, 'images')}
                    accept=".jpeg,.jpg,.png"
                  />
                </label>
              </div>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-x-4 mt-3">
                {images.map((item, index) => (
                  <div className="border border-[#999999] rounded-[5px] flex items-center justify-center relative">
                    <img src={item} className="w-[100%]" />
                    <div
                      className="bg-[#ffffff] flex items-center cursor-pointer justify-center rounded-full w-[15px] h-[15px] absolute top-[-10px] right-[-10px]"
                      onClick={() => removeItem(index, 'images')}
                    >
                      <FaTimes className="text-[#ff0000] text-[12px]" />{' '}
                    </div>
                  </div>
                ))}
              </div>

              <div class="w-full mx-auto mt-5">
                <label class="block border bg-[#ffffff] border-gray-300 rounded-lg p-2 flex items-center cursor-pointer w-[100%] p-3">
                  <FaUpload className="text-[#3E0292]" />
                  <div className="text-[#3E0292] font-400 mx-3">
                    {t('myOrders.uploadFiles')}
                  </div>
                  <input
                    type="file"
                    className="hidden"
                    multiple={true}
                    label={t('myOrders.uploadFiles')}
                    placeholder={t('myOrders.selectFiles')}
                    onChange={(e) => handleChangeFiles(e, 'files')}
                    accept=".pdf,.doc,.docx"
                  />
                </label>
              </div>
              <div className="grid md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 gap-x-4 mt-3">
                {files.map((item, index) => (
                  <div className="border border-[#999999] rounded-[5px] flex items-center justify-center relative">
                    <div
                      onClick={() => (window.location.href = item)}
                      className="underline text-[#3E0292] font-400"
                    >
                      {t('myOrders.file')} {index + 1}
                    </div>
                    <div
                      className="bg-[#ffffff] flex items-center justify-center cursor-pointer rounded-full w-[15px] h-[15px] absolute top-[-10px] right-[-10px]"
                      onClick={() => removeItem(index, 'files')}
                    >
                      <FaTimes className="text-[#ff0000] text-[12px]" />{' '}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col max600:mt-5 sm:mt-5">
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <Textarea
                    rows={5}
                    label={t('myOrders.description')+ "  " +"("+description.length+"/"+1000+")"}
                    placeholder={t('myOrders.description')}
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          {/* <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4 mt-5">
              <div className="flex flex-col max600:mt-5 sm:mt-5">
                    <Controller
                    name="filesUrl"
                    control={control}
                    render={({ field }) => (
                     <Input
                      type={"file"}
                      name="filesUrl"
                      multiple={true}
                      label={t('myOrders.description')}
                      placeholder={t('myOrders.description')}
                      {...field}
                    />
                  )}
                  />
                </div>
              </div> */}

          <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5">
            <Button
              type="submit"
              loading={loading}
              title={t('offerCycle.addOffer')}
              dark={false}
              className="bg-[#00CEBC] border border-[#00CEBC]"
            />
            <Button
              onClick={() => {
                reset();
                navigate('/orders');
              }}
              title={t('offerCycle.deleteOffer')}
              dark={false}
              className="bg-[#FF4342] border border-[#FF4342] max600:mt-3 sm:mt-3 xl:mt-0 lg:mt-0 2xl:mt-0 md:mt-0"
            />
          </div>
        </form>

        <MConfirmation
          openModal={openModal}
          title={t('offerCycle.addOfferSuccess')}
          showFooter={false}
          content={
            <div className="flex items-center justify-center">
              <img
                src={SuccessAddOffer}
                className="w-54 h-54 mb-2"
                alt="Registration Success"
              />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default CreateOffer;
