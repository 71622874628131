import { useTranslation } from 'react-i18next';
import {
  loadingGreen,
  loadingRed,
  menuDotsWhite,
  opened,
} from '../../assets/icons';
import { user } from '../../assets/imgs';
import { defaultLettersCircle, languageAr } from '../../helpers/helper';

const Header = ({ user, toggleMenu, isOpen, closeMenu, actionOpenModal }) => {
  const { t } = useTranslation();
  const languageAr = true; // Replace with actual language detection logic
  return (
    <div className="bg-[#00CEBC] flex flex-row items-center justify-between rounded-t-[12px] p-1">
      <div className="flex flex-row items-center">
        <div>
          {!user?.receiverInfo?.profileImg ? (
            defaultLettersCircle(user?.name, 'w-[40px]', 'h-[40px]')
          ) : (
            <img
              src={user?.receiverInfo?.profileImg}
              alt={user?.receiverInfo?.fullName}
              className="w-[40px] h-[40px] rounded-full"
            />
          )}
        </div>
        <div className="text-white font-500 text-[16px] mx-3">
          {user?.receiverInfo?.fullName}
        </div>
      </div>
      <div className="flex flex-row items-center">
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center justify-around rounded-[10px] p-2 bg-white rounded-[7px] mx-5">
            <div>
              <img src={opened} alt="Opened" />
            </div>
            <div className="text-[#04C500] text-[11px] font-[600px] mx-3">
              {t('chats.open')}
            </div>
          </div>
        </div>
        <div className="relative">
          <img
            src={menuDotsWhite}
            onClick={toggleMenu}
            className="cursor-pointer"
            alt="Menu"
          />
          {isOpen && (
            <div
              className={`absolute ${
                languageAr ? 'left-0' : 'right-0'
              } mt-2 w-[100px] z-10 bg-white border border-gray-200 rounded-[12px] shadow-lg`}
              onMouseLeave={closeMenu}
            >
              <ul className="py-2">
                <li>
                  <div className="flex flex-row items-center justify-around p-3 cursor-pointer">
                    <div>
                      <img src={loadingGreen} alt="Completed" />
                    </div>
                    <div className="text-[#00CEDF] text-[15px] font-400">
                      {t('chats.completed')}
                    </div>
                  </div>
                </li>
                <li>
                  <div
                    className="flex flex-row items-center justify-around border-t border-t-[#E9E9E9] p-3 cursor-pointer"
                    onClick={actionOpenModal}
                  >
                    <div>
                      <img src={loadingRed} alt="Close" />
                    </div>
                    <div className="text-[#CE4223] text-[15px] font-400">
                      {t('chats.close')}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
