import React, { useState, useEffect, useRef } from 'react';
import '../../../styles/chat.css';
import { Tabs } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { HiAdjustments, HiClipboardList, HiUserCircle } from 'react-icons/hi';
import { MdDashboard } from 'react-icons/md';
import { notFoundChats } from '../../../assets/icons';
import { user } from '../../../assets/imgs';
import {
  CardUser,
  CInput,
  CloseChat,
  Header,
  MConfirmation,
  Message,
  CarOwnerCardUser,
  CarOwnerHeader,
  DateSeparator,
} from '../../../components';
import { groupMessagesByDate } from '../../../utils/messageUtils';
import { themeTabs } from './../../../helpers/contents';
import { database } from '../../../firebase';
import {
  ref,
  onValue,
  push,
  serverTimestamp,
  query,
  orderByChild,
  get,
  onChildAdded,
  set,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from 'firebase/storage';
import { getDatabase, ref as dbRef } from 'firebase/database';
import { getUserData } from '../../../services/apis/user';
import {
  initiateChat,
  getChatListOfCarOwner,
  getChatList,
} from '../../../services/apis/chat';
const Chat = () => {
  const { t } = useTranslation();
  const [userInfo, setUserInfo] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModalClose, setOpenModalClose] = useState(false);
  const [messagesArr, setMessagesArr] = useState([]);
  const [conversation_id, setConversation_id] = useState(
    selectedUser?.conversationId
  );
  const [carOwnerChatList, setCarOwnerChatList] = useState([]);
  const [chatList, setChatList] = useState([]);

  const startFetch = () => {
    try {
      const chatRef = ref(database, `Mshrai/chats/${conversation_id}`);
      get(chatRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const messages = Object.values(data).sort(
              (a, b) => a.createdAt - b.createdAt
            );
            setMessagesArr(messages);
          } else {
            console.log('No messages found');
          }
        })
        .catch((error) => {
          console.error('Error fetching messages:', error);
        });
    } catch (error) {
      console.error('Error in startFetch:', error);
    }
  };

  const startFetchCarOwner = () => {
    try {
      const chatRef = ref(database, `chats/${conversation_id}`);
      get(chatRef)
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            const messages = Object.values(data).sort(
              (a, b) => a.createdAt - b.createdAt
            );
            setMessagesArr(messages);
          } else {
            console.log('No messages found');
          }
        })
        .catch((error) => {
          console.error('Error fetching messages:', error);
        });
    } catch (error) {
      console.error('Error in startFetchCarOwner:', error);
    }
  };

  const fetchNewMessage = () => {
    try {
      const chatRef = ref(database, `Mshrai/chats/${conversation_id}`);
      onValue(chatRef, (snapshot) => {
        const newMessages = snapshot.val();
        if (newMessages) {
          const newMessagesArray = Object.values(newMessages).sort(
            (a, b) => a.createdAt - b.createdAt
          );
          setMessagesArr(newMessagesArray);
        }
      });
    } catch (error) {
      console.error('Error listening for new messages:', error);
    }
  };

  const fetchNewMessageCarOwner = () => {
    try {
      const chatRef = ref(database, `chats/${conversation_id}`);
      onValue(chatRef, (snapshot) => {
        const newMessages = snapshot.val();
        if (newMessages) {
          const newMessagesArray = Object.values(newMessages).sort(
            (a, b) => a.createdAt - b.createdAt
          );
          setMessagesArr(newMessagesArray);
        }
      });
    } catch (error) {
      console.error('Error listening for new messages:', error);
    }
  };

  // Convert file or image to Base64
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const sendMessage = async ({ message, file, image }) => {
    try {
      let fileUrl = '';
      let imageUrl = '';

      // Step 1: Convert image to Base64 if it exists
      if (image) {
        const imageBase64 = await convertToBase64(image);
        imageUrl = imageBase64; // Store the Base64 string
      }

      // Step 2: Convert file to Base64 if it exists
      if (file) {
        const fileBase64 = await convertToBase64(file);
        fileUrl = fileBase64; // Store the Base64 string
      }

      // Step 3: Prepare the message data with sender and receiver information
      const messageData = {
        text: message || (image && 'image.png') || (file && 'attachment'),
        createdAt: Date.now(),
        image: imageUrl,
        file: fileUrl,
        senderId: userInfo?.userId,
        senderName: userInfo?.fullName,
        senderImage: userInfo?.profileImg,
        receiverId: selectedUser?.receiverInfo?.id,
        receiverName: selectedUser?.receiverInfo?.fullName,
        receiverImage: selectedUser?.receiverInfo?.profileImg,
      };

      // Step 4: Push the message data to Firebase Realtime Database
      const chatRef = dbRef(database, `Mshrai/chats/${conversation_id}`);
      const newMessageRef = push(chatRef);
      await set(newMessageRef, messageData);

      console.log('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const createChat = async () => {
    if (
      !selectedUser?.offerId ||
      !userInfo?.userId ||
      !selectedUser?.receiverInfo?.id
    ) {
      return;
    }

    const controller = new AbortController();
    const { signal } = controller;

    try {
      const body = {
        offerId: selectedUser.offerId,
        buyerId: userInfo.userId,
        mediatorId: selectedUser.receiverInfo.id,
      };

      const response = await initiateChat(body, { signal });
      const conversationId = response?.conversationId;

      if (conversationId) {
        setConversation_id(conversationId);
        localStorage.setItem('conversationID', conversationId.toString());
      } else {
        console.error('Conversation ID not found in response');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Chat creation request was aborted');
      } else {
        console.error(
          'Error creating chat:',
          error.response?.data || error.message
        );
      }
    }
  };

  useEffect(() => {
    if (selectedUser?.conversationId) {
      setConversation_id(selectedUser?.conversationId);
    }
  }, [selectedUser]);

  useEffect(() => {
    let chatRef;
    let unsubscribe;

    const setupMessageListeners = async () => {
      if (!conversation_id) {
        await createChat();
        return;
      }

      // Clear existing messages when switching chats
      setMessagesArr([]);

      // Set up Firebase listener
      chatRef = ref(database, `Mshrai/chats/${conversation_id}`);
      unsubscribe = onValue(chatRef, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          const messages = Object.values(data).sort(
            (a, b) => a.createdAt - b.createdAt
          );
          setMessagesArr(messages);
        }
      });
    };

    setupMessageListeners();

    // Cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [conversation_id]);

  const handleChange = React.useCallback((user) => {
    setSelectedUser(user);
    setConversation_id(user.conversationId);
    setMessagesArr([]); // Clear messages immediately when switching
  }, []);

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserData();
      setUserInfo(response);
    };

    const initializeChatList = async () => {
      try {
        // Get location state using window.history
        // Get location state from React Router
        const location = window.location;
        const state = location.state || {};
        const autoSelectChat = state?.autoSelectChat;
        const selectedOffer = state?.selectedOffer;
        const fromOfferDetails = state?.fromOfferDetails;

        // Fetch chat lists
        const [chatListResponse, carOwnerChatListResponse] = await Promise.all([
          getChatList(),
          getChatListOfCarOwner(),
        ]);

        setChatList(chatListResponse);
        setCarOwnerChatList(carOwnerChatListResponse);

        // Handle auto-selection from offer details
        if (autoSelectChat && selectedOffer && fromOfferDetails) {
          const allChats = [
            ...(chatListResponse?.data?.items || []),
            ...(carOwnerChatListResponse?.data?.items || []),
          ];

          // Look for existing chat across both lists
          const existingChat = allChats.find(
            (chat) => chat.offerId === selectedOffer.offerId
          );

          if (existingChat) {
            // Use existing chat
            setSelectedUser(existingChat);
            setConversation_id(existingChat.conversationId);
          } else {
            // Create new chat object
            const newChatObject = {
              offerId: selectedOffer.offerId,
              conversationId: selectedOffer.conversationId,
              receiverInfo: selectedOffer.receiverInfo,
              lastMessage: null,
              unreadCount: 0,
              lastMessageTime: new Date().toISOString(),
            };

            setSelectedUser(newChatObject);

            if (!selectedOffer.conversationId) {
              await createChat();
            } else {
              setConversation_id(selectedOffer.conversationId);
            }
          }

          // Force a message fetch
          setTimeout(() => {
            startFetch();
            startFetchCarOwner();
          }, 500);
        } else if (chatListResponse?.data?.items?.length > 0) {
          setSelectedUser(chatListResponse.data.items[0]);
          setConversation_id(chatListResponse.data.items[0].conversationId);
        }

        // If chatList is empty, set the first car owner chat as the selected user
        if (
          !chatListResponse?.data?.items?.length &&
          carOwnerChatListResponse?.data?.items?.length > 0
        ) {
          setSelectedUser(carOwnerChatListResponse.data.items[0]);
        }
      } catch (error) {
        console.error('Error fetching chat lists:', error);
      }
    };
    getUser();
    initializeChatList();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] py-6">
      <div className="w-[95%] max-w-[1400px] mx-auto">
        <Tabs theme={themeTabs} aria-label="Tabs Chat" variant="fullWidth">
          {chatList?.data?.items?.length > 0 ? (
            <Tabs.Item active title={t('chats.chats')}>
              <div className="grid grid-cols-[40%_60%] max600:grid-cols-1 gap-x-4 ">
                <div className="h-[75vh] overflow-y-auto overflow-x-hidden custom-scrollbar pr-2">
                  {chatList?.data?.items?.map((user, index) => (
                    <CardUser
                      key={`${user.offerId}-${index}`}
                      user={user}
                      receivedMessage={false}
                      selected={selectedUser?.offerId === user.offerId}
                      onClick={() => handleChange(user)}
                    />
                  ))}
                </div>
                <div className="bg-[#00CEBC26] shadow-md rounded-[12px] h-[75vh] max600:mt-5">
                  {selectedUser && (
                    <>
                      <Header
                        user={selectedUser}
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        toggleMenu={toggleMenu}
                        actionOpenModal={() => setOpenModalClose(true)}
                      />
                      <div className="h-[45vh] overflow-y-auto overflow-x-hidden flex-1 px-4">
                        {Object.entries(groupMessagesByDate(messagesArr)).map(
                          ([date, messages]) => (
                            <div key={date}>
                              <DateSeparator date={date} />
                              {messages.map((message, index) => (
                                <Message
                                  key={`${message.id}-${index}`}
                                  message={message}
                                  userInfo={userInfo}
                                  user={selectedUser}
                                  showAvatar={
                                    index === 0 ||
                                    messages[index - 1]?.senderId !==
                                      message.senderId
                                  }
                                />
                              ))}
                            </div>
                          )
                        )}
                      </div>
                      <div className="my-[5px] mb-12">
                        <CInput onSend={sendMessage} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Tabs.Item>
          ) : (
            <Tabs.Item title={t('chats.ownerChats')}>
              <div className="flex flex-col items-center justify-center">
                <div>
                  <img src={notFoundChats} />
                </div>
                <div className="text-[17px] text-black font-500 mt-10">
                  {t('chats.notFoundChats')}
                </div>
              </div>
            </Tabs.Item>
          )}

          {carOwnerChatList?.data?.items?.length > 0 ? (
            <Tabs.Item active title={t('chats.ownerChats')}>
              <div className="grid grid-cols-[40%_60%] max600:grid-cols-1 gap-x-4">
                <div className="h-[60vh] overflow-y-auto overflow-x-hidden">
                  {carOwnerChatList?.data?.items?.map((user, index) => (
                    <CarOwnerCardUser
                      key={`${user.offerId}-${index}`}
                      user={user}
                      receivedMessage={false}
                      selected={selectedUser?.offerId === user.offerId}
                      onClick={() => handleChange(user)}
                    />
                  ))}
                </div>
                <div className="bg-[#00CEBC26] shadow-md rounded-[12px] h-[60vh] max600:mt-5">
                  {selectedUser && (
                    <>
                      <CarOwnerHeader
                        user={selectedUser}
                        isOpen={isOpen}
                        closeMenu={closeMenu}
                        toggleMenu={toggleMenu}
                        actionOpenModal={() => setOpenModalClose(true)}
                      />
                      <div className="h-[45vh] overflow-y-auto overflow-x-hidden flex-1 px-4">
                        {messagesArr.map((message, index) => (
                          <Message
                            key={`${message.id}-${index}`}
                            message={message}
                            sender={message.senderId === 'currentUserId'}
                            user={selectedUser}
                          />
                        ))}
                      </div>
                      <div className="my-[5px]">
                        <CInput onSend={sendMessage} />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Tabs.Item>
          ) : (
            <Tabs.Item title={t('chats.ownerChats')}>
              <div className="flex flex-col items-center justify-center">
                <div>
                  <img src={notFoundChats} />
                </div>
                <div className="text-[17px] text-black font-500 mt-10">
                  {t('chats.notFoundChats')}
                </div>
              </div>
            </Tabs.Item>
          )}
        </Tabs>
      </div>

      <MConfirmation
        loading={loading}
        openModal={openModalClose}
        showFooter={true}
        setOpenModal={setOpenModalClose}
        title={t('chats.causeClose')}
        content={<CloseChat />}
        titleButton={t('chats.confirm')}
        singleButton={true}
        confirm={confirm}
      />
    </div>
  );
};

export default Chat;
