import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { appStore, googlePlay, logo, LogoEn } from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import { userToken } from '../../helpers/helper';
import i18n from '../../languages/i18n';
const ServicesCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="bg-[#E1E9FF] rounded-lg p-10 w-[90%] ml-auto mr-auto my-16">
      <div className="flex">
        <img src={i18n.language == Lang.ar ? logo : LogoEn} />
      </div>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 sm:grid-cols-1 mt-10">
        <div>
          <div className="text-[15px] text-[#444444] font-700">
            {t('home.services.aboutUs')}
          </div>
          <div
            className="text-[15px] text-[#444444] mt-5 cursor-pointer hover:underline font-400"
            onClick={() => navigate('/')}
          >
            {t('home.services.services')}
          </div>
          <div
            className="text-[15px] text-[#444444] mt-3 cursor-pointer hover:underline font-400"
            onClick={() => navigate('/terms')}
          >
            {t('home.services.terms')}
          </div>
          <div
            className="text-[15px] text-[#444444] mt-3 cursor-pointer hover:underline font-400"
            onClick={() => navigate('/privacy')}
          >
            {t('home.services.privacy')}
          </div>
          <div
            className="text-[15px] text-[#444444] mt-3 cursor-pointer hover:underline font-400"
            onClick={() => navigate('/contact-us')}
          >
            {t('home.services.contactUs')}
          </div>
        </div>
        <div className="max600:mt-5">
          <div className="text-[15px] text-[#444444] font-700">
            {t('home.services.speedLinks')}
          </div>
          {userToken && (
            <div
              className="text-[15px] text-[#444444] mt-5 cursor-pointer hover:underline font-400"
              onClick={() => navigate('/chats')}
            >
              {t('home.services.liveChat')}
            </div>
          )}
          <div
            className="text-[15px] text-[#444444] mt-3 cursor-pointer hover:underline font-400"
            onClick={() => navigate('/about-us')}
          >
            {t('home.services.aboutUs')}
          </div>
        </div>
        {/* <div>
                 <div className="text-[15px] text-[#444444] font-bold">{t("home.services.trademarks")}</div>
                 <div className="grid md:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 lg:grid-cols-2 sm:grid-cols-1 mt-5">
                     <div className="text-[15px] text-[#444444]">{t("home.services.mercides")}</div>
                     <div className="text-[15px] text-[#444444]">{t("home.services.toyota")}</div>
                 </div>
               
                 </div> */}
        <div className="md:mt-5 sm:mt-5 max600:mt-5">
          <div className="text-[15px] text-[#444444] font-700">
            {t('home.services.downloadApp')}
          </div>
          <div className="mt-5">
            <img
              src={googlePlay}
              className="cursor-pointer"
              onClick={() =>
                window.open(
                  'https://play.google.com/store/apps/details?id=com.miso.mshraiB2C',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            />
            <img
              src={appStore}
              className="cursor-pointer mt-3"
              onClick={() =>
                window.open(
                  'https://apps.apple.com/eg/app/%D9%85%D8%B4%D8%B1%D8%A7%D9%8A/id6553995179',
                  '_blank',
                  'noopener,noreferrer'
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesCard;
