import { z } from 'zod';
import { useTranslation } from 'react-i18next';

const useAddOrderSchema = () => {
  const { t } = useTranslation();

  return z.object({
    carBrands: z.array(z.number()).min(1, t('addOffer.brandSelect')),
    carType: z.array(z.number()).refine((data) => data.every((id) => id > 0), {
      message: t('addOffer.modelSelect'),
    }),
    city: z.array(z.number()).optional(),
    color: z.array(z.number()).optional(),
    yearFrom: z.coerce
      .number({
        required_error: t('addOffer.yearFromRequired'),
        invalid_type_error: t('addOffer.yearFromRequired'),
      })
      .min(1, t('addOffer.yearFromRequired')),
    yearTo: z.coerce
      .number({
        required_error: t('addOffer.yearToRequired'),
        invalid_type_error: t('addOffer.yearToRequired'),
      })
      .min(1, t('addOffer.yearToRequired'))
      .superRefine((yearTo, ctx) => {
        const yearFrom = ctx.parent?.yearFrom; // Use ctx.parent to access sibling fields.
        if (yearFrom && yearTo && yearTo < yearFrom) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('addOffer.yearToGreater'),
          });
        }
        return true;
      }),
    kilometersFrom: z.number(),
    kilometersTo: z.number(),
    priceFrom: z.number({
      required_error: t('addOffer.priceFromRequired'),
      invalid_type_error: t('addOffer.priceFromRequired'),
    }),
    priceTo: z
      .number({
        required_error: t('addOffer.priceToRequired'),
        invalid_type_error: t('addOffer.priceToRequired'),
      })
      .superRefine((priceTo, ctx) => {
        const priceFrom = ctx.parent?.priceFrom; // Access the sibling field priceFrom correctly.
        if (priceFrom && priceTo && priceTo < priceFrom) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t('addOffer.priceToGreater'),
          });
        }
        return true;
      }),
    description: z.string().optional(),
    requestTitle: z.string().optional(),
    phoneNumber: z.string().optional(),
    whatsappNumber: z.string().optional(),
  });
};

export default useAddOrderSchema;
