import { user } from '../../assets/imgs';
import { star } from '../../assets/icons';
import { defaultLettersCircle } from '../../helpers/helper';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import { useTranslation } from 'react-i18next';

dayjs.extend(relativeTime);
const CardUser = ({
  selected = false,
  receivedMessage = false,
  onClick,
  user,
}) => {
  const { t, i18n } = useTranslation();
  return (
    <div
      className={`${
        receivedMessage
          ? 'bg-[#B8CBFF] hover:bg-[#a6bcff]'
          : selected
            ? 'bg-[#00CEBC] hover:bg-[#00bfb0]'
            : 'bg-[#D6E1FF] hover:bg-[#c4d3ff]'
      } rounded-[12px] m-2 shadow-md cursor-pointer transition-all duration-200 transform hover:scale-[1.01]`}
      onClick={onClick}
    >
      <div className="flex flex-row justify-between">
        <div className="p-3 flex flex-row items-center">
          <div className="relative">
            {!user?.receiverInfo?.profileImg ? (
              <div className="w-[60px] h-[60px] rounded-full shadow-lg border-2 border-white flex items-center justify-center bg-gradient-to-r from-[#00CEBC] to-[#3E0292] text-white text-xl font-semibold">
                {defaultLettersCircle(user.name, 'w-[60px]', 'h-[60px]')}
              </div>
            ) : (
              <img
                src={user.receiverInfo?.profileImg}
                alt={user.receiverInfo?.fullName}
                className="w-[60px] h-[60px] rounded-full object-cover border-2 border-white shadow-lg"
              />
            )}
            {user.chatStatus && (
              <div className="absolute bottom-0 right-0 w-4 h-4 rounded-full bg-green-500 border-2 border-white"></div>
            )}
          </div>
          <div className="flex flex-col ml-3">
            <div className="flex flex-row items-center">
              <div
                className={`${
                  receivedMessage
                    ? 'text-[#303030] font-bold'
                    : selected
                      ? 'text-white font-semibold'
                      : 'text-[#303030] font-semibold'
                } text-[16px] truncate max-w-[200px]`}
              >
                {user.receiverInfo?.fullName}
              </div>
              <div className="flex flex-row items-center mx-2">
                <img src={star} alt="Rating" />
                <div
                  className={
                    selected
                      ? 'text-white font-500 text-[15px] mx-2'
                      : 'text-[#303030] font-500 text-[15px] mx-2'
                  }
                >
                  {user.receiverInfo?.medAvgRating}
                </div>
              </div>
            </div>
            <div
              className={`${
                receivedMessage
                  ? 'text-[#7B7B7B] font-700'
                  : selected
                    ? 'text-white font-400'
                    : 'text-[#7B7B7B] font-400'
              } text-[16px] mt-3`}
            >
              {user.lastMessage || user.message}{' '}
              {/* Adjust based on your data */}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-between p-2 gap-3">
          <div className="bg-[#FFFFFF4D] rounded-[10px] flex flex-row items-center font-400 text-[15px] p-1">
            <div
              className={`${
                receivedMessage
                  ? 'text-[#00CEBC]'
                  : selected
                    ? 'text-black'
                    : 'text-[#00CEBC]'
              }`}
            >
              #{user.offerId}
            </div>

            <div
              className={`${
                receivedMessage
                  ? 'text-[#00CEBC]'
                  : selected
                    ? 'text-black'
                    : 'text-[#00CEBC]'
              }`}
            >
              :
            </div>
            <div className="text-black">id</div>
          </div>
          <div className="bg-[#FFFFFF4D]   rounded-full flex flex-col items-center font-400 text-[15px]">
            <div
              className={`${
                receivedMessage
                  ? 'text-[#00CEBC]'
                  : selected
                    ? 'text-black'
                    : 'text-[#00CEBC]'
              }`}
            >
              {user.chatStatus}
            </div>
          </div>

          {receivedMessage && (
            <div className="rounded-full bg-[#00CEBC] text-white font-700 text-[13px] w-[40%] flex items-center justify-center my-3">
              {/* You can show the count of unread messages here */}2
            </div>
          )}
          {/* <div
            className={`${
              receivedMessage
                ? 'text-[#00CEBC]'
                : selected
                ? 'text-white '
                : 'text-[#BBBBBB]'
            } font-400 text-[11px]`}
          >
            {user.cratedAt}
          </div> */}
          <div className="rounded-full flex flex-col items-center font-200 text-[10px]">
            <div
              className={`${
                receivedMessage
                  ? 'text-[#00CEBC]'
                  : selected
                    ? 'text-black'
                    : 'text-[#00CEBC]'
              }`}
            >
              {user.lastMessageTime &&
                (dayjs().diff(dayjs(user.lastMessageTime), 'hour') < 24
                  ? dayjs(user.lastMessageTime)
                      .locale(i18n.language)
                      .fromNow()
                      .replace('ago', i18n.language === 'ar' ? 'منذ' : 'ago')
                  : dayjs(user.lastMessageTime).format('HH:mm'))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardUser;
