import Environment from '../Environment';
import mainService from '../mainService';

export function markNotificationAsRead(notificationId) {
  return mainService
    .put(`${Environment.notifications}/${notificationId}/read`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getNotifications(data) {
  return mainService
    .get(Environment.notifications, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function addNotificationSetting(data) {
  return mainService
    .post(Environment.notificationSetting, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getNotificationSetting(data) {
  return mainService
    .get(Environment.notificationSetting, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}
