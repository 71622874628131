import { Spinner } from 'flowbite-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { carOwnerNotFound, plusWhite } from '../../../assets/icons';
import {
  ButtonIcon,
  CardOrderOwner,
  CardSkeleton,
  FilterOrder,
  InputSearch,
  MConfirmation,
  NotFoundCars,
} from '../../../components';
import { FilterOrderContext } from '../../../context/FilterOrderContext';
import { userRole } from '../../../helpers/helper';
import { getFilterOrdersOwner, getOrders } from '../../../services/apis/owner';
import { getUserData } from '../../../services/apis/user';
import { UserContext } from '../../../context/UserContext';
import CompleteProfileModal from '../../../components/modals/CompleteProfileModal';

const List = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState('');
  const [userInfo, setUserInfo] = useState(null);

  const filterOrderContext = useContext(FilterOrderContext);

  let filterOrderContextCondition =
    filterOrderContext?.brands?.length == 0 &&
    filterOrderContext?.models?.length == 0 &&
    filterOrderContext?.statuses?.length == 0 &&
    filterOrderContext?.cities?.length == 0 &&
    filterOrderContext?.colors?.length == 0 &&
    !filterOrderContext?.priceFrom &&
    !filterOrderContext?.priceTo &&
    !filterOrderContext?.odoFrom &&
    !filterOrderContext?.odoTo;
  console.log(filterOrderContextCondition);
  console.log(filterOrderContext);
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const observerRef = useRef(null);
  const prevFilter = useRef(filterOrderContext);

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserData();
      setUserInfo(response);
      setUserData(response);
    };

    getUser();
  }, []);

  const hasFilterChanged = useCallback(() => {
    return (
      JSON.stringify(prevFilter.current) !== JSON.stringify(filterOrderContext)
    );
  }, [filterOrderContext]);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchText(e.target.value);
    console.log(searchText);
  };

  const searchAction = () => {
    fetchOrders(true);
  };

  // if (loading) {
  //     return <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
  //         <CardSkeleton number={3} height={"300px"} />
  //     </div>;
  // }

  // if (!orders || orders.length === 0) {
  //     return <NotFoundCars/>
  // }

  const fetchOrders = async (reset = false) => {
    setLoading(true);
    console.log(decodeURIComponent(searchText));
    try {
      let body = {};
      if (!filterOrderContextCondition) {
        body = {
          brandIds: filterOrderContext.brands.map((brand) => brand.value),
          modelIds: filterOrderContext.models.map((model) => model.value),
          status: filterOrderContext.statuses.map((status) => status.value),
          cityIds: filterOrderContext.cities.map((city) => city.value),
          priceFrom: filterOrderContext.priceFrom,
          priceTo: filterOrderContext.priceTo,
          odoFrom:filterOrderContext.odoFrom,
          odoTo:filterOrderContext.odoTo,
          colorIds:filterOrderContext.colors.map((color) => color.value),
          filterOwnRequests: true,
        };
      } else {
        body = {
          brandIds: [],
          modelIds: [],
          status: [],
          cityIds: [],
          priceFrom: null,
          priceTo: null,
          odoFrom:null,
          odoTo:null,
          colorIds:[],
          filterOwnRequests: true,
        };
      }

      let data = {
        params: {
          page: reset ? 0 : currentPage,
          size: 3,
        },
        body: body,
      };
      console.log(decodeURIComponent(searchText))

      if (searchText) {
        data.params.searchText = decodeURIComponent(searchText);
      }

      const response = await getFilterOrdersOwner(data.body, data.params);

      const newOrders = response.data.page.content;
      setOrders((prevPosts) =>
        reset ? newOrders : [...prevPosts, ...newOrders]
      );
      setHasMore(newOrders.length > 0);
      setOpenFilter(false);
      prevFilter.current = filterOrderContext;
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (hasFilterChanged()) {
      fetchOrders(true);
    } else if (hasMore && !loading) {
      fetchOrders();
    }
  }, [currentPage]);

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <div className='relative'>
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          action={() => setOpenFilter(!openFilter)}
          showAdd={true}
          showSearch={true}
          add={() => {
            if (!userData?.fullName) {
              setShowProfileModal(true);
              setPendingNavigation('/car-owner/add');
            } else {
              navigate('/car-owner/add');
            }
          }}
          search={() => searchAction()}
        />
        {openFilter && (
        <div className='absolute top-15 left-0 bg-white p-3 rounded-[12px] shadow-md w-[400px] max600:w-full'>
          <div className='p-3 text-center text-[15px] text-[#3E0292] font-bold'>{t('filterModal.filterWith')}</div>
            <FilterOrder
              from="owner"
              close={() => setOpenFilter(false)}
              confirm={fetchOrders}
              hideStatus={true}
              showColor={true}
              showOdo={true}
            />
        </div>
        )}
        </div>
        <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-16 my-5">
          {orders.map((order, index) => (
            <div ref={index === orders.length - 1 ? lastItemRef : null}>
              <CardOrderOwner
                order={order}
                key={order.id}
                refreshOrders={fetchOrders}
                goEditPage={() =>
                  navigate(`/car-owner/${order?.sellMediationId}/edit`)
                }
                onClick={() =>
                  navigate(`/car-owner/${order?.sellMediationId}/details`)
                }
                goOffers={() =>
                  navigate(`/car-owner/${order?.sellMediationId}/offers`)
                }
              />
            </div>
          ))}
        </div>
        {loading && (
          <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
            <Spinner
              color="success"
              aria-label="Extra large spinner example"
              size="xl"
            />
          </div>
        )}
        {!hasMore && (
          <p className="mt-20 text-center text-[#3E0292]">
            {t('myOrders.loadingAllOrders')}
          </p>
        )}
        {/* <MConfirmation
          loading={loading}
          openModal={openFilter}
          showFooter={false}
          setOpenModal={setOpenFilter}
          title={t('filterModal.filterWith')}
          content={
            <FilterOrder
              from="owner"
              close={() => setOpenFilter(false)}
              confirm={fetchOrders}
              hideStatus={true}
              showColor={true}
              showOdo={true}
            />
          }
          singleButton={false}
          confirm={fetchOrders}
        /> */}

        <CompleteProfileModal
          isOpen={showProfileModal}
          onClose={() => setShowProfileModal(false)}
          onConfirm={() => {
            setShowProfileModal(false);
            navigate('/profile');
          }}
        />
      </div>
    </div>
  );
};

export default List;
