export const groupMessagesByDate = (messages) => {
  const grouped = messages.reduce((groups, message) => {
    const date = new Date(message.createdAt).toLocaleDateString();
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(message);
    return groups;
  }, {});

  // Sort messages within each group
  Object.keys(grouped).forEach((date) => {
    grouped[date].sort((a, b) => a.createdAt - b.createdAt);
  });

  return grouped;
};

export const formatMessageTime = (timestamp) => {
  return new Date(timestamp).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
};
