import React, { useState } from 'react';
import i18n from '../../languages/i18n';
import { FaLongArrowAltLeft } from 'react-icons/fa';
import InputRHF from '../../components/inputs/InputRHF';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { changePassword } from '../../services/auth';
import { toast } from 'react-toastify';
import SuccessChangePassword from './SuccessChangePassword';
const ChangePasswordModal = ({ isOpen, isClose }) => {
  const { t } = useTranslation();
  const [successChangePass, setSuccessChangePass] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const newPassword = watch('newPassword');

  const onSubmit = async (data) => {
    try {
      await changePassword(data);
      isClose(); // Close the current modal
      toast.success(t('profile.passchangeSuccess'));
      setSuccessChangePass(true);
    } catch (error) {
      toast.error(t('profile.passchangeError'));
    }
  };

  if (!isOpen) return null;
  document.body.dir = i18n.dir();

  return (
    <div
      id="static-modal"
      data-modal-backdrop="static"
      tabIndex="-1"
      aria-hidden="true"
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <div className="bg-white rounded-lg p-6 w-full max-w-[430px] h-auto max-h-[554px] relative rounded-2xl">
        <button
          className="absolute top-4 right-4 bg-[#00CEBC] rounded-lg text-gray-500 hover:text-gray-800"
          onClick={isClose}
        >
          <FaLongArrowAltLeft size={24} className="text-white" />
        </button>

        <div className="text-center">
          <h2 className="text-sm font-small mb-4">{t('profile.changePass')}</h2>
        </div>

        <div className="text-center">
          <h2 className="text-sm font-bold mb-4">
            {t('profile.EnterNewPassword')}{' '}
          </h2>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <InputRHF
              label={t('profile.oldPassword')}
              type="password"
              placeholder={t('profile.oldPassword')}
              {...register('currentPassword', { required: true, minLength: 6 })}
            />
            {errors.currentPassword && (
              <span className="text-red-500">هذا الحقل مطلوب</span>
            )}
          </div>

          <div className="mb-4">
            <InputRHF
              label={t('profile.newPassword')}
              type="password"
              placeholder={t('profile.newPassword')}
              {...register('newPassword', { required: true })}
            />

            {errors.newPassword && (
              <span className="text-red-500">
                هذا الحقل مطلوب ويجب أن يتكون من 6 أحرف على الأقل
              </span>
            )}
          </div>

          <div className="mb-4">
            <InputRHF
              label={t('profile.confirmNewPassword')}
              type="password"
              placeholder={t('profile.confirmNewPassword')}
              {...register('confirmNewPassword', {
                required: true,
                validate: (value) =>
                  value === newPassword || t('profile.notMatch'),
              })}
            />
            {errors.confirmNewPassword && (
              <span className="text-red-500">
                {errors.confirmNewPassword.type === 'validate'
                  ? errors.confirmNewPassword.message
                  : 'هذا الحقل مطلوب'}
              </span>
            )}
          </div>

          <div className="flex justify-center mt-5 gap-x-5">
            <button
              type="submit"
              className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[10px] text-bold text-white px-10 py-4 w-[40%] shadow-lg text-center"
            >
              {t('profile.submit')}{' '}
            </button>
            <button
              type="button"
              className="border border-[#00CEBC] rounded-[14px] text-[10px] text-[#00CEBC] px-10 py-4 w-[40%] shadow-lg text-center"
              onClick={isClose}
            >
              {t('profile.close')}{' '}
            </button>
          </div>
        </form>
      </div>
      {successChangePass === true ? (
        <SuccessChangePassword
          isOpen={successChangePass}
          Close={closeSuccessChangePass}
        />
      ) : null}
    </div>
  );
  ƒ;
};

export default ChangePasswordModal;
