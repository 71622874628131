import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  BMW,
  Calendar,
  car,
  carOrder,
  chatWhite,
  checkOffer,
  clock,
  confirmDelete,
  confirmIcon,
  grayCar,
  kilometers,
  loadingGreen,
  loadingRed,
  location,
  menuDots,
  moneyGreen,
  moneyWhite,
  pen,
  statisticsOrder,
  timesOffer,
  trash,
} from './../../../assets/icons';
import { Tooltip } from 'flowbite-react';
import {
  ButtonBadge,
  ButtonIcon,
  CarColor,
  InputSearch,
  MConfirmation,
  CardSkeleton,
  Button,
  ConfirmRequest,
  PaidRequest,
  MediatorRate,
  Input,
  Textarea,
  CompleteOrder,
  ChooseMediator,
  CheckBox,
} from './../../../components';
import { Lang } from './../../../helpers/contents';
import i18n from './../../../languages/i18n';
import Environment from './../../../services/Environment';
import { get } from './../../../services/Request';
import {
  convertDate,
  languageAr,
  toastConfig,
  userRole,
} from './../../../helpers/helper';
import { Bounce, toast } from 'react-toastify';
import {
  addOfferMediator,
  addOfferToSaved,
  closeOrderCarOwner,
  confirmationCarOwnerOffer,
  deleteOrderCar,
  editOfferMediator,
  getCarOwnerOfferWithRequestForCompleteRequest,
  getConfirmationRequestOwner,
  getOrderDetails,
  paidConfirmationRequestOwner,
  updateConfirmationRequestOwner,
} from '../../../services/apis/owner';
import { getStatusConfirmationOrder } from '../../../services/apis/owner';
import { Carousel } from 'react-responsive-carousel';
import {
  closeOrderCar,
  getConfirmationRequest,
  paidConfirmationRequest,
  rateMediator,
  updateConfirmationRequest,
} from '../../../services/apis/order';
import {
  confirmationOffer,
  getOfferWithRequestForCompleteRequest,
} from '../../../services/apis/offer';
import { CompleteOfferContext } from '../../../context/CompleteOfferContext';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { getUserInfo } from '../../../services/apis/user';
import { defaultLettersCircle } from '../../../helpers/helper';
import { MdShare } from 'react-icons/md';
const OrderDetails = () => {
  const completeOfferContext = useContext(CompleteOfferContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation()
  const { id } = useParams();
  const [order, setOrder] = useState({});
  const [confirmationOrder, setConfirmationOrder] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [openModalCars, setOpenModalCars] = useState(false);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState(false);
  const [openPaidRequestModal, setOpenPaidRequestModal] = useState(false);
  const [confirmationRequest, setConfirmationRequest] = useState(null);
  const [confirmationId, setConfirmationId] = useState(null);
  const [hideActionModal, setHideActionModal] = useState(false);
  const [openRateMediatorModal, setOpenRateMediatorModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const [openAddOfferMediator, setOpenAddOfferMediator] = useState(false);
  const [openEditOfferMediator, setOpenEditOfferMediator] = useState(false);

  const [comment, setComment] = useState("");
  const [commission, setCommission] = useState(null);
  const [cluserMessage, setCluserMessage] = useState('');
  const [closeOrderModal, setCloseOrderModal] = useState(false);
  const [openCompleteOfferModal, setOpenCompleteOfferModal] = useState(false);
  const [openChooseCarModal, setOpenChooseCarModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedCause, setSelectedCause] = useState([]);
  const [causes, setCauses] = useState([t("orderDetails.outOfApp"), t('orderDetails.availabilityOffers'), t("orderDetails.notWantCar"), t("orderDetails.otherCause")]);

  const [loadingOffers, setLoadingOffers] = useState(false);
  const handleUserInfo = async () => {
    const response = await getUserInfo();
    setUserInfo(response);
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const getOffersWithRequest = async () => {
    setLoadingOffers(true);
    let data = {
      params: {
        page: 0,
        size: 1000000,
      },
    };
    const response = await getCarOwnerOfferWithRequestForCompleteRequest(
      data.params,
      id
    );
    console.log(response);
    let newOffers = [];
    response.data.page.content.forEach((offer) => {
      if (offer.statusId == 3) {
        newOffers.push(offer);
      }
    });
    setOffers(newOffers, setLoadingOffers(false));
    //  setOffers(response)
  };

  useEffect(() => {
    getOrder();
  }, []);
  console.log(localStorage.getItem('token'));

  const getOrder = async () => {
    setLoading(true);
    const response = await getOrderDetails('', id);
    console.log(response);
    let newFiles = [];
    let newImages = [];
    response?.data?.cars?.[0]?.filesUrl.forEach((file) => {
      const fileExtension = file.split('.').pop();
      if (
        fileExtension == 'pdf' ||
        fileExtension == 'docx' ||
        fileExtension == 'doc'
      ) {
        newFiles.push(file);
        setFiles(newFiles);
      } else {
        newImages.push(file);
        setImages(newImages);
      }
    });
    setComment(response?.data?.medOffer?.comment)
    setCommission(response?.data?.medOffer?.commission.replace("%",""))
    setOrder(response.data, getConfirmationStatus());
  };
  const getConfirmationStatus = async () => {
    const response = await getStatusConfirmationOrder('', id);
    console.log(response);
    setConfirmationOrder(response.data.items, setLoading(false));
  };

  const removeOrderCar = async () => {
    try {
      setLoadingConfirm(true);
      const response = await deleteOrderCar('', id);
      console.log(response);
      navigate('/car-owner');
      toast.success(
        t('orderDetails.deleteMessage'),
        toastConfig,
        setLoadingConfirm(false)
      );
    } catch (error) {
      toast.error(error.response.data, toastConfig, setLoadingConfirm(false));
    }
  };

  const showMessage = (status) => {
    if (status == 4) {
      return t('orderDetails.offerUnderPaymentMessage');
    }
    if (status == 3) {
      return t('orderDetails.offerPendingMessage');
    }
    if (status == 2) {
      return t('orderDetails.offerRejectedMessage');
    }
    if (status == 1) {
      return t('orderDetails.offerAcceptedMessage');
    }
    if (status == 5) {
      return t('orderDetails.offerCompleteMessage');
    }
  };

  //confirmation offers

  const action = (offer) => {
    console.log(offer);
    setConfirmationId(offer.crId);
    if (offer.status == 1 || offer.status == 4) {
      if (userInfo.userId == offer.userId) {
        setOpenPaidRequestModal(true, getConfirmationRequestData(offer.crId));
      } else {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(true, getConfirmationRequestData(offer.crId))
        );
      }
    } else if (offer.status == 3 || offer.status == 2) {
      if (userInfo.userId == offer.carOwnerId) {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(true, getConfirmationRequestData(offer.crId))
        );
      } else {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(false, getConfirmationRequestData(offer.crId))
        );
      }
    } else if (offer.status == 5) {
      setOpenConfirmRequestModal(
        true,
        setHideActionModal(true, getConfirmationRequestData(offer.crId))
      );
    }
  };

  const getConfirmationRequestData = async (crId) => {
    const response = await getConfirmationRequestOwner('', crId);
    console.log(response);
    response.data.medInfo = response.data.otherUserInfo;
    response.data.medInfo.avgRating = response.data.otherUserInfo.medAvgRating;
    response.data.cars = [];
    response.data.confirmationCars.forEach((car) => {
      response.data.cars.push(car);
    });

    setConfirmationRequest(response.data);
  };

  const [valueConfirm, setValueConfirm] = useState('');
  const [valueComment, setValueComment] = useState('');

  const [valueCommentRate, setValueCommentRate] = useState('');
  const [valueStarRate, setValueStarRate] = useState('');
  const [valueRate, setValueRate] = useState('');
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);

  const handleValueConfirm = (value) => {
    setValueConfirm(value);
  };
  const handleValueComment = (value) => {
    setValueComment(value);
  };

  const handleValueCommentRate = (value) => {
    setValueCommentRate(value);
  };
  const handleValueStarRate = (value) => {
    setValueStarRate(value);
  };
  const handleValueRate = (value) => {
    setValueRate(value);
  };
  const showMessageSuccessRateMediator = (value) => {
    setShowMessageSuccess(value);
  };

  const confirmRequest = async () => {
    if (valueConfirm == 2 && !valueComment) {
      toast.error(t('completeRequest.requiredComment'), toastConfig);
    } else {
      let data = {
        confirmationStatus: valueConfirm,
        rejectComment: valueComment,
      };
      try {
        const response = await updateConfirmationRequestOwner(data, confirmationId);
        setOpenConfirmRequestModal(false);
        console.log('Success:', response);
        toast.success(
          t('completeRequest.messageSuccessConfirmed'),
          toastConfig
        );
      } catch (error) {
        if (error.response) {
          if (error.response.status == 400) {
            toast.error(error.response.data.message, toastConfig);
          } else if (error.response.status == 500) {
            toast.error(error.response.data.message, toastConfig);
          } else if (error.response.status == 401) {
            toast.error(error.response.data.message, toastConfig);
          }
        }
      }
    }
  };

  const paidRequest = () => {
    console.log('test', confirmationRequest);
    if (confirmationRequest.totalCommission == 0) {
      console.log('test');
      setOpenRateMediatorModal(true);
    } else {
      console.log('test');
      paidRequestAction();
    }
  };

  const paidRequestAction = async () => {
    let data = {
      params: {
        confirmationId: confirmationId,
      },
    };
    try {
      const response = await paidConfirmationRequestOwner(data, confirmationId);
      console.log('Success:', response);

      toast.success(t('paidRequest.paySuccess'), toastConfig);
      setOpenPaidRequestModal(false);
      // setOpenRateMediatorModal(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };


    const addToSavedOffer = async () => {
    try {
      const response = await addOfferToSaved("", id);
      console.log('Success:', response);

      toast.success(t('paidRequest.orderSavedSuccess'), toastConfig);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  console.log(confirmationRequest);

  const ratedMediator = async () => {
    let data = {
      mediatorId: confirmationRequest.medInfo.id,
      offerId: confirmationRequest.offerRes.id,
      comment:
        valueRate == 1
          ? t('paidRequest.acceptable')
          : valueRate == 2
            ? t('paidRequest.good')
            : valueRate == 3
              ? t('paidRequest.veryGood')
              : t('paidRequest.excellent') + '  : ' + valueCommentRate,
      rating: valueStarRate,
    };
    try {
      const response = await rateMediator(data);
      console.log('Success:', response);

      toast.success(t('paidRequest.ratingSuccess'), toastConfig);
      setShowMessageSuccess(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  };

  const handleChangeCommission = (e) => {
    setCommission(e.target.value);
  };

  const addOffer = async () => {
    let data = {
      sellMediationId: order.sellMediationId,
      commission: commission,
      comment: comment,
    };
    try {
      const response = await addOfferMediator(data);
      console.log('Success:', response);

      toast.success(t('offerCycle.addOfferSuccess'), toastConfig);
      setOpenAddOfferMediator(false, getOrder());
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 409) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };



  const editOffer = async () => {
    console.log("testststststs")
    let data = {
      sellMediationId: order.sellMediationId,
      commission: commission,
      comment: comment,
    };
    try {
      const response = await editOfferMediator(data,order.medOffer.id);
      console.log('Success:', response);

      toast.success(t('offerCycle.editOfferSuccess'), toastConfig);
      setOpenEditOfferMediator(false, getOrder());
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 409) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const confirm = () => {
    setOpenCompleteOfferModal(false);
  };

  const completeRequestConfirm = () => {
    confirmRequestOffer();
  };

  const goToHome = () => {
    navigate('/');
    // setOpenRateMediatorModal(true)
  };

  const confirmRequestOffer = async () => {
    setLoading(true);
    let data = {
      medOfferId: completeOfferContext.offer.id,
    };
    try {
      const response = await confirmationCarOwnerOffer(data);
      setOpenConfirmModal(true);
      console.log('Success:', response);
      toast.success(t('تم تاكيد الطلب بنجاح'), toastConfig, setLoading(false));
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        } else {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        }
      }
    }
  };

  const closeOrder = async () => {
    if (cluserMessage == "" && selectedCause.length == 0) {
      toast.error(t('orderDetails.cluserMessageRequired'), toastConfig);
    } else {
      try {
        let data = {
          body: {
            statusId: 2,
            message: cluserMessage + "    " + selectedCause.join(","),
          },
        };
        const response = await closeOrderCarOwner(data.body, id);
        console.log(response);
        setCloseOrderModal(false, getOrder());
        toast.success(t('orderDetails.messageCloseOrder'), toastConfig);
      } catch (error) {
        toast.error(error.response.data, toastConfig);
      }
    }
  };

  const shareUrl = `https://staging-v2.mshrai.pages.dev/car-owner/${id}/details`;
  const title = 'Check out this amazing mshrai site!';

  const handleShare = async () => {
    const shareData = {
      title: 'Share car owner order',
      text: 'Hey, look at this amazing mshrai site!',
      url: shareUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        toast.error('Link shared successfully!', toastConfig);
      } catch (err) {
        toast.error('Error sharing the link: ' + err.message, toastConfig);
      }
    } else {
      toast.error(
        'Web Share API is not supported on this device.',
        toastConfig
      );
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(t('orderDetails.copyLink'), toastConfig);
      })
      .catch((err) => {
        toast.success('Failed to copy link.', toastConfig);
      });
  };


  const handleChangeCause = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedCause((prevItems) => [...prevItems, name]);
    } else {
      setSelectedCause((prevItems) => prevItems.filter((item) => item !== name));
    }

    console.log(selectedCause)

  };

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      {console.log(order)}
      <div className="w-[90%] mr-auto ml-auto">
        {userRole == 3 && (
          <div
            className="flex flex-row w-[40%] shadow-md items-center p-2 rounded-[28px] bg-[#00CEBC] cursor-pointer"
            onClick={() => navigate(`/profile-info/${order?.carOwnerInfo?.id}`)}
          >
            <div className="rounded-full p-1 bg-[#73D3CB]">
              {!order?.carOwnerInfo?.profileImg ? (
                defaultLettersCircle(order?.carOwnerInfo?.fullName)
              ) : (
                <img
                  className="w-[36px] h-[36px] rounded-full"
                  src={order?.carOwnerInfo?.profileImg}
                />
              )}
            </div>
            <div className="flex flex-col mx-3">
              <div className="flex flex-row items-center text-[#ffffff] text-[15px] font-700">
                <div>{t('listOffers.buyer')}</div>
                <div className="px-2">:</div>
                <div>{order?.carOwnerInfo?.fullName}</div>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-row items-center justify-between my-5">
          <div className="bg-[#3E0292] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
            <div>{t('orderDetails.orderDescription')}</div>
            <div className="px-2">:</div>
            <div>#{order.sellMediationId}</div>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              {/* <Button
                dark={true}
                title={t('orderDetails.buttonShare')}
                onClick={() => setOpenShare(true)}
                className="mx-2"
              /> */}
              <Tooltip content={t('orderDetails.buttonShare')} style="dark">
              <div onClick={() => setOpenShare(true)} className="mx-3 cursor-pointer">
                <MdShare className="text-[#3E0292] text-[25px]" />
              </div>
              </Tooltip>
              {/* {userRole == 3 ? (
                       <ButtonIcon dark={true} title={t("offerCycle.addOffer")} icon={""} onClick={()=>setOpenAddOfferMediator(true)}/>
                 ):(
                <> */}
            {userInfo?.userId != order?.carOwnerInfo?.id && (
          <>
              {order.statusId == 1 && !order.hasActiveCR && order.medOffer ? (
                <>
                {location?.state?.from == "mediationRequests"? (
    <ButtonIcon
    dark={true}
    title={t('offerCycle.editOffer')}
    icon={pen}
    onClick={() => setOpenEditOfferMediator(true)}
  />
                ):(
                  <ButtonIcon
                    dark={true}
                    title={t('orderDetails.edit')}
                    icon={pen}
                    onClick={() => navigate(`/car-owner/${id}/edit`)}
                  />
                  )}
                </>
              ) : null}
              </>
            )}
              {order.countOfOffers == 0 ? (
                <ButtonIcon
                  dark={false}
                  title={t('orderDetails.delete')}
                  className="bg-[#FD2828] border-[#FD2828] mx-2"
                  icon={trash}
                  onClick={() => setOpenDeleteModal(true)}
                />
              ) : null}

              {/* </>
                )}  */}

              {/* order.statusId == 2 ? (
                    <div className="flex flex-row items-center justify-around p-2 bg-[#FF00004D] rounded-[7px] mx-5">
                    <div><img src={timesOffer}/></div>
                    <div className="text-[#FD2828] text-[11px] font-[600px] mx-3">{t("offerDetails.reject")}</div>
                  </div>
                ):order.statusId == 4 ? (
                    <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
                    <div><img src={checkOffer}/></div>
                    <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">{t("statusActions.complete")}</div>
                   </div>
                ):
                null} */}
            </div>
            <div className="flex flex-row items-center rounded-[7px] bg-[#E6EDFF] p-2 mx-5">
              <div className="text-[#3E0292] text-[13px] font-500">
                {t('home.orders.status')}
              </div>
              <div className="text-[#3E0292] text-[13px] font-500 mx-2">:</div>
              <div className="text-[#3E0292] text-[13px] font-500">
                {order.statusId == 1
                  ? t('home.orders.open')
                  : order.statusId == 2
                    ? t('home.orders.close')
                    : order.statusId == 3
                      ? t('home.orders.complete')
                      : t('home.orders.open')}
              </div>
              <div
                className={`${
                  order.statusId == 1
                    ? 'bg-[#04C500CC]'
                    : order.statusId == 2
                      ? 'bg-[#ff0000CC]'
                      : order.statusId == 3
                        ? 'bg-[#3E0292CC]'
                        : 'bg-[#04C500CC]'
                }  w-[10px] h-[10px] rounded-full mx-2`}
              ></div>
            </div>
            <div className="flex flex-row items-center justify-around p-2 bg-[#E6EDFF] rounded-[7px]">
              <div>
                <img src={clock} />
              </div>
              {order?.createdDate ? (
                <div className="text-[#3E0292] mx-3">
                  <span className="font-bold text-[13px]">
                    {convertDate(order?.createdDate)}
                  </span>{' '}
                  <span className="text-[11px] font-[600px]">
                    {order?.createdAt?.split('T')[1]}
                  </span>
                </div>
              ) : null}
            </div>
            {userInfo?.userId == order?.carOwnerInfo?.id && (
              <div className="relative">
                {order.statusId == 1 ? (
                  <img
                    src={menuDots}
                    onClick={toggleMenu}
                    className="cursor-pointer"
                  />
                ) : null}
                {isOpen && (
                  <div
                    className={`absolute ${
                      languageAr ? 'left-0' : 'right-0'
                    } mt-2 w-[100px] z-10 bg-white border border-gray-200 rounded-[12px] shadow-lg`}
                    onMouseLeave={closeMenu}
                  >
                    <ul className="py-2">
                      <li>
                        <div
                          className="flex flex-row items-center justify-around p-3 cursor-pointer"
                          onClick={() =>
                            setOpenCompleteOfferModal(
                              true,
                              getOffersWithRequest()
                            )
                          }
                        >
                          <div>
                            <img src={loadingGreen} />
                          </div>
                          <div className="text-[#00CEDF] text-[15px] font-400">
                            {t('completeRequest.completed')}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div
                          className="flex flex-row items-center justify-around border-t border-t-[#E9E9E9] p-3 cursor-pointer"
                          onClick={() => setCloseOrderModal(true)}
                        >
                          <div>
                            <img src={loadingRed} />
                          </div>
                          <div className="text-[#CE4223] text-[15px] font-400">
                            {t('completeRequest.close')}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <CardSkeleton number={1} height={'500px'} />
        ) : (
          <div className="w-[100%] bg-[#D7E2FF] rounded-[20px] grid grid-cols-[40%_60%] max800:grid-cols-1 p-5">
            <div className="flex flex-col p-2">
              {order?.cars?.[0]?.brand == null ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.car')}:
                  </div>
                  <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                    <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2">
                      <div>
                        <img
                          src={order?.cars?.[0]?.brand?.image}
                          className="w-[20px] h-[20px]"
                        />
                      </div>
                      <div className="text-[#0000000] text-[12px] font-400 px-2">
                        {languageAr
                          ? order?.cars?.[0]?.brand?.nameAr
                          : order?.cars?.[0]?.brand?.nameEn}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {order?.cars?.[0]?.model == null ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.model')}:
                  </div>
                  <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                    <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2">
                      <div>
                        <img
                          src={order?.cars?.[0]?.brand?.image}
                          className="w-[20px] h-[20px]"
                        />
                      </div>

                      <div className="text-[#0000000] text-[12px] font-400 px-2">
                        {languageAr
                          ? order?.cars?.[0]?.model?.nameAr
                          : order?.cars?.[0]?.model?.nameEn}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <div className="flex flex-row items-center my-5">
                        <div className="text-[#3E0292] text-[14px] font-500"> {t("orderDetails.carType")}:</div>
                        <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mx-2">
                        <div className="bg-[#ffffff] rounded-[12px] p-3 flex flex-row items-center">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                       </div>
                       </div>
                        
                    </div> */}
              {console.log(order)}

              {order?.cars?.[0]?.city == null ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.city')}:
                  </div>
                  <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                    <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2">
                      <div>
                        <img src={location} className="w-[20px] h-[20px]" />
                      </div>
                      <div className="text-[#0000000] text-[12px] font-400 px-2">
                        {languageAr
                          ? order?.cars?.[0]?.city?.nameAr
                          : order?.cars?.[0]?.city?.nameEn}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {order?.cars?.[0]?.odo == null ? null : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.km')}:
                  </div>
                  <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mx-2">
                    <div>
                      <img src={kilometers} className="w-[20px] h-[20px]" />
                    </div>
                    <div className="flex flex-row items-center px-2">
                      <div className="text-[#BBBBBB] text-[10px] font-400">
                        {t('listOrders.from')}
                      </div>
                      <div className="text-[#000000] text-[13px] font-400 px-2">
                        {order?.cars?.[0]?.odo}
                      </div>
                      <div className="text-[#BBBBBB] text-[10px] font-400">
                        {t('listOrders.km')}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {order?.cars?.[0]?.color == null ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.color')}:
                  </div>
                  <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                    <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2">
                      <div className="w-[20px] h-[20px]">
                        <CarColor
                          color={
                            order?.cars?.[0]?.color?.hexCode == '#FFFFFF'
                              ? '#808080'
                              : order?.cars[0]?.color?.hexCode
                          }
                        />
                      </div>
                      <div
                        style={{
                          color:
                            order?.cars?.[0]?.color?.hexCode == '#FFFFFF'
                              ? '#808080'
                              : order?.cars[0]?.color?.hexCode,
                        }}
                        className="text-[12px] font-400 px-2"
                      >
                        {languageAr
                          ? order?.cars?.[0]?.color?.nameAr
                          : order?.cars?.[0]?.color?.nameEn}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row items-center my-3">
                <div className="text-[#3E0292] text-[14px] font-500">
                  {t('orderDetails.price')}:
                </div>
                <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 mx-2">
                  <div>
                    <img src={moneyWhite} className="w-[20px] h-[20px]" />
                  </div>
                  <div className="flex flex-row items-center px-2">
                    <div className="text-[12px] font-500 text-[#ffffff]">
                      {t('listOrders.price')}
                    </div>
                    <div className="text-[12px] font-500 text-[#ffffff] px-2">
                      :
                    </div>
                    <div className="text-[12px] font-500 text-[#ffffff]">
                      {order?.cars?.[0]?.price}
                    </div>
                    <div className="text-[12px] font-500 text-[#ffffff]">
                      {t('listOrders.Rial')}
                    </div>
                  </div>
                </div>
              </div>

              {/* {location?.state?.from == "mediationRequests" && (
              <>
                 {order?.medOffer  == null ? null : (
       <div className="flex flex-row items-center my-3">
       <div className="text-[#3E0292] text-[14px] font-500">
         {t('offerDetails.commission')}:
       </div>
       <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 mx-2">
         <div>
           <img src={moneyWhite} className="w-[20px] h-[20px]" />
         </div>
         <div className="flex flex-row items-center px-2">
           <div className="text-[12px] font-500 text-[#ffffff]">
             {t('listOffers.commission')}
           </div>
           <div className="text-[12px] font-500 text-[#ffffff] px-2">
             :
           </div>
           <div className="text-[12px] font-500 text-[#ffffff]">
             {order?.medOffer?.commission}
           </div>
           {!/%/.test(order?.medOffer?.commission) && (
           <div className="text-[12px] font-500 text-[#ffffff]">
           {t('listOrders.Rial')}
           </div>
           )}
         </div>
       </div>
     </div>
                 )}
     </>
              )}   */}
            </div>
            <div
              className={`flex flex-col max800:border-0 border ${
                i18n.language == Lang.ar
                  ? 'border-r-[#ffffff]'
                  : 'border-l-[#ffffff]'
              } p-3`}
            >
              <div className="flex flex-row items-center my-3">
                <div className="text-[#3E0292] text-[14px] font-500">
                  {' '}
                  {t('orderDetails.year')}:
                </div>
                <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 mx-2">
                  <div>
                    <img src={Calendar} className="w-[20px] h-[20px]" />
                  </div>
                  <div className="flex flex-row items-center px-2">
                    <div className="text-[#000000] text-[13px] font-[600px]">
                      {t('statusActions.from')}
                    </div>
                    <div className="text-[#000000] text-[13px] font-[600px] px-2">
                      {order?.cars?.[0]?.modelYear}
                    </div>
                  </div>
                </div>
              </div>
              {!confirmationOrder || confirmationOrder.length == 0 ? null : (
                <div>
                  {confirmationOrder?.map((item, index) => (
                    <div
                      key={index}
                      className="grid lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 m-3 cursor-pointer"
                      onClick={() => {
                        // item.status == 2
                        //   ? console.log('rejected')
                        //   : 
                          action(item);
                      }}
                    >
                      <div className="text-[#3E0292] text-[14px] font-500">
                        {t('offerDetails.number')}:{item?.medOfferId}
                      </div>
                      <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-1">
                        <div>
                          <img src={car} className="w-[20px] h-[20px]" />
                        </div>
                        <div className="text-[#0000000] text-[12px] font-400 px-2">
                          {showMessage(item?.status)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {order?.cars?.[0]?.description == '' ? null : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {' '}
                    {t('orderDetails.description')}:
                  </div>
                  <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 w-[80%] mx-2">
                    <div className="text-[#303030] text-[14px] font-400">
                      {order?.cars?.[0]?.description}
                    </div>
                  </div>
                </div>
              )}
              {order?.medOffer && (
              <>
{location?.state?.from == "mediationRequests" && (
              <div className='border border-[#00CEBC] rounded-[10px] p-3'>
                <div className='flex flex-row items-center justify-between'>
                <div className="text-[15px] font-700">{t("offerCycle.offerForYou")}</div>
                <ButtonIcon
                dark={false}
                className="bg-[#00CEBC] shadow-md"
                title={t('profileInfo.conversation')}
                icon={chatWhite}
              />
                </div>
                {order?.medOffer?.comment && (
                <>
                 {order?.medOffer  == null ? null : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {' '}
                    {t('offerCycle.commentTitle')}:
                  </div>
                  <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 w-[80%] mx-2">
                    <div className="text-[#303030] text-[14px] font-400">
                      {order?.medOffer?.comment}
                    </div>
                  </div>
                </div>
              )}
              </>
              )}

              {order?.medOffer  == null ? null : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {' '}
                    {t('offerCycle.status')}:
                  </div>
                  <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 w-[80%] mx-2">
                    <div className="text-[#303030] text-[14px] font-400">
                      {order?.medOffer?.statusId == 1 ? t("home.orders.pending") : order?.medOffer?.statusId == 2 ? t("home.orders.close") : order?.medOffer?.statusId == 3 ? t("home.orders.accept") : order?.medOffer?.statusId == 4 ? t("home.orders.complete") : t("home.orders.pending")}
                    </div>
                  </div>
                </div>
              )}


{order?.medOffer  == null ? null : (
       <div className="flex flex-row items-center my-3">
       <div className="text-[#3E0292] text-[14px] font-500">
         {t('offerDetails.commission')}:
       </div>
       <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 mx-2">
         <div>
           <img src={moneyWhite} className="w-[20px] h-[20px]" />
         </div>
         <div className="flex flex-row items-center px-2">
           <div className="text-[12px] font-500 text-[#ffffff]">
             {t('listOffers.commission')}
           </div>
           <div className="text-[12px] font-500 text-[#ffffff] px-2">
             :
           </div>
           <div className="text-[12px] font-500 text-[#ffffff]">
             {order?.medOffer?.commission}
           </div>
           {!/%/.test(order?.medOffer?.commission) && (
           <div className="text-[12px] font-500 text-[#ffffff]">
           {t('listOrders.Rial')}
           </div>
           )}
         </div>
       </div>
     </div>
                 )}
              </div>
)}
</>
              )}


              {images.length == 0 ? null : (
                <div
                  className="flex flex-col my-3 cursor-pointer"
                  onClick={() => setOpenModalCars(true)}
                >
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.images')}:
                  </div>
                  <div className="bg-[#ffffff] rounded-[12px] p-2 mx-2">
                    <div className="grid xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 max600:grid-cols-1 sm:grid-cols-2 gap-2">
                      {images.map((image, index) => (
                        <div
                          key={index}
                          onClick={() => (window.location.href = image)}
                        >
                          <img
                            src={image}
                            className="h-[100px] w-[100%] rounded-[5px] border-[0.5px] border-[#999999]"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              {files?.length == 0 ? null : (
                <div className="flex flex-col my-3 cursor-pointer">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.files')}:
                  </div>
                  <div className="bg-[#ffffff] rounded-[12px] p-2 mx-2">
                    <div className="grid xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 max600:grid-cols-1 sm:grid-cols-2 gap-2">
                      {files.map((file, index) => (
                        <div
                          key={index}
                          onClick={() => (window.location.href = file)}
                          className="text-[#3E0292] underline font-400"
                        >
                          {t('myOrders.file')} {index + 1}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {userInfo?.userId != order?.carOwnerInfo?.id && (
          <>
            {userRole == 3 && order?.medOffer == null ? (
              <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%] mr-auto ml-auto gap-x-2 mt-10">
                <ButtonIcon
                  dark={true}
                  title={t('offerCycle.addOffer')}
                  icon={''}
                  onClick={() => setOpenAddOfferMediator(true)}
                />
              </div>
            ) : userRole == 3 && order?.medOffer != null ? (
          <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%] mr-auto ml-auto gap-x-2 mt-10">
           {order?.medOffer?.statusId == 3 && !order?.isAddedToSavedOffers ? (
           <Button
           title={t('statusActions.addToSavedOffer')}
           className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
           dark={false}
           onClick={()=>addToSavedOffer()}
           // onClick={()=>navigate(`/orders/${order?.sellMediationId}/offers/${order?.finalCR?.completedCRId}`)}
         />
):(
  <ButtonIcon
  dark={true}
  title={t('offerCycle.addedOfferAlready')}
  icon={''}
  onClick={() => console.log('')}
/>
)}
              </div>
            ) : null}
          </>
        )}
 

        {/* {userRole == 2 && ( */}
          <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[30%] mr-auto ml-auto gap-x-2 mt-10">
            {order.statusId == 3 ? (
              <Button
                title={t('statusActions.finalOffer')}
                className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
                dark={false}
                // onClick={()=>navigate(`/orders/${order?.sellMediationId}/offers/${order?.finalCR?.completedCRId}`)}
              />
            ) : (
              <>
              {location?.state?.from == "mediationRequests" && order?.countOfOffers > 0 && (
              <ButtonBadge
                disabled={order?.countOfOffers > 0 ? false : true}
                value={order?.countOfOffers}
                className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
                dark={false}
                title={t('orderDetails.offers')}
                onClick={() => navigate(`/car-owner/${id}/offers`)}
              />
              )}
              </>
            )}
          </div>
        {/* // )} */}
      </div>
      <MConfirmation
        loading={loadingConfirm}
        openModal={openDeleteModal}
        showFooter={true}
        setOpenModal={setOpenDeleteModal}
        title={t('orderDetails.messageDelete')}
        content={
          <div className="flex justify-center items-center">
            <img src={confirmDelete} />
          </div>
        }
        confirm={removeOrderCar}
      />
      <MConfirmation
        openModal={openModalCars}
        setOpenModal={setOpenModalCars}
        title={t('orderDetails.images')}
        content={
          <Carousel>
            {order?.cars?.[0]?.filesUrl.map((item, index) => (
              <div>
                <img
                  src={item}
                  className="h-[300px]"
                  alt={`image ${index + 1}`}
                />
                <p className="legend">
                  {t('myOrders.image')} {index + 1}
                </p>
              </div>
            ))}
          </Carousel>
        }
      />

      <MConfirmation
        loading={loading}
        openModal={openConfirmRequestModal}
        showFooter={hideActionModal ? false : true}
        setOpenModal={setOpenConfirmRequestModal}
        title={t('completeRequest.completeRequest')}
        content={
          <ConfirmRequest
            from="carowner"
            hideActionModal={hideActionModal}
            onValueChangeComment={handleValueComment}
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={confirmRequest}
      />
      <MConfirmation
        loading={loading}
        openModal={openPaidRequestModal}
        setOpenModal={setOpenPaidRequestModal}
        showFooter={true}
        titleButton={
          confirmationRequest?.cars?.[0].commission > 0
            ? t('paidRequest.payment')
            : t('paidRequest.rating')
        }
        singleButton={true}
        content={
          <PaidRequest
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
            from={"carowner"}
          />
        }
        confirm={paidRequest}
      />
      <MConfirmation
        loading={loading}
        openModal={openRateMediatorModal}
        showFooter={true}
        setOpenModal={setOpenRateMediatorModal}
        title={t('paidRequest.ratingMediator')}
        content={
          <MediatorRate
            onValueChangeCommentRate={handleValueCommentRate}
            onValueChangeStarRate={handleValueStarRate}
            onValueChangeRate={handleValueRate}
            showMessageSuccess={showMessageSuccess}
            setShowMessageSuccess={showMessageSuccessRateMediator}
            offer={confirmationRequest}
            from={"carowner"}
          />
        }
        titleButton={t('paidRequest.rating')}
        singleButton={true}
        confirm={ratedMediator}
      />

      {/* add offer for mediator */}
      <MConfirmation
        title={t('offerCycle.addOffer')}
        loading={loading}
        openModal={openAddOfferMediator}
        setOpenModal={setOpenAddOfferMediator}
        showFooter={true}
        titleButton={t('offerCycle.confirmOffer')}
        singleButton={true}
        content={
          <div>
            <Input
              type={'number'}
              // max={'100'}
              min={'1'}
              placeholder={t('offerCycle.commission')}
              label={t('offerCycle.commission')}
              onChange={handleChangeCommission}
              value={commission}
            />
            <div className="mt-5">
              <Textarea
                rows={5}
                placeholder={t('offerCycle.writeWant')}
                label={t('offerCycle.comment')}
                value={comment}
                onChange={handleChangeComment}
              />
            </div>
          </div>
        }
        confirm={addOffer}
      />


           {/* edit offer for mediator */}
        <MConfirmation
        title={t('offerCycle.editOffer')}
        loading={loading}
        openModal={openEditOfferMediator}
        setOpenModal={setOpenEditOfferMediator}
        showFooter={true}
        titleButton={t('offerCycle.confirmOffer')}
        singleButton={true}
        content={
          <div>
            <Input
              type={'number'}
              // max={'100'}
              min={'1'}
              placeholder={t('offerCycle.commission')}
              label={t('offerCycle.commission')}
              onChange={handleChangeCommission}
              value={commission}
            />
            <div className="mt-5">
              <Textarea
                rows={5}
                placeholder={t('offerCycle.writeWant')}
                label={t('offerCycle.comment')}
                value={comment}
                onChange={handleChangeComment}
              />
            </div>
          </div>
        }
        confirm={editOffer}
      />

      {/* complete Order */}
      <MConfirmation
        loading={loading}
        openModal={openCompleteOfferModal}
        showFooter={true}
        setOpenModal={setOpenCompleteOfferModal}
        title={t('completeRequest.chooseMediator')}
        content={
          <ChooseMediator offers={offers} from="carowner" loadingOffers={loadingOffers} />
        }
        confirm={completeRequestConfirm}
        disabled={offers.length > 0 ? false : true}
      />
      {/* <MConfirmation
        loading={loading}
        openModal={openChooseCarModal}
        showFooter={true}
        setOpenModal={setOpenChooseCarModal}
        title={t('completeRequest.completeRequest')}
        content={<CompleteOrder offer={completeOfferContext.offer} />}
        confirm={completeRequestConfirm}
      /> */}
      <MConfirmation
        loading={loading}
        openModal={openConfirmModal}
        showFooter={true}
        setOpenModal={setOpenConfirmModal}
        title={t('completeRequest.successSentToMediator')}
        content={
          <div className="flex justify-center items-center">
            <img src={confirmIcon} />
          </div>
        }
        titleButton={t('completeRequest.backToHome')}
        singleButton={true}
        confirm={goToHome}
      />

      <MConfirmation
        loading={loading}
        openModal={closeOrderModal}
        showFooter={true}
        setOpenModal={setCloseOrderModal}
        title={t('orderDetails.questionCloseOrder')}
        content={
          <div>
            <div>{t("orderDetails.selectCauseClose")}</div>
            <div className="flex flex-col my-5">
            {causes.map((cause) => (
            <div key={cause} className="flex flex-row mt-2">
              <CheckBox
                handleChange={handleChangeCause}
                checked={selectedCause.includes(cause)}
                type="checkbox"
                name={cause}
              />
                <label className="mx-3 text-[#3E0292] text-[13px] font-bold">
                {cause}
              </label>
            </div>
        ))}
          </div>
            <Textarea
              rows={4}
              onChange={(e) => setCluserMessage(e.target.value)}
              value={cluserMessage}
              placeholder={t('orderDetails.writeCause')}
              label={t('orderDetails.cause')}
            />
          </div>
        }
        singleButton={false}
        confirm={closeOrder}
      />

      {/* share model */}
      <MConfirmation
        loading={loading}
        openModal={openShare}
        showFooter={false}
        setOpenModal={setOpenShare}
        title={t('orderDetails.titleShareOrder')}
        content={
          <div className="grid grid-cols-3 gap-3">
            <div className="flex flex-row items-center justify-between">
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <Button
              dark={true}
              title={t('orderDetails.other')}
              onClick={handleShare}
            />
            <Button
              dark={true}
              title={t('orderDetails.copyLinkButton')}
              onClick={handleCopyLink}
            />
          </div>
        }
      />
    </div>
  );
};

export default OrderDetails;
