import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CardOffer,
  InputSearch,
  NotFoundOrders,
  CardSkeleton,
  MCardOffer,
  MCardSavedOffer,
} from './../index';
import {
  getAllOffersForMediator,
  getAllSavedOffersForMediator,
  getOffers,
  getOffersForOrder,
} from './../../services/apis/offer';
import { Spinner, Tabs } from 'flowbite-react';
import { themeTabs } from './../../helpers/contents';
import { userRole } from './../../helpers/helper';

const Offers = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  console.log(location?.state?.status);
  const [offers, setOffers] = useState([]);
  const [allOffers, setAllOffers] = useState([]);
  const [statuses, setStatuses] = useState([
    { value: 0, label: t('home.orders.all'), color: 'bg-[#3E029269]' },
    { value: 1, label: t('home.orders.accept'), color: 'bg-[#BBF7F1]' },
    { value: 2, label: t('home.orders.reject'), color: 'bg-[#FEBFBF]' },
    { value: 3, label: t('home.orders.pending'), color: 'bg-[#D7E2FF]' },
    { value: 4, label: t('home.orders.complete'), color: 'bg-[#9FBDFF]' },
  ]);
  const [savedOffers, setSavedOffers] = useState([]);
  console.log('🚀 ~ Offers ~ offers:', offers);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState('');
  const [searchText, setSearchText] = useState('');
  const observerRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const { id } = useParams();

  const getAllOffersForRequest = async (reset = false) => {
    setLoading(true);
    // try{
    let data = {
      params: {
        page: currentPage,
        size: 1000000,
      },
    };
    const response = await getAllOffersForMediator(data);
    const newOffers = response.data.offers.content;
    setOffers((prevPosts) =>
      reset ? newOffers : [...prevPosts, ...newOffers]
    );
    setAllOffers((prevPosts) =>
      reset ? newOffers : [...prevPosts, ...newOffers]
    );
    setHasMore(newOffers.length > 0);
    setLoading(false);
    // } catch (error) {
    //     console.error('Error fetching posts:', error);
    //   }finally {
    //     setLoading(false);
    //   }
  };

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    getAllOffersForRequest();
  }, [currentPage]);

  const getAllOffersForRequestFilter = (status) => {
    if (status) {
      const filteredOffers = allOffers.filter(
        (offer) => offer.offerStatus === status
      );
      setOffers(filteredOffers);
      setStatus(status);
    } else {
      // If no status filter, show all offers
      setOffers(allOffers);
      setStatus(status);
    }
  };

  if (loading) {
    return (
      <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
        <CardSkeleton number={3} height={'300px'} />
      </div>
    );
  }

  if (!offers || offers.length === 0) {
    return <NotFoundOrders from="offers" onClick={() => navigate(`/offers`)} />;
  }

  return (
    // <div className="bg-[#C7F1EF] p-5">
    <div className="w-[100%] mr-auto ml-auto">
      <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-4 sm:grid-cols-2 max600:grid-cols-1  gap-2">
        {statuses.map((statusObj, index) => (
          <div
            key={index}
            className={`${status == statusObj.value ? 'bg-white text-[#444444] border border-[#444444] shadow-md' : `${statusObj.color} text-[#444444] shadow-md`} rounded-[12px] p-2 text-center mt-3 font-500 text-[15px] cursor-pointer `}
            onClick={() =>
              setStatus(
                statusObj.value,
                getAllOffersForRequestFilter(statusObj.value)
              )
            }
          >
            {statusObj.label}
          </div>
        ))}
      </div>

      <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-16 my-5 p-5 overflow-y-scroll h-[500px]">
        {offers.map((offer, index) => (
          <div ref={index === offers.length - 1 ? lastItemRef : null}>
            <MCardOffer
              key={offer.offerId}
              offer={offer}
              onClick={() =>
                navigate(`/orders/${offer?.requestId}/offers/${offer?.offerId}`)
              }
            />
          </div>
        ))}
      </div>
      {loading && (
        <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
          <Spinner
            color="success"
            aria-label="Extra large spinner example"
            size="xl"
          />
        </div>
      )}
      {!hasMore && (
        <p className="mt-20 text-center text-[#3E0292]">
          {t('myOrders.loadingAllOrders')}
        </p>
      )}
    </div>

    // {/* </div> */}
  );
};

export default Offers;
