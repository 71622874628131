import { useTranslation } from 'react-i18next';
import {
  facebook,
  instagram,
  logo,
  twitter,
  copyRight,
  newLogoMshrai,
} from '../assets/icons';

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="px-5 py-3 bg-[#E1E9FF]">
      <div className="grid lg:grid-cols-3 md:grid-cols-3 xs:grid-cols-1 flex items-center justify-center w-[90%] mr-auto ml-auto">
        <div className="flex max600:justify-center">
          <img src={newLogoMshrai} width={51} />
        </div>
        <div className="flex items-center justify-center flex-row max600:mt-5 max600:mb-5">
          <div
            className="font-400"
            style={{
              fontSize: 16,
              color: '#444444',
              fontFamily: 'Noto Sans Arabic',
            }}
          >
            {t('home.footer.msrhai')} 2024
          </div>
          <img src={copyRight} className="mx-3" />
          <div className="text-[16px] text-[#444444] font-400">
            {t('home.footer.title')}
          </div>
        </div>
        <div className="flex flex-row items-center justify-end lg:w-[100%] md:w-[100%] 2xl:w-[100%] xl:w-[100%] max600:justify-center sm:[100%] sm:order-3 lg:order-1 xl:order-1 md:order-1 2xl:order-1">
          <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center">
            <img src={twitter} />
          </div>
          <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center mx-3">
            <img src={instagram} />
          </div>
          <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center">
            <img src={facebook} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
