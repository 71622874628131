import { arrowLeftOrder, userMail } from '../../../assets/icons';
import { useForm ,Controller} from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { FilterOrderContext } from '../../../context/FilterOrderContext';
import ReactSlider from 'react-slider';
import './price.css';
import Button from '../../buttons/Button';
import { useTranslation } from 'react-i18next';
import Counter from '../../inputs/Counter';

const Price = ({ back }) => {
  const { t } = useTranslation();
  const filterOrderContext = useContext(FilterOrderContext);
  const { control, register, handleSubmit, setValue } = useForm({
    defaultValues: { price: [100000, 500000] , priceFrom:1000 , priceTo:5000},
  });

  const [price, setPrice] = useState([100000, 500000]);
  const handleSliderChange = (newValues) => {
    setPrice(newValues, setValue('price', newValues));
  };

  const onSubmit = (data) => {
    console.log(data.priceFrom)
    console.log(data.priceTo)
    filterOrderContext.setPriceFrom(data.priceFrom);
    filterOrderContext.setPriceTo(data.priceTo, back());
  };
  return (
    <div>
      <img
        src={arrowLeftOrder}
        className="my-2 cursor-pointer"
        onClick={back}
      />
      <div className="text-[#3E0292] text-[15px] font-500">
        {t('filterModal.selectPrice')}
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
                <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 gap-x-4">
                  <Controller
                    name="priceFrom"
                    control={control}
                    defaultValue={0}
                    // rules={{ required: !isPriceNotDetermined }}
                    render={({ field }) => (
                      <Counter
                        {...field}
                        initialValue={filterOrderContext.priceFrom}
                        icon={userMail}
                        label={t('myOrders.price')}
                        placeholder={t('myOrders.from')}
                        className="mt-2"
                        step={1000}
                      />
                    )}
                  />
                  <Controller
                    name="priceTo"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Counter
                        {...field}
                        initialValue={filterOrderContext.priceTo}
                        icon={''}
                        label={t('myOrders.to')}
                        placeholder={t('myOrders.to')}
                        className="mt-2"
                        step={5000}
                      />
                    )}
                  />
                </div>

        <div className="grid grid-cols-2 gap-x-2 mt-10">
          <Button
            type="submit"
            dark={false}
            className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]"
            title={t('filterModal.confirm')}
          />
          <Button
            type="submit"
            dark={false}
            className="bg-[#FF4342] border-[#FF4342] rounded-[10px]"
            title={t('filterModal.cancel')}
          />
        </div>
      </form>
    </div>
  );
};
export default Price;
