import React, { useState } from 'react';
import Input from '../components/inputs/Input';
import { useTranslation } from 'react-i18next';
import { phone } from '../assets/icons';
import i18n from '../languages/i18n';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import InputRHF from '../components/inputs/InputRHF';
import { loginSchema } from '../schema/auth';
import { login } from '../services/auth';
import { getUserData } from '../services/apis/user';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import { useContext } from 'react';
import MediatorPopup from './MediatorPopup';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toastConfig, validateSaudiPhone } from '../helpers/helper';
import MConfirmation from './modals/MConfirmation';
import { activateAccount } from '../services/apis/user';

const LoginForm = () => {
  document.body.dir = i18n.dir();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setUserData } = useContext(UserContext);
  const [isModal, setIsModal] = useState(false);
  const [openRetriveAccount, setOpenRetriveAccount] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const CloseModal = () => {
    setIsModal(false);
  };

  const methods = useForm({
    resolver: zodResolver(loginSchema),
    mode: 'onChange',
    defaultValues: {
      phoneNumber: '',
      password: ''
    }
  });

  const {
    register: formRegister,
    handleSubmit,
    formState: { errors },
    getValues,
    control
  } = methods;

  const handleLogin = async () => {
    const { phoneNumber, password } = getValues();
    // Validate the input with Zod schema
    const result = loginSchema.safeParse({ phoneNumber, password });
    if (!result.success) {
      toast.error(result.error.errors[0].message, { position: 'top-right' });
      return;
    }
    const { phoneNumber: transformedPhoneNumber, password: validPassword } =
      result.data;
    try {
      const data = {
        phoneNumber: transformedPhoneNumber,
        password: validPassword,
      };
      const loginResponse = await login(data);
      if (loginResponse?.id_token) {
        localStorage.setItem('token', loginResponse.id_token);
        localStorage.setItem('userRole', loginResponse?.user_role?.roleId);
        
        // Get user data and update context
        try {
          const userResponse = await getUserData();
          setUserData(userResponse);
          localStorage.setItem('userData', JSON.stringify(userResponse));
          
          navigate('/', { state: { phoneNumber: transformedPhoneNumber } });
          window.location.href = '/';
        } catch (error) {
          console.error('Error fetching user data:', error);
          toast.error(t('login.errorFetchingUserData'), toastConfig);
        }
      } else {
        toast.error('Login failed, token not found');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data == 'User account is deleted') {
          setOpenRetriveAccount(true);
        }
        toast.error(
          error.response.data || 'Login failed, please try again.',
          toastConfig
        );
      } else {
        toast.error(
          error.response.data || 'Login failed, please try again.',
          toastConfig
        );
      }
    }
  };

  const retriveAccount = async () => {
    setLoadingConfirm(true);
    if (phoneNumber == '') {
      toast.error(
        t('login.schema.phoneRequired'),
        toastConfig,
        setLoadingConfirm(false, setOpenRetriveAccount(false))
      );
    } else {
      try {
        let data = {
          params: {
            phoneNum: phoneNumber,
          },
        };
        const response = await activateAccount(data, phoneNumber);
        console.log(response);
        toast.success(
          t('login.schema.retriveAccountSuccess'),
          toastConfig,
          setLoadingConfirm(false, setOpenRetriveAccount(false))
        );
        window.location.href = '/';
      } catch (error) {
        toast.error(
          error.response.data,
          toastConfig,
          setLoadingConfirm(false, setOpenRetriveAccount(false))
        );
      }
    }
  };

  return (
    <div className=" rounded-lg max600:order-2 w-full max-w-md space-y">
      <h2 className="text-2xl font-bold text-center text-customPurple sm:mt-10">
        {t('login.label.welcome')}
      </h2>
      <h2 className="text-lg  font-medium	 text-[#666666] text-center md:mt-5 sm:mt-5">
        {t('login.label.login')}
      </h2>
      <form onSubmit={handleSubmit(handleLogin)} className="mt-9">
        <div className="mt-9">
          <InputRHF
            label={t('contactUs.label.phone')}
            type={'text'}
            icon={phone}
            placeholder={t('contactUs.placeholder.phone')}
            {...formRegister('phoneNumber')}
            error={errors.phoneNumber?.message}
          />
        </div>
        <div className="mt-5">
          <InputRHF
            label={t('login.label.password')}
            type={'password'}
            icon={phone}
            placeholder={t('login.placeholder.password')}
            {...formRegister('password')}
            error={errors.password?.message}
          />
        </div>
        <div className="flex items-center justify-between mt-3">
          <label className="flex items-center gap-x-2">
            <span className="ml-2 text-[#C0BEBF]">
              {t('login.label.rememberMe')}
            </span>
            <input type="checkbox" className="form-checkbox" />
          </label>
          <a
            href="/forgetPass"
            className="text-sm text-[#C0BEBF]  hover:underline"
          >
            {t('login.label.forgetPassword')}
          </a>
        </div>
        <div className="flex justify-center mt-5">
          <button
            type="submit"
            className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[15px] text-white px-10 py-4 w-[70%] shadow-lg text-center"
          >
            {t('login.label.login')}{' '}
          </button>
        </div>
      </form>
      <p className="text-center text-gray-500 mt-5">
        {t('login.label.NoAccount')}
        <a
          href="/signUp"
          className="text-[#3E0292] font-normal hover:underline ml-1"
        >
          {t('login.label.createNewAccount')}
        </a>
      </p>
      <MediatorPopup isOpen={isModal} isClose={CloseModal} />
      <MConfirmation
        loading={loadingConfirm}
        openModal={openRetriveAccount}
        showFooter={true}
        setOpenModal={setOpenRetriveAccount}
        title={t('login.schema.retriveAccount')}
        content={
          <div className="flex flex-col">
            <Input
              icon={phone}
              type={'tel'}
              label={t('contactUs.label.phone')}
              placeholder={t('contactUs.placeholder.phone')}
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber}
            />
          </div>
        }
        confirm={retriveAccount}
      />
    </div>
  );
};

export default LoginForm;
