import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import {
  BMW,
  Calendar,
  car,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  moneyWhite,
  statisticsOrder,
  userMail,
  saudiUser,
  star,
  carIcon,
} from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import {
  convertDate,
  defaultLettersCircle,
  languageAr,
  toastConfig,
} from '../../helpers/helper';
import i18n from '../../languages/i18n';
import { updateOfferStatus } from '../../services/apis/offer';
import { deleteOrderCar } from '../../services/apis/owner';
import ButtonBadge from '../buttons/ButtonBadge';
import ButtonIcon from '../buttons/ButtonIcon';
import CarColor from '../orders/CarColor';
import { Button, MConfirmation } from './../index';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import { FaShare } from 'react-icons/fa';
import { MdShare } from 'react-icons/md';
import { Tooltip } from 'flowbite-react';

const CardOrder = ({ onClick, order, goOffers, goEditPage, refreshOrders }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openShare, setOpenShare] = useState(false);
  const [loading, setLoading] = useState(false);

  const removeOrderCar = async () => {
    try {
      const response = await deleteOrderCar('', order?.sellMediationId);
      console.log(response);
      refreshOrders();
      toast.success(t('orderDetails.deleteMessage'), toastConfig);
    } catch (error) {
      toast.error(error.response.data, toastConfig);
    }
  };

  const shareUrl = `https://staging-v2.mshrai.pages.dev/car-owner/${order.sellMediationId}/details`;
  const title = 'Check out this amazing mshrai site!';

  const handleShare = async () => {
    const shareData = {
      title: 'Share car owner order',
      text: 'Hey, look at this amazing mshrai site!',
      url: shareUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        toast.error('Link shared successfully!', toastConfig);
      } catch (err) {
        toast.error('Error sharing the link: ' + err.message, toastConfig);
      }
    } else {
      toast.error(
        'Web Share API is not supported on this device.',
        toastConfig
      );
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(t('orderDetails.copyLink'), toastConfig);
      })
      .catch((err) => {
        toast.success('Failed to copy link.', toastConfig);
      });
  };

  return (
    <div className="rounded-[20px] bg-[#ACEBEB] cursor-pointer flex flex-col justify-between shadow-lg h-[100%]">
      <div>
        <div className="flex flex-row justify-between">
          <div
            className={`bg-[#00CEBC] flex flex-row items-center ${languageAr ? 'rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]' : 'rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]'} text-[14px] text-[#ffffff] p-3  h-[50px]`}
          >
            <div>{t('listOrdersCarOwner.orderNumber')}</div>
            <div className="px-2">:</div>
            <div>#{order?.sellMediationId}</div>
          </div>
          <div className="flex flex-col items-center">
            <div className="flex flex-row items-center p-2 bg-[#E6EDFF] rounded-[7px] mx-5 mt-[5px]">
              <div>
                <img src={Calendar} className="w-[20px] h-[20px]" />
              </div>
              <div className="text-[13px] font-[600px] text-[#000000] px-2">
                {convertDate(order?.cars[0]?.createdAt)}
              </div>
              <div className="text-[11px] font-[600px] text-[#6A6A6A]">
                {order?.cars[0]?.createdAt.split('T')[1]?.slice(0, 8)}
              </div>
            </div>
            <div className="flex flex-row items-center pt-2">
              <div className="text-[#3E0292] text-[13px] font-500">
                {t('home.orders.status')}
              </div>
              <div className="text-[#3E0292] text-[13px] font-500 mx-2">:</div>
              <div className="text-[#3E0292] text-[13px] font-500">
                {order.statusId == 1
                  ? t('home.orders.open')
                  : order.statusId == 2
                    ? t('home.orders.close')
                    : order.statusId == 3
                      ? t('home.orders.complete')
                      : t('home.orders.open')}
              </div>
              <div
                className={`${order.statusId == 1 ? 'bg-[#04C500CC]' : order.statusId == 2 ? 'bg-[#ff0000CC]' : order.statusId == 3 ? 'bg-[#3E0292CC]' : 'bg-[#04C500CC]'}  w-[10px] h-[10px] rounded-full mx-2`}
              ></div>
              <Tooltip content={t('orderDetails.buttonShare')} style="dark">
              <div onClick={() => setOpenShare(true)} className="mx-3">
                <MdShare className="text-[#3E0292] text-[25px]" />
              </div>
              </Tooltip>
            </div>
          </div>
        </div>
        <div
          className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mr-auto ml-auto mt-5"
          onClick={onClick}
        >
          {!order?.cars[0]?.brand ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img
                    className="w-[20px] h-[20px]"
                    src={order?.cars[0]?.brand?.image}
                  />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr
                    ? order?.cars[0]?.brand.nameAr
                    : order?.cars[0]?.brand.nameEn}
                </div>
              </div>
            </div>
          )}
          {!order?.cars[0]?.model ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img className="w-[20px] h-[20px]" src={carIcon} />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr
                    ? order?.cars[0]?.model.nameAr
                    : order?.cars[0]?.model.nameEn}
                </div>
              </div>
            </div>
          )}
          {!order?.cars[0]?.city ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img src={location} className="w-[20px] h-[20px]" />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr
                    ? order?.cars[0]?.city?.nameAr
                    : order?.cars[0]?.city?.nameEn}
                </div>
              </div>
            </div>
          )}
          {!order?.cars[0]?.color ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div className="w-[20px] h-[20px]">
                  <CarColor
                    color={
                      order?.cars[0]?.color?.hexCode == '#FFFFFF'
                        ? '#808080'
                        : order?.cars[0]?.color?.hexCode
                    }
                  />{' '}
                </div>
                <div
                  style={{
                    color:
                      order?.cars[0]?.color?.hexCode == '#FFFFFF'
                        ? '#808080'
                        : order?.cars[0]?.color?.hexCode,
                  }}
                  className="text-[12px] font-400 px-2"
                >
                  {languageAr
                    ? order?.cars[0]?.color?.nameAr
                    : order?.cars[0]?.color?.nameEn}
                </div>
              </div>
            </div>
          )}
          {/* <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">

                       <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                      </div>
                      </div> */}
          {!order?.cars[0]?.odo ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">
              <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 h-[100%]">
                <div>
                  <img src={kilometers} className="w-[20px] h-[20px]" />
                </div>
                <div className="flex flex-row items-center px-2">
                  <div className="text-[#000000] text-[13px] font-400">
                    {order?.cars[0]?.odo}
                  </div>
                  <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                    {t('listOrdersCarOwner.km')}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!order?.cars[0]?.modelYear ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">
              <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 h-[100%]">
                <div>
                  <img src={Calendar} className="w-[20px] h-[20px]" />
                </div>
                <div className="flex flex-row items-center px-2">
                  <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                    {t('orderDetails.year')}
                  </div>
                  <div className="text-[#000000] text-[13px] font-400">
                    {order?.cars[0]?.modelYear}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-5 w-[90%] mr-auto ml-auto">
          <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 ">
            <div>
              <img src={moneyWhite} className="w-[20px] h-[20px]" />
            </div>
            <div className="flex flex-row items-center px-2">
              <div className="text-[12px] font-500 text-[#ffffff]">
                {t('listOrdersCarOwner.price')}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">:</div>
              <div className="text-[12px] font-500 text-[#ffffff]">
                {order?.cars[0]?.price}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">
                {t('listOrdersCarOwner.Rial')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {order?.countOfOffers == 0 ? (
           <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
           <ButtonBadge value={order?.countOfOffers} className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg" onClick={goOffers} dark={false} title={t("listOrdersCarOwner.offers")}/>
          </div>
         ):( */}

      {/* <div className={order.countOfOffers != 0 && order.statusId == 1 ? "grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 w-[90%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]" : order.countOfOffers != 0 ? "grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]" :  "grid md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 sm:grid-cols-3 w-[90%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]"}>
                <ButtonBadge value={order?.countOfOffers} className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg" onClick={goOffers} dark={false} title={t("listOrdersCarOwner.offers")}/>
                {order.statusId == 1 ? (
                  <Button dark={false} className="shadow-lg bg-[#3E0292] border-[#3E0292] pl-[0px] pr-[0px]" title={t("listOrdersCarOwner.editOrder")} onClick={goEditPage}/>
                ):null}
                 {order.countOfOffers == 0 ? (
                  <Button dark={false} className="bg-[#ff0000] border-[#ff0000] pl-[0px] pr-[0px] shadow-lg px-0" title={t("listOrdersCarOwner.removeOrder")} onClick={removeOrderCar}/>
                ):null}
            </div> */}

      <div
        className={
          'grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]'
        }
      >
        <ButtonBadge
          value={order?.countOfOffers}
          className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg"
          onClick={goOffers}
          dark={false}
          title={t('listOrdersCarOwner.offers')}
        />
      </div>
      {/* )} */}

      {/* share model */}
      <MConfirmation
        loading={loading}
        openModal={openShare}
        showFooter={false}
        setOpenModal={setOpenShare}
        title={t('orderDetails.titleShareOrder')}
        content={
          <div className="grid grid-cols-3 gap-3">
            <div className="flex flex-row items-center justify-between">
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <Button
              dark={true}
              title={t('orderDetails.other')}
              onClick={handleShare}
            />
            <Button
              dark={true}
              title={t('orderDetails.copyLinkButton')}
              onClick={handleCopyLink}
            />
          </div>
        }
      />
    </div>
  );
};

export default CardOrder;
