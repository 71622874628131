import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterOrderContext } from '../../context/FilterOrderContext';
import Button from '../buttons/Button';
import Brand from './parts/Brand';
import City from './parts/City';
import Model from './parts/Model';
import Price from './parts/Price';
import Status from './parts/Status';
import Type from './parts/Type';
import Color from './parts/Color';
import Odo from './parts/Odo';

const Filter = ({ close, confirm, from, hideStatus = false ,hideType=true,showColor=false,showOdo=false}) => {
  const filterOrderContext = useContext(FilterOrderContext);
  const { t } = useTranslation();
  const [openTab, setOpenTab] = useState('');
  return (
    <div>
      {openTab == '' ? (
        <>
        <div className='h-[300px] overflow-y-scroll'>
          {!hideStatus && (
            <div
              className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 cursor-pointer"
              onClick={() => setOpenTab('status')}
            >
              <div className="text-[#3E0292] text-[15px] font-500">
                {t('filterModal.status')}
              </div>
              {filterOrderContext?.statuses?.length == 0 ? null : (
                <div className="grid grid-cols-3 gap-3 mt-3">
                  {filterOrderContext?.statuses?.map((status, index) => (
                    <div
                      key={index}
                      className="bg-[#3E029240] flex items-center justify-center rounded-[15px] text-[#3E0292] text-[13px] font-400 p-3"
                    >
                      {status.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}


       {!hideType && (
            <div
              className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 cursor-pointer"
              onClick={() => setOpenTab('type')}
            >
              <div className="text-[#3E0292] text-[15px] font-500">
                {t('filterModal.type')}
              </div>
              {filterOrderContext?.types?.length == 0 ? null : (
                <div className="grid grid-cols-2 gap-3 mt-3">
                  {filterOrderContext?.types?.map((type, index) => (
                    <div
                      key={index}
                      className="bg-[#3E029240] flex items-center justify-center rounded-[15px] text-[#3E0292] text-[13px] font-400 p-3"
                    >
                      {type.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

         {showColor && (
            <div
              className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 cursor-pointer"
              onClick={() => setOpenTab('color')}
            >
              <div className="text-[#3E0292] text-[15px] font-500">
                {t('filterModal.color')}
              </div>
              {filterOrderContext?.colors?.length == 0 ? null : (
                <div className="grid grid-cols-3 gap-3 mt-3">
                  {filterOrderContext?.colors?.map((color, index) => (
                    <div
                      key={index}
                      className="bg-[#3E029240] flex items-center justify-center rounded-[15px] text-[#3E0292] text-[13px] font-400 p-3"
                    >
                      {color.label}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div
            className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 my-3 cursor-pointer"
            onClick={() => setOpenTab('brand')}
          >
            <div className="text-[#3E0292] text-[15px] font-500">
              {t('filterModal.brand')}
            </div>
            {filterOrderContext?.brands?.length == 0 ? null : (
              <div className="grid grid-cols-3 gap-3 mt-3">
                {filterOrderContext?.brands?.map((brand, index) => (
                  <div
                    key={index}
                    className="bg-[#3E029240] flex items-center justify-center rounded-[15px] text-[#3E0292] text-[13px] font-400 p-3"
                  >
                    {brand.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 cursor-pointer"
            onClick={() => setOpenTab('model')}
          >
            <div className="text-[#3E0292] text-[15px] font-500">
              {t('filterModal.model')}
            </div>
            {filterOrderContext?.models?.length == 0 ? null : (
              <div className="grid grid-cols-3 gap-3 mt-3">
                {filterOrderContext?.models?.map((model, index) => (
                  <div
                    key={index}
                    className="bg-[#3E029240] flex items-center justify-center rounded-[15px] text-[#3E0292] text-[13px] font-400 p-3"
                  >
                    {model.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 my-3 cursor-pointer"
            onClick={() => setOpenTab('price')}
          >
            <div className="text-[#3E0292] text-[15px] font-500">
              {t('filterModal.price')}
            </div>
            {!filterOrderContext.priceFrom && !filterOrderContext.priceTo ? null : (
              <div
                className="mt-3"
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#a0f0f0',
                  color: '#4e008e',
                  borderRadius: '8px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div className="mx-2 text-[12px]">{t('filterModal.from')}</div>
                <div className='text-[12px]'>{filterOrderContext.priceFrom}</div>
                <div className="mx-2 text-[12px]">{t('filterModal.rial')}</div>
                <div className='text-[12px]'>{t('filterModal.to')}</div>
                <div className="mx-2 text-[12px]">{filterOrderContext.priceTo}</div>
                <div className='text-[12px]'>{t('filterModal.rial')}</div>
              </div>
            )}
          </div>
        {showOdo && (
          <div
            className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 my-3 cursor-pointer"
            onClick={() => setOpenTab('odo')}
          >
            <div className="text-[#3E0292] text-[15px] font-500">
              {t('filterModal.odo')}
            </div>
            {!filterOrderContext.odoFrom && !filterOrderContext.odoTo ? null : (
              <div
                className="mt-3"
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#a0f0f0',
                  color: '#4e008e',
                  borderRadius: '8px',
                  textAlign: 'center',
                  fontWeight: 'bold',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div className="mx-2 text-[12px]">{t('filterModal.odoFrom')}</div>
                <div className='text-[12px]'>{filterOrderContext.odoFrom}</div>
                <div className="mx-2 text-[12px]">{t('filterModal.km')}</div>
                <div className='text-[12px]'>{t('filterModal.to')}</div>
                <div className="mx-2 text-[12px]">{filterOrderContext.odoTo}</div>
                <div className='text-[12px]'>{t('filterModal.km')}</div>
              </div>
            )}
          </div>
         )}

          <div
            className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 cursor-pointer"
            onClick={() => setOpenTab('city')}
          >
            <div className="text-[#3E0292] text-[15px] font-500">
              {t('filterModal.city')}
            </div>
            {filterOrderContext?.cities?.length == 0 ? null : (
              <div className="grid grid-cols-3 gap-3 mt-3">
                {filterOrderContext?.cities?.map((city, index) => (
                  <div
                    key={index}
                    className="bg-[#3E029240] flex items-center justify-center rounded-[15px] text-[#3E0292] text-[13px] font-400 p-3"
                  >
                    {city.label}
                  </div>
                ))}
              </div>
            )}
          </div>
          </div>
          {/* <div className="flex flex-col bg-[#00CEBC30] rounded-[15px] p-3 my-3 cursor-pointer">
                <div className="text-[#3E0292] text-[15px] font-500">طريقة التواصل :</div>


            </div> */}
          <div className="grid grid-cols-2 gap-x-2 mt-3">
            <Button
              dark={false}
              className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]"
              title={t('filterModal.action')}
              onClick={confirm}
            />
            <Button
              dark={false}
              className="bg-[#FF4342] border-[#FF4342] rounded-[10px]"
              title={t('filterModal.cancel')}
              onClick={close}
            />
          </div>
        </>
      ) : openTab == 'brand' ? (
        <Brand back={() => setOpenTab('')} />
      ) : openTab == 'status' ? (
        <Status from={from} back={() => setOpenTab('')} />
      ) : openTab == 'type' ? (
        <Type back={() => setOpenTab('')} />
      ) : openTab == 'odo' ? (
        <Odo back={() => setOpenTab('')} />
      ) : openTab == 'color' ? (
        <Color back={() => setOpenTab('')} />
      ) : openTab == 'city' ? (
        <City back={() => setOpenTab('')} />
      ) : openTab == 'model' ? (
        <Model back={() => setOpenTab('')} />
      ) : openTab == 'price' ? (
        <Price back={() => setOpenTab('')} />
      ) : null}
    </div>
  );
};

export default Filter;
