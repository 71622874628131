import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { star } from '../../assets/icons';
import { defaultLettersCircle } from '../../helpers/helper';

const Comments = ({ mediator }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <div className="p-5 flex flex-row items-center justify-between shadow-sm">
        <div className="text-[#3E0292] text-[15px] font-700">
          {t('profileInfo.others')}
        </div>
        {!mediator?.medCommentsRating ||
        mediator?.medCommentsRating?.commentRatingRes?.length == 0 ? null : (
          <div className="flex flex-row items-center">
            <div className="text-[14px] font-400 text-[#3E0292] mx-3">
              {mediator?.medCommentsRating?.commentRatingRes?.length}
            </div>
            <div className="text-[14px] font-400 text-[#3E0292]">
              {t('profileInfo.comment')}
            </div>
          </div>
        )}
      </div>
      {!mediator?.medCommentsRating ||
      mediator?.medCommentsRating?.commentRatingRes?.length == 0 ? (
        <div className="flex items-center justify-center h-[140px] p-3">
          {t('profileInfo.notFoundOthers')}
        </div>
      ) : (
        <div className="flex flex-col p-3 h-[400px] overflow-y-scroll">
          {mediator?.medCommentsRating?.commentRatingRes.map((item, index) => (
            <div className="flex flex-row bg-[#BADCEF] shadow-sm rounded-[10px] mt-2 p-3">
              <div className="rounded-full">
                {!item.buyerInfo.profileImg ? (
                  defaultLettersCircle(
                    item?.buyerInfo?.fullName,
                    'w-[40px]',
                    'h-[40px]',
                    'text-[18px]'
                  )
                ) : (
                  <img
                    src={item.buyerInfo.profileImg}
                    className="w-[40px] h-[40px] rounded-full"
                  />
                )}
              </div>
              <div className="flex flex-row justify-between items-center mx-5 w-[90%]">
                <div className="flex flex-col justify-between">
                  <div
                    className="text-[13px] font-500 text-black cursor-pointer"
                    onClick={() => {
                      navigate(`/profile-info/${item?.buyerInfo?.id}`);
                      window.location.reload(false);
                    }}
                  >
                    {item?.buyerInfo?.fullName}
                  </div>
                  <div className="text-[10px] text-[#424242] font-400 mt-3">
                    {item.comment}
                  </div>
                </div>
                <div className="flex flex-col justify-between items-center">
                  <div className="text-[11px] text-[#424242] font-400">
                    {item.date}
                  </div>
                  <div className="flex flex-row items-center mt-3">
                    <div>
                      <img src={star} className="w-[20px] h-[20px]" />
                    </div>
                    <div className="text-[#0000000] text-[12px] font-400 px-2 pt-[3px]">
                      {item?.rating}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Comments;
