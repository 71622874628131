import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import {
  BMW,
  Calendar,
  car,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  moneyWhite,
  statisticsOrder,
  userMail,
  saudiUser,
  star,
  timesOffer,
  checkOffer,
  carIcon,
} from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import {
  convertDate,
  defaultLettersCircle,
  languageAr,
  toastConfig,
  userRole,
} from '../../helpers/helper';
import i18n from '../../languages/i18n';
import { updateOfferStatus } from '../../services/apis/offer';
import ButtonIcon from '../buttons/ButtonIcon';
import { Button } from './../index';
import CarColor from './CarColor';
const MCardOffer = ({ onClick, offer }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loadingUpdateAccept, setLoadingUpdateAccept] = useState(false);
  const [loadingUpdateReject, setLoadingUpdateReject] = useState(false);

  const loader = (type) => {
    let loaderFalse =
      type == 'accept'
        ? setLoadingUpdateAccept(false)
        : setLoadingUpdateReject(false);
    return loaderFalse;
  };

  const offerUpdateStatus = async (type) => {
    type == 'accept'
      ? setLoadingUpdateAccept(true)
      : setLoadingUpdateReject(true);

    let data = {
      offerId: offer.offerId,
      offerStatusId: type == 'accept' ? 1 : 2,
    };
    try {
      const response = await updateOfferStatus(data);
      toast.success(t('تم التعديل الحالة بنجاح'), toastConfig, loader(type));
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig, loader(type));
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig, loader(type));
        }
      }
    }
  };
  return (
    <div className="rounded-[20px] bg-[#D7E2FF] cursor-pointer flex flex-col justify-between shadow-lg h-[100%]">
      <div>
        <div className="flex flex-row justify-between items-center">
          <div
            className={`bg-[#3E0292] flex flex-row items-center ${languageAr ? 'rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]' : 'rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]'} text-[14px] text-[#ffffff] p-4 h-[50px]`}
          >
            <div>{t('listOffers.offerNumber')}</div>
            <div className="px-2">:</div>
            <div>#{offer.offerId}</div>
          </div>
          <div className="flex flex-row items-center p-2 bg-[#E6EDFF] rounded-[7px] mx-5">
            <div>
              <img src={Calendar} className="w-[20px] h-[20px]" />
            </div>
            <div className="text-[13px] font-[600px] text-[#000000] px-2">
              {convertDate(offer?.cratedAt)}
            </div>
            <div className="text-[11px] font-[600px] text-[#6A6A6A]">
              {offer?.cratedAt?.split('T')[1]?.slice(0, 8)}
            </div>
          </div>
        </div>
        <div
          className="flex flex-row items-center w-[90%] mr-auto ml-auto mt-5"
          onClick={() =>
            navigate(`/profile-info/${offer?.buyerRequest?.buyerInfo?.id}`)
          }
        >
          <div className="rounded-full p-1 bg-[#73D3CB]">
            {offer?.buyerRequest?.buyerInfo?.profileImg == null ? (
              defaultLettersCircle(offer?.buyerRequest?.buyerInfo?.fullName)
            ) : (
              <img
                className="w-[36px] h-[36px] rounded-full"
                src={offer?.buyerRequest?.buyerInfo?.profileImg}
              />
            )}
          </div>
          <div className="flex flex-col mx-3">
            <div className="flex flex-row items-center text-[#3E0292] text-[15px] font-700">
              <div>
                {userRole == 3
                  ? t('listOffers.buyer')
                  : t('listOffers.mediator')}
              </div>
              <div className="px-2">:</div>
              <div>{offer?.buyerRequest?.buyerInfo?.fullName}</div>
            </div>
            <div className="flex flex-row items-center">
              <div>
                <img src={star} />
              </div>
              <div className="text-[#3E0292] text-[15px] font-700">
                {offer?.buyerRequest?.buyerInfo?.avgRating}
              </div>
            </div>
          </div>
        </div>
        <div
          className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mr-auto ml-auto mt-5"
          onClick={onClick}
        >
          {!offer?.cars[0]?.brand ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img
                    className="w-[20px] h-[20px]"
                    src={offer?.cars[0]?.brand?.image}
                  />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr
                    ? offer?.cars[0]?.brand?.nameAr
                    : offer?.cars[0]?.brand?.nameEn}
                </div>
              </div>
            </div>
          )}

          {!offer?.cars[0]?.model ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img className="w-[20px] h-[20px]" src={carIcon} />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr
                    ? offer?.cars[0]?.model?.nameAr
                    : offer?.cars[0]?.model?.nameEn}
                </div>
              </div>
            </div>
          )}
          {!offer?.cars[0]?.city ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img src={location} className="w-[20px] h-[20px]" />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr
                    ? offer?.cars[0]?.city?.nameAr
                    : offer?.cars[0]?.city?.nameEn}
                </div>
              </div>
            </div>
          )}
          {!offer?.cars[0]?.color ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div className="w-[20px] h-[20px]">
                  <CarColor
                    color={
                      offer?.cars[0]?.color?.hexCode == '#FFFFFF'
                        ? '#808080'
                        : offer?.cars[0]?.color?.hexCode
                    }
                  />{' '}
                </div>
                <div
                  style={{
                    color:
                      offer?.cars[0]?.color?.hexCode == '#FFFFFF'
                        ? '#808080'
                        : offer?.cars[0]?.color?.hexCode,
                  }}
                  className="text-[12px] font-400 px-2"
                >
                  {languageAr
                    ? offer?.cars[0]?.color?.nameAr
                    : offer?.cars[0]?.color?.nameEn}
                </div>
              </div>
            </div>
          )}
          {/* <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">

                       <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                      </div>
                      </div> */}
          {!offer?.cars[0]?.odo ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">
              <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 h-[100%]">
                <div>
                  <img src={kilometers} className="w-[20px] h-[20px]" />
                </div>
                <div className="flex flex-row items-center px-2">
                  <div className="text-[#000000] text-[13px] font-400">
                    {offer?.cars[0]?.odo}
                  </div>
                  <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                    {t('listOrders.km')}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!offer?.cars[0]?.modelYear ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img className="w-[20px] h-[20px]" src={Calendar} />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {offer?.cars[0]?.modelYear}
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-row items-center justify-center text-[11px] font-700 text-[#3E0292] underline mt-2">
            {t('listOffers.showMore')}
          </div>
        </div>
        <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-5 w-[90%] mr-auto ml-auto">
          <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 ">
            <div>
              <img src={moneyWhite} className="w-[20px] h-[20px]" />
            </div>
            <div className="flex flex-row items-center px-2">
              <div className="text-[12px] font-500 text-[#ffffff]">
                {t('listOffers.price')}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">:</div>
              <div className="text-[12px] font-500 text-[#ffffff]">
                {offer?.cars[0]?.price}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">
                {t('listOffers.Rial')}
              </div>
            </div>
            {/* <div className="text-[12px] font-500 text-[#00CEBC] px-2">السعر : 500.125 / 1,000.000 ريال سعودى</div> */}
          </div>
        </div>
        <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-3 w-[90%] mr-auto ml-auto">
          <div className="flex flex-row bg-[#3E0292] rounded-[12px] p-2">
            <div>
              <img src={moneyWhite} />
            </div>
            <div className="flex flex-row items-center px-2">
              <div className="text-[12px] font-500 text-[#ffffff]">
                {t('listOffers.commission')}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">:</div>
              <div className="text-[12px] font-500 text-[#ffffff]">
                {offer?.cars[0]?.commission}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">
                {t('listOffers.Rial')}
              </div>
            </div>
            {/* <div className="text-[12px] font-500 text-[#00CEBC] px-2">السعر : 500.125 / 1,000.000 ريال سعودى</div> */}
          </div>
        </div>
      </div>
      {offer.offerStatus == 2 ? (
        <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
          <ButtonIcon
            className="bg-[#DDC0D9] border border-[#DDC0D9] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg"
            titleClass={'text-[#FD2828] text-[14px] font-500'}
            icon={timesOffer}
            dark={false}
            title={t('statusActions.rejectRequest')}
          />
        </div>
      ) : offer.offerStatus == 1 ? (
        <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
          <ButtonIcon
            className="bg-[#DEF3FF] border border-[#DEF3FF] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg text-[#00CEBC]"
            titleClass={'text-[#00CEBC] text-[14px] font-500'}
            icon={checkOffer}
            dark={false}
            title={t('statusActions.acceptRequest')}
          />
        </div>
      ) : offer.offerStatus == 4 ? (
        <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
          <ButtonIcon
            className="bg-[#DEF3FF] border border-[#DEF3FF] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg text-[#00CEBC]"
            titleClass={'text-[#00CEBC] text-[14px] font-500'}
            icon={checkOffer}
            dark={false}
            title={t('statusActions.completeRequest')}
          />
        </div>
      ) : (
        <>
          {userRole == 3 ? (
            <div className="grid md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
              <ButtonIcon
                loading={loadingUpdateAccept}
                icon={checkOffer}
                className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
                dark={false}
                title={t('في انتظار الموافقة')}
              />
            </div>
          ) : (
            <div className="grid md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 sm:grid-cols-1 w-[90%] mr-auto ml-auto gap-x-2 mt-5 mb-[-30px]">
              <Button
                loading={loadingUpdateAccept}
                className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg"
                dark={false}
                title={t('listOffers.accept')}
                onClick={() => offerUpdateStatus('accept')}
              />
              <Button
                loading={loadingUpdateReject}
                className="bg-[#FF0000] border border-[#FF0000] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0 shadow-lg"
                dark={false}
                title={t('listOffers.reject')}
                onClick={() => offerUpdateStatus('reject')}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default MCardOffer;
