import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { closeOrder, completeOrder, openOrder } from '../../assets/icons';
import { getOrdersStatusStatistics } from '../../services/apis/order';
import Environment from '../../services/Environment';
import { get } from '../../services/Request';
import { CardSkeleton } from '../index';
import { userToken } from '../../helpers/helper';

const DashboardCard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(userToken){
    getAllStatistics();
    }
  }, []);

  const getAllStatistics = async () => {
    setLoading(true);
    const response = await getOrdersStatusStatistics('');
    setStatistics(response.data, setLoading(false));
  };
  return (
    <>
      {loading ? (
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4 w-[80%] mr-auto ml-auto mt-10">
          <CardSkeleton number={3} height={'300px'} />
        </div>
      ) : (
        <div className="grid 2xl:grid-cols-3 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-x-4 w-[80%] mr-auto ml-auto mt-10">
          <div
            className="flex flex-col justify-between bg-[#FEBFBF] rounded-[15px] p-5 max600:mt-5 max600:order-3 sm:mt-3 cursor-pointer"
            onClick={() => navigate('/orders', { state: { status: 'CLOSED' } })}
          >
            <div className="flex items-center justify-center">
              <img src={closeOrder} />
            </div>
            <div className="flex items-center justify-center bg-[#FFFFFF99] rounded-[15px]">
              <div className="text-[#444444] text-[15px] font-700">
                {t('home.orders.closeOrders')}
              </div>
              <div className="text-[#3E0292] text-[35px] font-700 mx-5">
                {statistics?.countOfClosedRequests}
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-between bg-[#BBF7F1] rounded-[15px] p-5 max600:mt-5 max600:order-2 sm:mt-3 cursor-pointer"
            onClick={() =>
              navigate('/orders', { state: { status: 'COMPLETED' } })
            }
          >
            <div className="flex items-center justify-center">
              <img src={completeOrder} />
            </div>
            <div className="flex items-center justify-center bg-[#FFFFFF99] rounded-[15px]">
              <div className="text-[#444444] text-[15px] font-700">
                {t('home.orders.completeOrders')}
              </div>
              <div className="text-[#3E0292] text-[35px] font-700 mx-5">
                {statistics?.countOfCompletedRequests}
              </div>
            </div>
          </div>
          <div
            className="flex flex-col justify-between bg-[#D7E2FF] rounded-[15px] p-5 max600:mt-5 max600:order-1 sm:mt-3 cursor-pointer"
            onClick={() => navigate('/orders', { state: { status: 'OPEN' } })}
          >
            <div className="flex items-center justify-center">
              <img src={openOrder} />
            </div>
            <div className="flex items-center justify-center bg-[#FFFFFF99] rounded-[15px]">
              <div className="text-[#444444] text-[15px] font-700">
                {t('home.orders.openOrders')}
              </div>
              <div className="text-[#3E0292] text-[35px] font-700 mx-5">
                {statistics?.countOfOpenRequests}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardCard;
