// import { CompleteOfferProvider } from "../../../../context/CompleteOfferContext"
import { CompleteOfferProvider } from '../../../../context/CompleteOfferContext';
import Order from './Order';
const OrderDetails = () => {
  return (
    <CompleteOfferProvider>
      <Order />
    </CompleteOfferProvider>
  );
};

export default OrderDetails;
