import { toast } from 'react-toastify';
import { getConfirmationRequest } from '../services/apis/order';

// Notification screen types
export const NotificationScreenType = {
  NONE: 'notification_screen_noRedirection',
  REQUEST: 'request_screen_idOfRequest',
  CHAT: 'chat_screen_idOfConversation',
  OFFER: 'offer_Screen_idOfOffer',
  CONFIRMATION: 'confirmation_screen_idOfConfirmationRequest',
  SELL_MEDIATION: 'sellmediation_screen_idOfSellMediation',
  MEDIATION_OFFER: 'mediationoffer_screen_idOfMediationOffer',
  SM_CHAT: 'sm_chat_screen_idOfConversation'
};

// Handler for request screen notifications
const handleRequestNotification = (notification, {navigate}) => {
  navigate(`/orders/${notification.redirectionId}`);
};

// Handler for chat screen notifications
const handleChatNotification = (notification, {navigate}) => {
  navigate('/chats', {
    state: {
      receiverInfo: notification.chatRes?.receiver,
      offerId: notification.offerRes?.offerId,
      conversationId: notification.chatRes?.conversationId,
      chatStatus: notification.offerRes?.offerStatus
    }
  });
};

// Handler for offer screen notifications
const handleOfferNotification = (notification, {navigate, userRole}) => {
  if (userRole === 3) {
    const offerStatus = notification.offerRes?.offerStatus;
    
    if (offerStatus === 2) {
      navigate(`/orders/${notification.offerRes?.requestId}`);
    } else if ([1, 4].includes(offerStatus) && notification.offerRes?.conversationId) {
      handleChatNotification(notification, {navigate});
    }
  } else {
    navigate(`/orders/${notification.offerRes?.requestId}`);
  }
};

// Handler for confirmation screen notifications
const handleConfirmationNotification = (notification, {
  callbacks,
  userRole
}) => {
  const {
    onSetConfirmationId,
    onSetOpenConfirmRequestModal,
    onSetHideActionModal,
    onSetOpenPaidRequestModal,
    onGetConfirmationRequestData
  } = callbacks;

  if (!onSetConfirmationId) return;
  
  onSetConfirmationId(notification.redirectionId);
  
  const crStatus = notification.crRes.crStatus;
  
  if (crStatus === 3 && onSetOpenConfirmRequestModal && onSetHideActionModal && onGetConfirmationRequestData) {
    onSetOpenConfirmRequestModal(true);
    onSetHideActionModal(false);
    onGetConfirmationRequestData(notification.redirectionId);
  } else if (crStatus === 4) {
    if (userRole === 2 && onSetOpenPaidRequestModal && onGetConfirmationRequestData) {
      onSetOpenPaidRequestModal(true);
      onGetConfirmationRequestData(notification.redirectionId);
    } else if (onSetOpenConfirmRequestModal && onSetHideActionModal && onGetConfirmationRequestData) {
      onSetOpenConfirmRequestModal(true);
      onSetHideActionModal(true);
      onGetConfirmationRequestData(notification.redirectionId);
    }
  } else if (onSetOpenConfirmRequestModal && onSetHideActionModal && onGetConfirmationRequestData) {
    onSetOpenConfirmRequestModal(true);
    onSetHideActionModal(true);
    onGetConfirmationRequestData(notification.redirectionId);
  }
};

// Handler for sell mediation notifications
const handleSellMediationNotification = (notification, {navigate, userRole, userId}) => {
  if (userRole === 3) {
    navigate(`/car-owner/${notification.redirectionId}/details`, {
      state: {
        showAddOffers: true,
        isCarOwner: notification.carOwnerInfo?.id === userId
      }
    });
  }
};

// Handler for mediation offer notifications
const handleMediationOfferNotification = (notification, {navigate, userId}) => {
  const isCarOwner = notification.carOwnerInfo?.id === userId;
  
  if (notification.mediationOffer?.moStatus === 3) {
    if (isCarOwner) {
      navigate(`/car-owner/${notification.mediationOffer?.smId}/details`, {
        state: { showAddOffers: false }
      });
    } else {
      navigate('/chats', {
        state: {
          receiverInfo: notification.smChatRes?.receiver,
          offerId: notification.mediationOffer?.moId,
          conversationId: notification.smChatRes?.conversationId
        }
      });
    }
  } else {
    navigate(`/car-owner/${notification.mediationOffer?.smId}/details`, {
      state: {
        showAddOffers: !isCarOwner
      }
    });
  }
};

// Handler for SM chat notifications
const handleSmChatNotification = (notification, {navigate}) => {
  navigate('/chats', {
    state: {
      receiverInfo: notification.smChatRes?.receiver,
      offerId: notification.mediationOffer?.moId,
      conversationId: notification.redirectionId
    }
  });
};

// Map of notification types to their handlers
const notificationHandlers = {
  [NotificationScreenType.NONE]: () => {},
  [NotificationScreenType.REQUEST]: handleRequestNotification,
  [NotificationScreenType.CHAT]: handleChatNotification,
  [NotificationScreenType.OFFER]: handleOfferNotification,
  [NotificationScreenType.CONFIRMATION]: handleConfirmationNotification,
  [NotificationScreenType.SELL_MEDIATION]: handleSellMediationNotification,
  [NotificationScreenType.MEDIATION_OFFER]: handleMediationOfferNotification,
  [NotificationScreenType.SM_CHAT]: handleSmChatNotification
};

export const handleNotificationAction = ({
  notification,
  navigate,
  userRole,
  userId,
  callbacks = {}
}) => {
  try {
    const handler = notificationHandlers[notification.redirectionScreen];
    if (handler) {
      handler(notification, {navigate, userRole, userId, callbacks});
    }
  } catch (error) {
    console.error('Error handling notification:', error);
  }
};
