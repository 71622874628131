import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { InputSearch, MCardSavedOffer } from '../../../../components';
import { getAllSavedOffersForMediator } from '../../../../services/apis/offer';
import { userRole } from '../../../../helpers/helper';

const SavedOffers = () => {
  const location = useLocation();
  let orderId = location.state.requestId;
  let buyerId = location.state.buyerId;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [savedOffers, setSavedOffers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchText, setSearchText] = useState('');

  const { id } = useParams();

  const getAllSavedOffers = async () => {
    setLoading(true);
    // try{
    let data = {
      params: {
        searchText: searchText,
      },
    };
    const response = await getAllSavedOffersForMediator(data);
    setSavedOffers(response.data.items);
  };

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const searchActionSavedOffers = () => {
    getAllSavedOffers();
  };

  useEffect(() => {
    getAllSavedOffers();
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          search={searchActionSavedOffers}
          showFilter={false}
          showSearch={true}
          showAdd={true}
          add={() => navigate('/offers/add')}
        />
        <div
          className={`grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 ${userRole == 3 ? 'gap-y-4' : 'gap-y-16'}  my-5`}
        >
          {savedOffers.map((offer, index) => (
            <div
            // ref={index === savedOffers.length - 1 ? lastItemRef : null}
            >
              <MCardSavedOffer
                key={offer.id}
                offer={offer}
                onClick={() =>
                  navigate(`/offers/${offer?.id}/add`, {
                    state: { requestId: orderId, buyerId: buyerId },
                  })
                }
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SavedOffers;
