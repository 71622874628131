import Environment from '../Environment';
import mainService, { baseURL } from '../mainService';

// Initialize chat conversation
export function initiateChat(data) {
  return mainService
    .post(Environment.initiateChat, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

// Get chat list for car owners
export function getChatListOfCarOwner() {
  return mainService
    .get(Environment.getChatListOfCarOwner)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

// Get general chat list
export function getChatList() {
  return mainService
    .get(Environment.getChatList)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

// Upload file to chat
export function uploadChatFile(file) {
  if (!file) return Promise.reject(new Error('No file provided'));

  const formData = new FormData();
  formData.append('file', file);

  const maxSize = 10 * 1024 * 1024; // 10MB limit
  if (file.size > maxSize) {
    return Promise.reject(new Error('File size exceeds 10MB limit'));
  }

  return mainService
    .post(Environment.uploadFile, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log('Upload progress:', percentCompleted);
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      console.error('File upload error:', error);
      return Promise.reject(error);
    });
}

// Upload image to chat
export function uploadChatImage(image) {
  const formData = new FormData();
  formData.append('image', image);

  return mainService
    .post(Environment.uploadImage, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

// Check if conversation exists
export function checkConversation(userId, mediatorId) {
  return mainService
    .get(`${Environment.checkConversation}/${userId}/${mediatorId}`)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}
