import { CompleteOfferProvider } from '../../../../context/CompleteOfferContext';
import MOfferDetails from './MOfferDetails';
const MOffer = () => {
  return (
    <CompleteOfferProvider>
      <MOfferDetails />
    </CompleteOfferProvider>
  );
};

export default MOffer;
