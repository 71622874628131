import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  BMW,
  Calendar,
  car,
  carOrder,
  clock,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  moneyWhite,
  saudiUser,
  star,
  statisticsOrder,
  whatsappLight,
  phoneLight,
  chatLight,
  carIcon,
  chatRound,
} from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import i18n from '../../languages/i18n';
import { MConfirmation } from '../index';
import { Carousel } from 'react-responsive-carousel';
import { defaultLettersCircle, languageAr } from '../../helpers/helper';
import CarColor from './CarColor';
import { getConfirmationRequest } from '../../services/apis/order';

const MCardOfferDetails = ({
  carOffer,
//   mediator,
  commision,
  from,
  offer,
  finalOffer,
//   goProfile,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [openModalCars, setOpenModalCars] = useState(false);

  const [confirmation, setConfirmation] = useState(null);
  console.log(finalOffer);
  useEffect(() => {
    if (finalOffer) {
      getConfirmationRequestData();
    }
  }, []);

  const getConfirmationRequestData = async () => {
    const response = await getConfirmationRequest('', finalOffer);
    console.log(response);
    setConfirmation(response.data);
  };

  return (
    <div
      className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto"
      style={{ direction: languageAr ? 'rtl' : 'ltr' }}
    >
      <div className="flex flex-row justify-between">
        {/* <div
          className="flex flex-row items-center p-2 m-[10px] rounded-[28px] bg-[#00CEBC] cursor-pointer"
          onClick={goProfile}
        > */}
          {/* <div className="rounded-full p-1 bg-[#73D3CB]">
            {mediator?.profileImg == null ? (
              defaultLettersCircle(mediator?.fullName)
            ) : (
              <img
                className="w-[36px] h-[36px] rounded-full"
                src={mediator?.profileImg}
              />
            )}
          </div> */}
          {/* <div className="flex flex-col mx-3">
            <div className="flex flex-row items-center text-[#ffffff] text-[15px] font-700">
              <div>{t('listOffers.mediator')}</div>
              <div className="px-2">:</div>
              <div>{mediator?.fullName}</div>
            </div>
            <div className="flex flex-row items-center">
              <div>
                <img src={star} />
              </div>
              <div className="text-[#ffffff] text-[15px] font-700">
                {mediator?.avgRating}
              </div>
            </div>
          </div> */}
        {/* </div> */}
        {/* {from == 'owner' ? (
          <>
            {offer.statusId == 3 ? (
              <div className="bg-[#00CEBC] rounded-[12px] shadow-lg flex flex-row justify-center items-center p-1">
                <img src={chatRound} />
                <div className="text-[13px] font-400 text-white mx-3">
                  {t('statusActions.conversation')}
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <>
            {!offer?.car ? null : (
              <div
                className={`bg-[#3E0292] flex flex-row items-center ${languageAr ? 'rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]' : 'rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]'} text-[14px] text-[#ffffff] p-3`}
              >
                <div>
                  {languageAr
                    ? offer?.car?.brand
                    : offer?.car?.brandEn}
                </div>
              </div>
            )}
          </>
        )} */}
      </div>
      <div className="grid grid-cols-[40%_60%] max800:grid-cols-1 p-5">
        <div className="flex flex-col p-2">
          {!offer?.car ? null : (
            <div className="flex flex-row items-center">
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('offerDetails.cars')}:
              </div>
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center">
                  <div>
                    <img
                      src={offer?.car?.image}
                      className="w-[20px] h-[20px]"
                    />
                  </div>
                  <div className="text-[#0000000] text-[12px] font-400 px-2">
                    {languageAr
                      ? offer?.car?.brand
                      : offer?.car?.brandEn}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!offer?.car?.model ? null : (
            <div className="flex flex-row items-center mt-3">
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('offerDetails.category')}:
              </div>
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center">
                  <div>
                    <img src={carIcon} className="w-[20px] h-[20px]" />
                  </div>
                  <div className="text-[#0000000] text-[12px] font-400 px-2">
                    {languageAr
                      ? offer?.car?.model?.brandTypes
                      : offer?.car?.model?.brandTypesEn}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* <div className="flex flex-row items-center my-5">
                <div className="text-[#3E0292] text-[14px] font-500"> {t("offerDetails.carType")}:</div>
                <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mx-2">
                <div className="bg-[#ffffff] rounded-[12px] p-3 flex flex-row items-center">
                  <div><img src={grayCar}/></div>
                  <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
               </div>
               </div>
                
            </div> */}

          {!offer?.city ? null : (
            <div className="flex flex-row items-center mt-3">
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('offerDetails.city')}:
              </div>
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center">
                  <div>
                    <img src={location} className="w-[20px] h-[20px]" />
                  </div>
                  <div className="text-[#0000000] text-[12px] font-400 px-2">
                    {languageAr
                      ? offer?.city?.city
                      : offer?.city?.cityEn}
                  </div>
                </div>
              </div>
            </div>
          )}
          {!offer?.odo ? null : (
            <div className="flex flex-row items-center my-3">
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('offerDetails.km')}:
              </div>
              <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mx-2">
                <div>
                  <img src={kilometers} className="w-[20px] h-[20px]" />
                </div>
                <div className="flex flex-row items-center px-2">
                  <div className="text-[#BBBBBB] text-[10px] font-400">
                    {t('listOrders.from')}
                  </div>
                  <div className="text-[#000000] text-[13px] font-400 px-2">
                    {offer.odo}
                  </div>
                  <div className="text-[#BBBBBB] text-[10px] font-400">
                    {t('listOrders.km')}
                  </div>
                </div>
              </div>
            </div>
          )}

          {!offer?.color ? null : (
            <div className="flex flex-row items-center">
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('offerDetails.color')}:
              </div>
              <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 mx-2">
                <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center">
                  <div>
                    <CarColor
                      color={
                        offer?.color?.hexCode == '#FFFFFF'
                          ? '#808080'
                          : offer?.color?.hexCode
                      }
                    />{' '}
                  </div>
                  <div
                    style={{
                      color:
                        offer?.color?.hexCode == '#FFFFFF'
                          ? '#808080'
                          : offer?.color?.hexCode,
                    }}
                    className="text-[12px] font-400 px-2"
                  >
                    {languageAr
                      ? offer?.color?.nameAr
                      : offer?.color?.nameEn}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-row items-center my-3">
            <div className="text-[#3E0292] text-[14px] font-500">
              {t('offerDetails.price')}:
            </div>
            <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 mx-2">
              <div>
                <img src={moneyWhite} className="w-[20px] h-[20px]" />
              </div>
              <div className="flex flex-row items-center px-2">
                <div className="text-[12px] font-500 text-[#ffffff]">
                  {t('listOrders.price')}
                </div>
                <div className="text-[12px] font-500 text-[#ffffff] px-2">
                  :
                </div>
                <div className="text-[12px] font-500 text-[#ffffff] px-2">
                  {offer?.price}
                </div>
                <div className="text-[12px] font-500 text-[#ffffff]">
                  {t('listOrders.Rial')}
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center">
            <div className="text-[#3E0292] text-[14px] font-500">
              {t('offerDetails.commission')}:
            </div>
            <div className="flex flex-row bg-[#04C500CC] rounded-[12px] p-2 mx-2">
              <div>
                <img src={moneyWhite} className="w-[20px] h-[20px]" />
              </div>
              <div className="flex flex-row items-center px-2">
                <div className="text-[12px] font-500 text-[#ffffff] px-2">
                  {commision ? commision : offer?.commission}
                </div>
                {offer.commissionType == "CASH" ? (
                  <div className="text-[12px] font-500 text-[#ffffff]">
                    {t('listOrders.Rial')}
                  </div>
                ):null}
              </div>
            </div>
          </div>
          {!confirmation?.invoiceUrl ? null : (
            <div className="flex flex-row items-center my-3">
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('listOrders.invoice')}:
              </div>
              <div className="flex flex-row bg-[#3E0292] rounded-[12px] p-2 mx-2">
                <div>
                  <img src={moneyWhite} className="w-[20px] h-[20px]" />
                </div>
                <div
                  className="text-[12px] font-500 text-[#ffffff] px-2"
                  onClick={() =>
                    (window.location.href = confirmation?.invoiceUrl)
                  }
                >
                  {t('offerDetails.downloadInvoice')}
                </div>

                {/* <div className="flex flex-row items-center px-2">
    <div className="text-[12px] font-500 text-[#ffffff]">{t("listOrders.invoice")}</div>
    <div className="text-[12px] font-500 text-[#ffffff] px-2">:</div>
    <div className="text-[12px] font-500 text-[#ffffff] px-2">{confirmation?.invoiceUrl}</div>

</div> */}
              </div>
            </div>
          )}
        </div>
        <div
          className={`flex flex-col border max800:border-0 ${languageAr ? 'border-r-[#ffffff]' : 'border-l-[#ffffff]'} p-3`}
        >
          <div className="flex flex-row items-center">
            <div className="text-[#3E0292] text-[14px] font-500">
              {' '}
              {t('offerDetails.year')}:
            </div>
            <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 mx-2">
              <div>
                <img src={Calendar} className="w-[20px] h-[20px]" />
              </div>
              <div className="flex flex-row items-center px-2">
                <div className="text-[#000000] text-[13px] font-[600px] px-2">
                  {offer?.modelYear}
                </div>
              </div>
            </div>
          </div>
          {offer?.description == '' ? null : (
            <div className="flex flex-row items-center mt-3">
              <div className="text-[#3E0292] text-[14px] font-500">
                {' '}
                {t('offerDetails.description')}:
              </div>
              <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 mx-2">
                <div className="text-[#303030] text-[14px] font-400">
                  {offer?.description}
                </div>
              </div>
            </div>
          )}

          {/* <div className="flex flex-row items-center mt-3">
            <div className="text-[#3E0292] text-[14px] font-500">
              {' '}
              {t('offerDetails.order')}:
            </div>
            <div
              className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 mx-2"
              onClick={() => navigate(`/orders/${offer?.requestId}`)}
            >
              <div className="text-[#303030] text-[14px] font-400">
                {offer?.requestId}
              </div>
            </div>
          </div> */}

          {offer?.carFiles?.length == 0 ? null : (
            <div
              className="flex flex-col my-3 cursor-pointer"
              onClick={() => setOpenModalCars(true)}
            >
              <div className="text-[#3E0292] text-[14px] font-500">
                {t('orderDetails.images')}:
              </div>
              <div className="bg-[#ffffff] rounded-[12px] p-2 mx-2">
                <div className="grid xl:grid-cols-3 2xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-3 max600:grid-cols-1 sm:grid-cols-2 gap-2">
                  {offer?.carFiles?.map((image, index) => (
                    <div key={index}>
                      <img
                        src={image}
                        className="h-[100px] w-[100%] rounded-[5px] border-[0.5px] border-[#999999]"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <MConfirmation
        openModal={openModalCars}
        setOpenModal={setOpenModalCars}
        title={t('orderDetails.images')}
        content={
          <Carousel>
            {carOffer?.filesUrl?.map((item, index) => (
              <div>
                <img
                  src={item}
                  className="h-[300px]"
                  alt={`image ${index + 1}`}
                />
                <p className="legend">
                  {t('myOrders.image')} {index + 1}
                </p>
              </div>
            ))}
          </Carousel>
        }
      />
    </div>
  );
};

export default MCardOfferDetails;
