import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  BMW,
  Calendar,
  car,
  carOrder,
  checkOffer,
  clock,
  confirmDelete,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  moneyWhite,
  pen,
  statisticsOrder,
  timesOffer,
  trash,
} from '../../../../assets/icons';
import { Tooltip } from 'flowbite-react';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import {
  ButtonBadge,
  ButtonIcon,
  CarColor,
  InputSearch,
  MConfirmation,
  CardSkeleton,
  Button,
  ConfirmRequest,
  PaidRequest,
  MediatorRate,
  Textarea,
  CheckBox,
} from '../../../../components';
import {
  addToFavourite,
  checkOfferForMediator,
  closeOrderCar,
  deleteOrder,
  getConfirmationRequest,
  getOrder,
  getStatusConfirmationOrder,
  paidConfirmationRequest,
  rateMediator,
  updateConfirmationRequest,
} from '../../../../services/apis/order';
import { Lang } from '../../../../helpers/contents';
import i18n from '../../../../languages/i18n';
import Environment from '../../../../services/Environment';
import { get } from '../../../../services/Request';
import {
  convertDate,
  languageAr,
  toastConfig,
  userRole,
} from '../../../../helpers/helper';
import { Bounce, toast } from 'react-toastify';
import { getUserInfo } from '../../../../services/apis/user';
import { carIcon } from '../../../../assets/icons';
import { ChooseMediator } from '../../../../components';
import { CompleteOrder } from '../../../../components';
import { confirmIcon } from '../../../../assets/icons';
import { CompleteOfferContext } from '../../../../context/CompleteOfferContext';
import { menuDots } from '../../../../assets/icons';
import { loadingGreen } from '../../../../assets/icons';
import { loadingRed } from '../../../../assets/icons';
import {
  confirmationOffer,
  getOfferWithRequestForCompleteRequest,
} from '../../../../services/apis/offer';
import { defaultLettersCircle } from '../../../../helpers/helper';
import { MdShare } from 'react-icons/md';
const Order = () => {
  const completeOfferContext = useContext(CompleteOfferContext);
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [confirmationOrder, setConfirmationOrder] = useState([]);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState(false);
  const [openPaidRequestModal, setOpenPaidRequestModal] = useState(false);
  const [confirmationRequest, setConfirmationRequest] = useState(null);
  const [confirmationId, setConfirmationId] = useState(null);
  const [hideActionModal, setHideActionModal] = useState(false);
  const [openRateMediatorModal, setOpenRateMediatorModal] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [cluserMessage, setCluserMessage] = useState('');
  const [closeOrderModal, setCloseOrderModal] = useState(false);
  const [checkAddOffer, setCheckAddOffer] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [openCompleteOfferModal, setOpenCompleteOfferModal] = useState(false);
  const [openChooseCarModal, setOpenChooseCarModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [offers, setOffers] = useState([]);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [selectedCause, setSelectedCause] = useState([]);
  const [causes, setCauses] = useState([t("orderDetails.outOfApp"), t('orderDetails.availabilityOffers'), t("orderDetails.notWantCar"), t("orderDetails.otherCause")]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const getOffersWithRequest = async () => {
    setLoadingOffers(true);
    const response = await getOfferWithRequestForCompleteRequest('', id);
    console.log(response);
    let newOffers = [];
    response.data.offers.forEach((offer) => {
      if (offer.offerStatus == 1) {
        newOffers.push(offer);
      }
    });
    setOffers(newOffers, setLoadingOffers(false));
    //  setOffers(response)
  };

  const getCheckOfferForMediator = async () => {
    const response = await checkOfferForMediator('', id);
    console.log(response);
    setCheckAddOffer(response);

    // setOffers(newOffers);
    //  setOffers(response)
  };

  const confirm = () => {
    setOpenCompleteOfferModal(false);
    setOpenChooseCarModal(true);
  };

  const completeRequestConfirm = () => {
    confirmRequestOffer();
  };

  const goToHome = () => {
    navigate('/');
    // setOpenRateMediatorModal(true)
  };

  const closeOrder = async () => {
    if (cluserMessage == "" && selectedCause.length == 0) {
      toast.error(t('orderDetails.cluserMessageRequired'), toastConfig);
    } else {
      try {
        let data = {
          params: {
            closureMessage: cluserMessage + "    " + selectedCause.join(","),
          },
        };
        const response = await closeOrderCar(data.params, id);
        console.log(response);
        setCloseOrderModal(false, getOrderDetails());
        toast.success(t('orderDetails.messageCloseOrder'), toastConfig);
      } catch (error) {
        toast.error(error.response.data, toastConfig);
      }
    }
  };

  const handleUserInfo = async () => {
    const response = await getUserInfo();

    userRole == 3
      ? setUserInfo(response, getCheckOfferForMediator())
      : setUserInfo(response);
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    getOrderDetails();
  }, []);

  const getOrderDetails = async () => {
    setLoading(true);
    const response = await getOrder('', id);
    setOrder(response.data, getConfirmationStatus());
  };

  const getConfirmationStatus = async () => {
    // setLoading(true)
    const response = await getStatusConfirmationOrder('', id);
    setConfirmationOrder(response.data.items, setLoading(false));
  };

  const removeOrder = async () => {
    try {
      setLoadingConfirm(true);
      const response = await deleteOrder('', id);
      navigate('/orders');
      toast.success(
        t('orderDetails.deleteMessage'),
        toastConfig,
        setLoadingConfirm(false)
      );
    } catch (error) {
      toast.error(error.response.data, toastConfig, setLoadingConfirm(false));
    }
  };

  const openDeleteModalAction = () => {
    setOpenDeleteModal(true);
  };

  const showMessage = (status) => {
    console.log(status)
    if (status == 5) {
      return t('orderDetails.offerCompleteMessage');
    }
    if (status == 3) {
      return t('orderDetails.offerPendingMessage');
    }
    if (status == 2) {
      return t('orderDetails.offerRejectedMessage');
    }
    if (status == 1) {
      return t('orderDetails.offerAcceptedMessage');
    }
    if (status == 4) {
      return t('orderDetails.offerUnderPaymentMessage');
    }
  };

  const handleEditOrder = () => {
    navigate(`/orders/add/${order.id}`, { state: { order } });
  };

  //confirmation offers

  const action = (offer) => {
    setConfirmationId(offer.id);
    if (offer.status == 3) {
      if (userInfo.userId == offer.userId) {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(true, getConfirmationRequestData(offer.id))
        );
      } else {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(false, getConfirmationRequestData(offer.id))
        );
      }
    } else if (offer.status == 1 || offer.status == 4) {
      if (userRole == 2) {
        setOpenPaidRequestModal(true, getConfirmationRequestData(offer.id));
      } else {
        setOpenConfirmRequestModal(
          true,
          setHideActionModal(true, getConfirmationRequestData(offer.id))
        );
      }
    } else if (offer.status == 5) {
      setOpenConfirmRequestModal(
        true,
        setHideActionModal(true, getConfirmationRequestData(offer.id))
      );
    }
  };

  const getConfirmationRequestData = async (confirmationId) => {
    const response = await getConfirmationRequest('', confirmationId);
    response.data.medInfo = response.data.otherUserInfo;
    response.data.medInfo.avgRating = response.data.otherUserInfo.medAvgRating;
    response.data.cars = [];
    response.data.confirmationCars.forEach((car) => {
      response.data.cars.push(car.offerCar);
    });

    setConfirmationRequest(response.data);
  };

  const [valueConfirm, setValueConfirm] = useState('');
  const [valueComment, setValueComment] = useState('');

  const [valueCommentRate, setValueCommentRate] = useState('');
  const [valueStarRate, setValueStarRate] = useState('');
  const [valueRate, setValueRate] = useState('');
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);
  const [openShare, setOpenShare] = useState(false);

  const handleValueConfirm = (value) => {
    setValueConfirm(value);
  };
  const handleValueComment = (value) => {
    setValueComment(value);
  };

  const handleValueCommentRate = (value) => {
    setValueCommentRate(value);
  };
  const handleValueStarRate = (value) => {
    setValueStarRate(value);
  };
  const handleValueRate = (value) => {
    setValueRate(value);
  };
  const showMessageSuccessRateMediator = (value) => {
    setShowMessageSuccess(value);
  };

  const confirmRequest = async () => {
    if (valueConfirm == 2 && !valueComment) {
      toast.error(t('completeRequest.requiredComment'), toastConfig);
    } else {
      let data = {
        confirmationStatus: valueConfirm,
        rejectComment: valueComment,
      };
      try {
        const response = await updateConfirmationRequest(data, confirmationId);
        setOpenConfirmRequestModal(false);
        toast.success(
          t('completeRequest.messageSuccessConfirmed'),
          toastConfig
        );
      } catch (error) {
        if (error.response) {
          if (error.response.status == 400) {
            toast.error(error.response.data.message, toastConfig);
          } else if (error.response.status == 500) {
            toast.error(error.response.data.message, toastConfig);
          } else if (error.response.status == 401) {
            toast.error(error.response.data.message, toastConfig);
          }
        }
      }
    }
  };

  const confirmRequestOffer = async () => {
    setLoading(true);
    let cars = [];
    completeOfferContext.selectedCars.forEach((carId) => {
      cars.push({ offerCarId: carId });
    });
    let data = {
      cars: cars,
    };
    try {
      const response = await confirmationOffer(data);
      setOpenChooseCarModal(false);
      setOpenConfirmModal(true);
      console.log('Success:', response);
      toast.success(t('تم تاكيد الطلب بنجاح'), toastConfig, setLoading(false));
    } catch (error) {
      console.log(error);
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        } else {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        }
      }
    }
  };

  const paidRequest = () => {
    if (confirmationRequest.totalCommission == 0) {
      setOpenRateMediatorModal(true);
    } else {
      paidRequestAction();
    }
  };

  const paidRequestAction = async () => {
    let data = {
      params: {
        confirmationId: confirmationId,
      },
    };
    try {
      const response = await paidConfirmationRequest(data, confirmationId);
      toast.success(t('paidRequest.paySuccess'), toastConfig);
      setOpenPaidRequestModal(false);
      setOpenRateMediatorModal(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const ratedMediator = async () => {
    let data = {
      mediatorId: confirmationRequest.medInfo.id,
      offerId: confirmationRequest.offerRes.id,
      comment:
        valueRate == 1
          ? t('paidRequest.acceptable')
          : valueRate == 2
            ? t('paidRequest.good')
            : valueRate == 3
              ? t('paidRequest.veryGood')
              : t('paidRequest.excellent') + '  : ' + valueCommentRate,
      rating: valueStarRate,
    };
    try {
      const response = await rateMediator(data);
      toast.success(t('paidRequest.ratingSuccess'), toastConfig);
      setShowMessageSuccess(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const addOrderToFavourite = async () => {
    try {
      const response = await addToFavourite('', id);
      toast.success(t('orderDetails.messageSuccessFavourite'), toastConfig);
      getOrderDetails();
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const addOffer = () => {};

  const shareUrl = `https://staging-v2.mshrai.pages.dev/orders/${id}`;
  const title = 'Check out this amazing mshrai site!';

  const handleShare = async () => {
    const shareData = {
      title: 'Share Order',
      text: 'Hey, look at this amazing mshrai site!',
      url: shareUrl,
    };

    if (navigator.share) {
      try {
        await navigator.share(shareData);
        toast.error('Link shared successfully!', toastConfig);
      } catch (err) {
        toast.error('Error sharing the link: ' + err.message, toastConfig);
      }
    } else {
      toast.error(
        'Web Share API is not supported on this device.',
        toastConfig
      );
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        toast.success(t('orderDetails.copyLink'), toastConfig);
      })
      .catch((err) => {
        toast.success('Failed to copy link.', toastConfig);
      });
  };


  const handleChangeCause = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedCause((prevItems) => [...prevItems, name]);
    } else {
      setSelectedCause((prevItems) => prevItems.filter((item) => item !== name));
    }

    console.log(selectedCause)

  };

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        {userRole == 3 && (
          <div
            className="flex flex-row w-[40%] shadow-md items-center p-2 rounded-[28px] bg-[#00CEBC] cursor-pointer"
            onClick={() => navigate(`/profile-info/${order?.buyerInfo?.id}`)}
          >
            <div className="rounded-full p-1 bg-[#73D3CB]">
              {!order?.buyerInfo?.profileImg ? (
                defaultLettersCircle(order?.buyerInfo?.fullName)
              ) : (
                <img
                  className="w-[36px] h-[36px] rounded-full"
                  src={order?.buyerInfo?.profileImg}
                />
              )}
            </div>
            <div className="flex flex-col mx-3">
              <div className="flex flex-row items-center text-[#ffffff] text-[15px] font-700">
                <div>{t('listOffers.buyer')}</div>
                <div className="px-2">:</div>
                <div>{order?.buyerInfo?.fullName}</div>
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-row items-center justify-between my-5">
          <div className="bg-[#3E0292] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
            <div>{t('orderDetails.orderDescription')}</div>
            <div className="px-2">:</div>
            <div>#{order.id}</div>
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center">
              {/* <Button
                dark={true}
                title={t('orderDetails.buttonShare')}
                onClick={() => setOpenShare(true)}
                className="mx-2"
              /> */}
              <Tooltip content={t('orderDetails.buttonShare')} style="dark">
              <div onClick={() => setOpenShare(true)} className="mx-3 cursor-pointer">
                <MdShare className="text-[#3E0292] text-[25px]" />
              </div>
              </Tooltip>
              {userRole == 2 && (
                <>
                  {order.status == 'OPEN' ? (
                    <>
                      {order?.countOfOffers == 0 && (
                        <ButtonIcon
                          dark={true}
                          title={t('orderDetails.edit')}
                          icon={pen}
                          onClick={handleEditOrder}
                        />
                      )}
                      {order?.countOfOffers == 0 ? (
                        <ButtonIcon
                          dark={false}
                          title={t('orderDetails.delete')}
                          className="bg-[#FD2828] border-[#FD2828] mx-2"
                          icon={trash}
                          onClick={openDeleteModalAction}
                        />
                      ) : null}
                      <MConfirmation
                        loading={loadingConfirm}
                        openModal={openDeleteModal}
                        showFooter={true}
                        setOpenModal={setOpenDeleteModal}
                        title={t('orderDetails.messageDelete')}
                        content={
                          <div className="flex justify-center items-center">
                            <img src={confirmDelete} />
                          </div>
                        }
                        confirm={removeOrder}
                      />
                    </>
                  ) : null}
                </>
              )}
              {/* // order.status == "CLOSED" ? (
                //     <div className="flex flex-row items-center justify-around p-2 bg-[#FF00004D] rounded-[7px] mx-5">
                //     <div><img src={timesOffer}/></div>
                //     <div className="text-[#FD2828] text-[11px] font-[600px] mx-3">{t("offerDetails.reject")}</div>
                //   </div>
                // ):order.status == "COMPLETED" ? (
                //     <div className="flex flex-row items-center justify-around p-2 bg-[#00CEBC4D] rounded-[7px] mx-5">
                //     <div><img src={checkOffer}/></div>
                //     <div className="text-[#00CEBC] text-[11px] font-[600px] mx-3">{t("statusActions.complete")}</div>
                //    </div>
                // ):
                null} */}
            </div>
            <div className="flex flex-row items-center rounded-[7px] bg-[#E6EDFF] p-2 mx-5">
              <div className="text-[#3E0292] text-[13px] font-500">
                {t('home.orders.status')}
              </div>
              <div className="text-[#3E0292] text-[13px] font-500 mx-2">:</div>
              <div className="text-[#3E0292] text-[13px] font-500">
                {order.status == 'OPEN'
                  ? t('home.orders.open')
                  : order.status == 'CLOSED'
                    ? t('home.orders.close')
                    : order.status == 'COMPLETED'
                      ? t('home.orders.complete')
                      : t('home.orders.open')}
              </div>
              <div
                className={`${
                  order.status == 'OPEN'
                    ? 'bg-[#04C500CC]'
                    : order.status == 'CLOSED'
                      ? 'bg-[#ff0000CC]'
                      : 'bg-[#3E0292CC]'
                }  w-[10px] h-[10px] rounded-full mx-2`}
              ></div>
            </div>
            <div className="flex flex-row items-center justify-around p-2 bg-[#E6EDFF] rounded-[7px]">
              <div>
                <img src={clock} />
              </div>
              {order?.createdAt ? (
                <div className="text-[#3E0292] mx-3">
                  <span className="font-bold text-[13px]">
                    {convertDate(order?.createdAt)}
                  </span>{' '}
                  <span className="text-[11px] font-[600px]">
                    {order?.createdAt?.split('T')[1]}
                  </span>
                </div>
              ) : null}
            </div>
            <div>
              {userRole == 2 && (
                <div className="relative">
                  {order.status == 'OPEN' ? (
                    <img
                      src={menuDots}
                      onClick={toggleMenu}
                      className="cursor-pointer"
                    />
                  ) : null}
                  {isOpen && (
                    <div
                      className={`absolute ${
                        languageAr ? 'left-0' : 'right-0'
                      } mt-2 w-[100px] z-10 bg-white border border-gray-200 rounded-[12px] shadow-lg`}
                      onMouseLeave={closeMenu}
                    >
                      <ul className="py-2">
                        <li>
                          <div
                            className="flex flex-row items-center justify-around p-3 cursor-pointer"
                            onClick={() =>
                              setOpenCompleteOfferModal(
                                true,
                                getOffersWithRequest()
                              )
                            }
                          >
                            <div>
                              <img src={loadingGreen} />
                            </div>
                            <div className="text-[#00CEDF] text-[15px] font-400">
                              {t('completeRequest.completed')}
                            </div>
                          </div>
                        </li>
                        <li>
                          <div
                            className="flex flex-row items-center justify-around border-t border-t-[#E9E9E9] p-3 cursor-pointer"
                            onClick={() => setCloseOrderModal(true)}
                          >
                            <div>
                              <img src={loadingRed} />
                            </div>
                            <div className="text-[#CE4223] text-[15px] font-400">
                              {t('completeRequest.close')}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        {loading ? (
          <CardSkeleton number={1} height={'500px'} />
        ) : (
          <div className="w-[100%] bg-[#D7E2FF] rounded-[20px] grid grid-cols-[40%_60%] max800:grid-cols-1 p-5">
            <div className="flex flex-col p-2">
              {order?.cars?.length == 0 ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.cars')}:
                  </div>
                  <div
                    className={`grid lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-1 gap-x-2 mx-2 ${
                      order?.cars?.length > 6
                        ? 'h-[200px] overflow-y-scroll'
                        : ''
                    }`}
                  >
                    {order?.cars?.map((car, index) => (
                      <div
                        key={index}
                        className="bg-[#ffffff] rounded-[12px] p-2 flex flex-col items-center mt-2 cursor-pointer"
                      >
                        <div className="flex flex-row items-center">
                          <div>
                            <img
                              src={car.image}
                              className="w-[20px] h-[20px]"
                            />
                          </div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">
                            {languageAr ? car.brand : car.brandEn}
                          </div>
                        </div>
                        <div className="flex flex-row items-center">
                          {car?.models?.map((model, i) => (
                            <div
                              key={i}
                              className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2"
                            >
                              <div>
                                <img
                                  src={carIcon}
                                  className="w-[20px] h-[20px]"
                                />
                              </div>
                              <div className="text-[#3E0292] text-[12px] font-400 px-2">
                                {languageAr
                                  ? model.brandTypes
                                  : model.brandTypesEn}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {/* <div className="flex flex-row items-center my-5">
                        <div className="text-[#3E0292] text-[14px] font-500"> {t("orderDetails.carType")}:</div>
                        <div className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mx-2">
                        <div className="bg-[#ffffff] rounded-[12px] p-3 flex flex-row items-center">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                       </div>
                       </div>
                        
                    </div> */}

              {order?.cities?.length == 0 ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.city')}:
                  </div>
                  <div
                    className={`grid lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-1 gap-x-2 mx-2 ${
                      order?.cities?.length > 6
                        ? 'h-[200px] overflow-y-scroll'
                        : ''
                    }`}
                  >
                    {order?.cities?.map((city, index) => (
                      <div
                        key={index}
                        className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2"
                      >
                        <div>
                          <img src={location} className="w-[20px] h-[20px]" />
                        </div>
                        <div className="text-[#0000000] text-[12px] font-400 px-2">
                          {languageAr ? city.city : city.cityEn}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {order?.odoFrom == null && order?.odoTo == null ? null : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.km')}:
                  </div>
                  <div className="flex flex-row bg-[#E6EDFF] rounded-[12px] p-2 mx-2">
                    <div>
                      <img src={kilometers} className="w-[20px] h-[20px]" />
                    </div>
                    <div className="flex flex-row items-center px-2">
                      <div className="text-[#BBBBBB] text-[10px] font-400">
                        {t('listOrders.from')}
                      </div>
                      <div className="text-[#000000] text-[13px] font-400 px-2">
                        {order.odoFrom}
                      </div>
                      <div className="text-[#BBBBBB] text-[10px] font-400">
                        {t('listOrders.km')}
                      </div>
                      <div className="text-[#000000] text-[10px] font-400 px-2">
                        /
                      </div>
                      <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                        {t('listOrders.to')}
                      </div>
                      <div className="text-[#000000] text-[13px] font-400">
                        {order.odoTo}
                      </div>
                      <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                        {t('listOrders.km')}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {order?.colors?.length == 0 ? null : (
                <div className="flex flex-row items-center">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.color')}:
                  </div>
                  <div
                    className={`grid lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-1 gap-x-2 mx-2 ${
                      order?.colors?.length > 6
                        ? 'h-[200px] overflow-y-scroll'
                        : ''
                    }`}
                  >
                    {order?.colors?.map((color, index) => (
                      <div
                        key={index}
                        className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-2"
                      >
                        <div className="w-[20px] h-[20px]">
                          <CarColor
                            color={
                              color?.hexCode == '#FFFFFF'
                                ? '#808080'
                                : color?.hexCode
                            }
                          />
                        </div>
                        <div
                          style={{
                            color:
                              color?.hexCode == '#FFFFFF'
                                ? '#808080'
                                : color?.hexCode,
                          }}
                          className="text-[12px] font-400 px-2"
                        >
                          {languageAr ? color.nameAr : color.nameEn}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {order?.isOpenBudget ? (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.price')}:
                  </div>
                  <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 mx-2">
                    <div>
                      <img src={moneyWhite} className="w-[20px] h-[20px]" />
                    </div>
                    <div className="flex flex-row items-center px-2">
                      <div className="text-[12px] font-500 text-[#ffffff]">
                        {t('listOrders.price')}
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff] px-2">
                        :
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff]">
                        {t('orderDetails.noLimit')}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {t('orderDetails.price')}:
                  </div>
                  <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 mx-2">
                    <div>
                      <img src={moneyWhite} className="w-[20px] h-[20px]" />
                    </div>
                    <div className="flex flex-row items-center px-2">
                      <div className="text-[12px] font-500 text-[#ffffff]">
                        {t('listOrders.price')}
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff] px-2">
                        :
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff]">
                        {order?.priceFrom}
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff] px-2">
                        /
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff] px-2">
                        {order?.priceTo}
                      </div>
                      <div className="text-[12px] font-500 text-[#ffffff]">
                        {t('listOrders.Rial')}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div
              className={`flex flex-col max800:border-0 border ${
                i18n.language == Lang.ar
                  ? 'border-r-[#ffffff]'
                  : 'border-l-[#ffffff]'
              } p-3`}
            >
              <div className="flex flex-row items-center my-3">
                <div className="text-[#3E0292] text-[14px] font-500">
                  {' '}
                  {t('orderDetails.year')}:
                </div>
                <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 mx-2">
                  <div>
                    <img src={Calendar} className="w-[20px] h-[20px]" />
                  </div>
                  <div className="flex flex-row items-center px-2">
                    <div className="text-[#000000] text-[13px] font-[600px]">
                      {t('statusActions.from')}
                    </div>
                    <div className="text-[#000000] text-[13px] font-[600px] px-2">
                      {order?.yearFrom}
                    </div>
                    <div className="text-[#000000] text-[13px] font-[600px]">
                      {t('statusActions.to')}
                    </div>
                    <div className="text-[#000000] text-[13px] font-[600px] px-2">
                      {order?.yearTo}
                    </div>
                  </div>
                </div>
              </div>
              {!confirmationOrder || confirmationOrder.length == 0 ? null : (
                <div>
                  {confirmationOrder.map((item, index) => (
                    <div
                      onClick={() => {
                        item.status == 2
                          ? console.log('rejected')
                          : action(item);
                      }}
                      key={index}
                      className="grid lg:grid-cols-1 2xl:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 gap-y-2 m-3 cursor-pointer"
                    >
                      <div className="text-[#3E0292] text-[14px] font-500">
                        {t('offerDetails.number')}:{item.id}
                      </div>
                      <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center mt-1">
                        <div>
                          <img src={car} className="w-[20px] h-[20px]" />
                        </div>
                        <div className="text-[#0000000] text-[12px] font-400 px-2">
                          {showMessage(item.status)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {order?.description == '' ? null : (
                <div className="flex flex-row items-center my-3">
                  <div className="text-[#3E0292] text-[14px] font-500">
                    {' '}
                    {t('orderDetails.description')}:
                  </div>
                  <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 w-[80%] mx-2">
                    <div className="text-[#303030] text-[14px] font-400">
                      {order?.description}
                    </div>
                  </div>
                </div>
              )}
              {userRole == 2 && (
                <>
                  {order?.numOfFavs == 0 ? null : (
                    <div className="flex flex-row items-center my-3">
                      <div className="text-[#3E0292] text-[14px] font-500">
                        {' '}
                        {t('orderDetails.numberFavourites')}:
                      </div>
                      <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 w-[80%] mx-2">
                        <div className="text-[#303030] text-[14px] font-400">
                          {order?.numOfFavs}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}

        <div
          className={`grid ${userRole == 3 && checkAddOffer?.isPendingOrCompletedOffer == false ? 'md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3' : checkAddOffer?.isPendingOrCompletedOffer == true ? 'md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2' : 'md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2'} sm:grid-cols-1 w-[50%] mr-auto ml-auto gap-x-2 mt-10`}
        >
          {order.status == 'COMPLETED' ? (
            <Button
              title={t('statusActions.finalOffer')}
              className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
              dark={false}
              onClick={() =>
                navigate(
                  `/orders/${order?.finalCR?.requestId}/offers/${order?.finalCR?.offerId}`
                )
              }
            />
          ) : (
            <>
              {userRole == 2 ? (
                <ButtonBadge
                  disabled={order?.countOfOffers > 0 ? false : true}
                  value={order?.countOfOffers}
                  className="bg-[#00CEBC] border border-[#00CEBC] max600:mb-3 sm:mb-3 xl:mb-0 lg:mb-0 2xl:mb-0 md:mb-0"
                  dark={false}
                  title={t('orderDetails.offers')}
                  onClick={() => navigate(`/orders/${id}/offers`)}
                />
              ) : (
                <>
                  {checkAddOffer?.isPendingOrCompletedOffer == false ? (
                    <>
                      <Button
                        dark={false}
                        className={'bg-[#00CEBC]'}
                        title={t('offerCycle.selectOffer')}
                        onClick={() =>
                          navigate('/saved-offers', {
                            state: {
                              requestId: order?.id,
                              buyerId: order?.buyerInfo?.id,
                            },
                          })
                        }
                      />
                      <Button
                        dark={false}
                        className={'bg-[#00CEBC]'}
                        title={t('offerCycle.newOffer')}
                        onClick={() =>
                          navigate('/offers/create', {
                            state: {
                              requestId: order?.id,
                              buyerId: order?.buyerInfo?.id,
                            },
                          })
                        }
                      />
                    </>
                  ) : (
                    <Button
                      dark={false}
                      className={'bg-[#00CEBC]'}
                      title={t('offerCycle.yourOffer')}
                      onClick={() =>
                        navigate(`/offers/${checkAddOffer.offerId}/update`, {
                          state: {
                            requestId: order?.id,
                            buyerId: order?.buyerInfo?.id,
                          },
                        })
                      }
                    />
                  )}
                </>
              )}
            </>
          )}
          {userRole == 3 ? (
            <>
              {order.isMedFav == true ? (
                <Button
                  dark={false}
                  className={'bg-[#ff0000]'}
                  title={t('orderDetails.removeFromFavourite')}
                  onClick={() => addOrderToFavourite()}
                />
              ) : (
                <Button
                  dark={true}
                  title={t('orderDetails.addToFavourite')}
                  onClick={() => addOrderToFavourite()}
                />
              )}
            </>
          ) : (
            <ButtonIcon
              dark={true}
              title={t('orderDetails.statistics')}
              icon={statisticsOrder}
              onClick={() => navigate(`/orders/${id}/statistics`)}
            />
          )}
        </div>
      </div>
      <MConfirmation
        loading={loading}
        openModal={openConfirmRequestModal}
        showFooter={hideActionModal ? false : true}
        setOpenModal={setOpenConfirmRequestModal}
        title={t('completeRequest.completeRequest')}
        content={
          <ConfirmRequest
            hideActionModal={hideActionModal}
            onValueChangeComment={handleValueComment}
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={confirmRequest}
      />
      <MConfirmation
        loading={loading}
        openModal={openPaidRequestModal}
        setOpenModal={setOpenPaidRequestModal}
        showFooter={true}
        titleButton={
          confirmationRequest?.totalCommission > 0
            ? t('paidRequest.payment')
            : t('paidRequest.rating')
        }
        singleButton={true}
        content={
          <PaidRequest
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={paidRequest}
      />
      <MConfirmation
        loading={loading}
        openModal={openRateMediatorModal}
        showFooter={true}
        setOpenModal={setOpenRateMediatorModal}
        title={t('paidRequest.ratingMediator')}
        content={
          <MediatorRate
            onValueChangeCommentRate={handleValueCommentRate}
            onValueChangeStarRate={handleValueStarRate}
            onValueChangeRate={handleValueRate}
            showMessageSuccess={showMessageSuccess}
            setShowMessageSuccess={showMessageSuccessRateMediator}
          />
        }
        titleButton={t('paidRequest.rating')}
        singleButton={true}
        confirm={ratedMediator}
      />

      {/* complete Order */}
      <MConfirmation
        loading={loading}
        openModal={openCompleteOfferModal}
        showFooter={true}
        setOpenModal={setOpenCompleteOfferModal}
        title={t('completeRequest.chooseMediator')}
        content={
          <ChooseMediator offers={offers} loadingOffers={loadingOffers} />
        }
        confirm={confirm}
        disabled={offers.length > 0 ? false : true}
      />
      <MConfirmation
        loading={loading}
        openModal={openChooseCarModal}
        showFooter={true}
        setOpenModal={setOpenChooseCarModal}
        title={t('completeRequest.completeRequest')}
        content={<CompleteOrder offer={completeOfferContext.offer} />}
        confirm={completeRequestConfirm}
      />
      <MConfirmation
        loading={loading}
        openModal={openConfirmModal}
        showFooter={true}
        setOpenModal={setOpenConfirmModal}
        title={t('completeRequest.successSentToMediator')}
        content={
          <div className="flex justify-center items-center">
            <img src={confirmIcon} />
          </div>
        }
        titleButton={t('completeRequest.backToHome')}
        singleButton={true}
        confirm={goToHome}
      />
      <MConfirmation
        loading={loading}
        openModal={closeOrderModal}
        showFooter={true}
        setOpenModal={setCloseOrderModal}
        title={t('orderDetails.questionCloseOrder')}
        content={
          <div>
            <div>{t("orderDetails.selectCauseClose")}</div>
            <div className="flex flex-col my-5">
            {causes.map((cause) => (
            <div key={cause} className="flex flex-row mt-2">
              <CheckBox
                handleChange={handleChangeCause}
                checked={selectedCause.includes(cause)}
                type="checkbox"
                name={cause}
              />
                <label className="mx-3 text-[#3E0292] text-[13px] font-bold">
                {cause}
              </label>
            </div>
        ))}
          </div>
            <Textarea
              rows={4}
              onChange={(e) => setCluserMessage(e.target.value)}
              value={cluserMessage}
              placeholder={t('orderDetails.writeCause')}
              label={t('orderDetails.cause')}
            />
          </div>
        }
        singleButton={false}
        confirm={closeOrder}
      />

      {/* share model */}
      <MConfirmation
        loading={loading}
        openModal={openShare}
        showFooter={false}
        setOpenModal={setOpenShare}
        title={t('orderDetails.titleShareOrder')}
        content={
          <div className="grid grid-cols-3 gap-3">
            <div className="flex flex-row items-center justify-between">
              <FacebookShareButton url={shareUrl} quote={title}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl} title={title}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <WhatsappShareButton url={shareUrl} title={title}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
            </div>
            <Button
              dark={true}
              title={t('orderDetails.other')}
              onClick={handleShare}
            />
            <Button
              dark={true}
              title={t('orderDetails.copyLinkButton')}
              onClick={handleCopyLink}
            />
          </div>
        }
      />
    </div>
  );
};

export default Order;
