import { useTranslation } from 'react-i18next';
import { filter, plusWhite, searchGreen } from '../../assets/icons';
const InputSearch = ({
  onChange,
  value,
  action,
  add,
  showAdd = false,
  showSearch = false,
  search,
  showFilter = true,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex items-center mx-auto">
      <label for="simple-search" className="sr-only">
        Search
      </label>
      <div className="relative w-full">
        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <img src={searchGreen} />
        </div>
        <input
          type="text"
          id="simple-search"
          className="bg-[#00CEBC4D] border border-[#00CEBC4D] rounded-[39px] p-4 text-[#9E9E9E] text-sm focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 font-400"
          placeholder={t('listOrders.search')}
          value={value}
          onChange={onChange}
        />
      </div>
      {showSearch ? (
        <button
          type="button"
          onClick={search}
          className="p-2.5 ms-2 text-sm font-medium text-white bg-[#3E0292] rounded-[12px] border border-[#3E0292] hover:bg-[#3E0292] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          {/* <img src={search} className="w-[33px]"/> */}
          <span className="font-500">{t('listOrders.search')}</span>
        </button>
      ) : null}
      {showFilter && (
        <button
          type="button"
          onClick={action}
          className="p-2.5 ms-2 text-sm font-medium text-white bg-[#00CEBC4D] rounded-[12px] border border-[#00CEBC4D] hover:bg-[#00CEBC4D] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <img src={filter} />
          <span className="sr-only">Search</span>
        </button>
      )}
      {showAdd ? (
        <button
          type="button"
          onClick={add}
          className="p-2.5 ms-2 text-sm font-medium text-white bg-[#3E0292] rounded-[12px] border border-[#3E0292] hover:bg-[#3E0292] focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          <img src={plusWhite} className="w-[33px]" />
          <span className="sr-only">Add</span>
        </button>
      ) : null}
    </div>
  );
};

export default InputSearch;
