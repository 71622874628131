import { z } from 'zod';
import i18n from 'i18next';

export const profileSchema = () => {
  return z.object({
    fullName: z.string().min(1, i18n.t('profile.fullNameRequired')),
    email: z.string().email(i18n.t('profile.InvalidEmailAddress')), 
    phoneNumber: z.string(),
    nationalId: z.string().length(10, i18n.t('profile.idRequired')),
    description: z.string().optional(),
  });
};
