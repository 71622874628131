import { useTranslation } from 'react-i18next';
import { moneyWhite, star } from '../../../assets/icons';
import { defaultLettersCircle } from '../../../helpers/helper';

const Mediator = ({
  selected = false,
  cars,
  mediatorId,
  handleChange,
  mediator,
  offer,
  from
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`flex flex-col ${cars ? 'bg-[#E0FFFC] mt-5 rounded-[20px] p-5' : 'bg-transparent'} cursor-pointer`}
      onClick={() => {
        cars ? console.log() : handleChange(mediatorId);
      }}
    >
      <div
        className={`${selected ? 'bg-[#7FE7DD] border-8 border-[#00CEBC]' : 'bg-[#DCECFD]'} ${cars ? 'bg-[#E0FFFC] mt-0' : 'bg-[#DCECFD] mt-3'} p-1 flex flex-row items-center justify-around rounded-[20px]`}
      >
        <div className="flex flex-row items-center p-2 m-[10px] rounded-[28px] bg-transparent">
          <div className="rounded-full p-1 bg-[#73D3CB]">
            {!offer?.medInfo?.profileImg ? (
              defaultLettersCircle(
                offer?.medInfo?.fullName,
                'w-[50px]',
                'h-[50px]'
              )
            ) : (
              <img
                src={offer?.medInfo?.profileImg}
                className="w-[50px] h-[50px] rounded-full"
              />
            )}
          </div>
          <div className="flex flex-col mx-3">
            <div className="flex flex-row items-center text-[#3E0292] text-[15px] font-bold">
              <div>{t('listOffers.mediator')}</div>
              <div className="px-2">:</div>
              <div>{offer?.medInfo?.fullName}</div>
            </div>
            <div className="flex flex-row items-center">
              <div>
                <img src={star} />
              </div>
              <div className="text-[#3E0292] text-[15px] font-700">
                {from == "carowner" ? offer?.medInfo?.medAvgRating : offer?.medInfo?.avgRating}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row bg-[#3E0292] rounded-[12px] p-2 mx-2 border-4 border-[#E6EDFF]">
          <div>
            <img src={moneyWhite} />
          </div>
          <div className="flex flex-row items-center px-2">
          <div className="text-[12px] font-500 text-[#ffffff]">
              {t('offerCycle.commission')}
            </div>
            <div className="text-[12px] font-500 text-[#ffffff] px-2">{from != "carowner" ? offer?.cars?.[0]?.commission : offer?.commission}</div>
            <div className="text-[12px] font-500 text-[#ffffff]">
              {t('listOrders.Rial')}
            </div>
          </div>
        </div>
      </div>
      <div>{cars}</div>
    </div>
  );
};

export default Mediator;
