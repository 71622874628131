import Select from 'react-tailwindcss-select';
const Dropdown = ({
  field,
  form,
  value,
  className,
  placeholder,
  options,
  isMultiple,
  ...props
}) => {
  const handleChange = (selectedOptions) => {
    // const selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    // form.setFieldValue(field.name, selectedValues);
    if (isMultiple) {
      const selectedValues = selectedOptions
        ? selectedOptions.map((option) => option.value)
        : [];
      form.setFieldValue(field.name, selectedValues);
    } else {
      // Handle single selection
      console.log(selectedOptions);
      const selectedValue = selectedOptions ? selectedOptions.value : null;
      form.setFieldValue(field.name, selectedValue);
    }
  };

  const selectedValueMultiple = isMultiple
    ? options.filter((option) => (field.value || []).includes(option.value))
    : [];

  // const selectedValue = isMultiple
  // ? options.filter(option => (field.value || []).includes(option.value))
  // : options.find(option => option.value === field.value) || null;

  const selectedValue = isMultiple
    ? // ? options.filter(option => (field.value || []).includes(option.value))
      selectedValueMultiple.length > 0
      ? selectedValueMultiple
      : null
    : options.find((option) => option.value === field.value) || null;

  return (
    <Select
      {...field}
      {...props}
      onChange={handleChange}
      options={options}
      value={selectedValue}
      // value={selectedValue.length > 0 ? selectedValue : null}
      // value={options.filter(option => (field.value || []).includes(option.value))}
      // value={field.value?.length ? field.value : null}
      placeholder={placeholder}
      isMultiple={isMultiple}
      classNames={
        className || {
          menuButton: ({ isDisabled }) =>
            'bg-[#ffffff] flex flex-row items-center h-[100%] rounded-[70px] font-500',
          list: 'h-[150px] overflow-y-scroll',
          searchBox:
            'bg-white pr-6 pb-3 pt-0 w-[100%] border-light border-[1px] leading-8 border-0 rounded-[30px] outline-none',
          tagItemText: 'text-[#3E0292] text-[14px]',
          listItem: ({ isSelected }) =>
            `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
              isSelected
                ? `text-[#37326d] font-semibold bg-blue-500`
                : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
            }`,
        }
      }
    />
  );
};

export default Dropdown;
