import React from 'react';
import LoginIcon from '.././assets/icons/loginIcon.svg';
import { logo, LogoEn } from '../assets/icons';
import './component.css';
import { useTranslation } from 'react-i18next';
const InfoSection = () => {
  const { t, i18n } = useTranslation();
  const isEnglish = i18n.language === 'en';

  return (
    <div className="relative h-screen flex flex-col items-center justify-center">
      <div className="logo-container cursor-pointer">
        <img
          src={isEnglish ? LogoEn : logo}
          alt="mshrai"
          onClick={() => (window.location.href = '/')}
        />
      </div>
      <div className="text-center space-y-4 p-8">
        <img
          src={LoginIcon}
          alt="Car Illustration"
          className="w-full max-w-xs mx-auto"
        />
        <h2 className="text-start font-bold text-md text-[#3E0292]">
          {t('login.label.it_offers')}{' '}
          <img
            src={isEnglish ? LogoEn : logo}
            alt="logo"
            className="inline-block ml-2 mr-2 w-15 h-8 rounded-md"
          />
          {t('login.label.features')}{' '}
        </h2>
        <ul className="space-y-2 text-gray-700">
          <li className="custom-bullet text-start text-[#000000] text-sm">
            {t('register.infoSection.bullet1')}
          </li>
          <li className="custom-bullet text-start  text-[#000000]">
            {t('register.infoSection.bullet2')}
          </li>
          <li className="custom-bullet text-start  text-[#000000]">
            {t('register.infoSection.bullet2')}
          </li>
          <li className="custom-bullet text-start  text-[#000000]">
            {t('register.infoSection.bullet4')}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InfoSection;
