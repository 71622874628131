import React, { createContext, useState } from 'react';

const NotificationContext = createContext();

function NotificationProvider(props) {
  // const[data,setData] = useState({
  //   appNotificationEnabled: false,
  //   emailNotificationEnabled: false,
  //   whatsappNotificationEnabled: false,
  //   whatsappPhoneNumber: "",
  //   emailAddress: ""
  // })

  const [phoneWhatsApp, setPhoneWhatsApp] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [live, setLive] = useState(false);
  const [email, setEmail] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);

  return (
    <NotificationContext.Provider
      value={{
        //  data,
        //  setData
        phoneWhatsApp,
        setPhoneWhatsApp,
        emailAddress,
        setEmailAddress,
        live,
        setLive,
        email,
        setEmail,
        whatsApp,
        setWhatsApp,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

export { NotificationContext, NotificationProvider };
