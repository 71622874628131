import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  arrowUp,
  chat,
  clock,
  clockGreen,
  discountCircle,
  discountShape,
  eye,
  info,
  messages,
  moneyGreen,
  moneysBlue,
  moneysGreen,
  moneysRed,
  opened,
  reject,
  tag,
  saudiUser,
} from '../../../../assets/icons';
import { CardStatistics, CircleCircuit } from '../../../../components';
import { getOrderStatistics } from '../../../../services/apis/order';

const Statistics = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [statistics, setStatistics] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getStatisticsOrder();
  }, []);
  console.log(localStorage.getItem('token'));

  const getStatisticsOrder = async () => {
    setLoading(true);
    const response = await getOrderStatistics('', id);
    console.log(response.data);
    setStatistics(response.data, setLoading(false));
  };
  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        {/* <InputSearch/> */}
        <div className="flex flex-row items-center justify-between my-5">
          <div className="flex flex-row items-center">
            <div className="bg-[#3E0292] p-3 rounded-[10px] text-[#ffffff] text-[14px] font-500 flex flex-row items-center">
              <div>{t('orderStatistics.title')}</div>
              <div className="px-2">:</div>
              <div>#{id}</div>
            </div>
            {/* <div className="bg-[#00CEBC] rounded-[7px] flex flex-row items-center p-1 mx-5">
              <div><img src={info}/></div>
              <div className="text-[#ffffff] flex flex-row items-center text-[12px] font-[600px] mx-3">
              <div>{t("offerDetails.containOffer")}</div>
               <div className="px-2">15</div>
               <div>{t("offerDetails.carsTitle")}</div>
              </div>
            </div> */}
          </div>
          <div className="flex flex-row items-center">
            <div className="flex flex-row items-center justify-around p-2 bg-[#E6EDFF] rounded-[7px] mx-5">
              <div>
                <img src={opened} />
              </div>
              <div className="text-[#04C500] text-[11px] font-[600px] mx-3">
                {t('orderStatistics.open')}
              </div>
            </div>
            <div className="flex flex-row items-center justify-around p-2 bg-[#E6EDFF] rounded-[7px]">
              <div>
                <img src={clock} />
              </div>
              <div className="text-[#3E0292] text-[11px] font-[600px] mx-3">
                {t('orderStatistics.sinceHour')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="grid 2xl:grid-cols-[25%_50%_25%] xl:grid-cols-[25%_50%_25%] lg:grid-cols-[25%_50%_25%] sm:grid-cols-1 max600:grid-cols-1 gap-4 gap-4 w-[90%] mr-auto ml-auto">
        <div className="grid grid-cols-2 gap-x-6 gap-y-6 w-full">
          <CardStatistics
            icon={discountShape}
            subTitle={t('orderStatistics.totalOffers')}
            number={
              statistics?.numOfOffers == null ? 0 : statistics?.numOfOffers
            }
            withSubTitle={true}
          />
          <CardStatistics
            icon={moneysRed}
            subTitle={t('orderStatistics.minPrice')}
            number={
              statistics?.cars?.minPrice == null
                ? 0
                : statistics?.cars?.minPrice
            }
            withSubTitle={true}
          />
          <CardStatistics
            icon={discountCircle}
            subTitle={t('orderStatistics.avgCommission')}
            number={
              statistics?.cars?.avgCommission == null
                ? 0
                : statistics?.cars?.avgCommission
            }
            withSubTitle={true}
          />
          <CardStatistics
            icon={eye}
            number={statistics?.numOfViews == null ? 0 : statistics?.numOfViews}
            title={t('orderStatistics.view')}
            withNumberAndString={true}
          />
          <div className="bg-[#BEDBFA] rounded-[10px] flex flex-row items-center p-5 col-span-2">
            <div>
              <img src={clockGreen} />
            </div>
            <div className="text-[#04C500] text-[14px] font-400 mx-1">
              {t('orderStatistics.medium')}
            </div>
            <div className="text-[#303030] text-[14px] font-400">
              {t('orderStatistics.dateCompleteOrder')}
            </div>
            <div className="text-[#303030] text-[15px] font-400 mx-1">
              {statistics?.avgOfHrsToComplete == null
                ? 0
                : statistics?.avgOfHrsToComplete}
            </div>
            <div className="text-[#303030] text-[10px] font-400">
              {t('orderStatistics.hour')}
            </div>
          </div>
        </div>
        <div>
          <div className="text-center text-[20px] font-500 text-[#000000] mb-10">
            {t('orderStatistics.titleModerators')}
          </div>
          <CircleCircuit statistics={statistics} />
        </div>
        <div className="grid grid-cols-2 gap-x-6 gap-y-6">
          <CardStatistics
            icon={moneysGreen}
            subTitle={t('orderStatistics.avgPrice')}
            number={
              statistics?.cars?.avgPrice == null
                ? 0
                : statistics?.cars?.avgPrice
            }
            withSubTitle={true}
          />
          <CardStatistics
            icon={moneysBlue}
            subTitle={t('orderStatistics.maxPrice')}
            number={
              statistics?.cars?.maxPrice == null
                ? 0
                : statistics?.cars?.maxPrice
            }
            withSubTitle={true}
          />
          <CardStatistics
            icon={tag}
            number={statistics?.numOfFavs == null ? 0 : statistics?.numOfFavs}
            title={t('orderStatistics.save')}
            withNumberAndString={true}
          />
          <CardStatistics
            icon={messages}
            number={
              statistics?.numOfConversations == null
                ? 0
                : statistics?.numOfConversations
            }
            title={t('orderStatistics.chat')}
            withNumberAndString={true}
          />
          {/* <div className="bg-[#BEDBFA] rounded-[10px] flex flex-row justify-between items-center p-5 col-span-2">
                    <div className="flex flex-row items-center">
                    <div><img src={saudiUser}/></div>
                    <div className="text-[#3E0292] text-[14px] font-400 mx-1">12</div>
                    <div className="text-[#3E0292] text-[14px] font-400">{t("orderStatistics.comment")}</div>
                    </div>
                    <div className="flex flex-row items-center">
                    <div><img src={arrowUp}/></div>
                    <div className="text-[#000000] text-[14px] font-400 mx-1">{t("orderStatistics.tellOther")}</div>
                    </div>
                </div> */}
        </div>
      </div>
    </div>
  );
};
export default Statistics;
