import { Spinner } from 'flowbite-react';

const Button = ({
  className,
  title,
  dark,
  onClick,
  type = 'button',
  textColor = '#ffffff',
  loading = false,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      style={{ color: textColor }}
      className={`${
        dark == true
          ? `bg-[#3E0292] border border-[#3E0292] rounded-[14px] text-[15px] text-white px-10 py-3 font-[Noto Sans Arabic] ${className}`
          : className
      } border rounded-[14px] text-[15px] text-white px-10 py-3 font-[Noto Sans Arabic]`}
    >
      <div className="flex flex-row items-center justify-center">
        <div className="font-500">{title}</div>
        {loading ? (
          <Spinner color="success" aria-label="Default status example" />
        ) : null}
      </div>
    </button>
  );
};

export default Button;
