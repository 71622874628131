import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import {
  BMW,
  Calendar,
  car,
  grayCar,
  kilometers,
  location,
  moneyGreen,
  moneyWhite,
  statisticsOrder,
  userMail,
  saudiUser,
  star,
  timesOffer,
  checkOffer,
} from '../../assets/icons';
import { Lang } from '../../helpers/contents';
import {
  convertDate,
  defaultLettersCircle,
  languageAr,
  toastConfig,
  userRole,
} from '../../helpers/helper';
import i18n from '../../languages/i18n';
import { updateOfferStatus } from '../../services/apis/offer';
import ButtonIcon from '../buttons/ButtonIcon';
import { Button } from './../index';
import CarColor from './CarColor';
const MCardSavedOffer = ({ onClick, offer }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loadingUpdateAccept, setLoadingUpdateAccept] = useState(false);
  const [loadingUpdateReject, setLoadingUpdateReject] = useState(false);

  const loader = (type) => {
    let loaderFalse =
      type == 'accept'
        ? setLoadingUpdateAccept(false)
        : setLoadingUpdateReject(false);
    return loaderFalse;
  };

  return (
    <div className="rounded-[20px] bg-[#D7E2FF] cursor-pointer flex flex-col justify-between shadow-lg h-[100%]">
      <div>
        <div className="flex flex-row justify-between items-center">
          <div
            className={`bg-[#3E0292] flex flex-row items-center ${languageAr ? 'rounded-tl-[0px] rounded-tr-[20px] rounded-bl-[20px] rounded-br-[0px]' : 'rounded-tl-[20px] rounded-tr-[0px] rounded-bl-[0px] rounded-br-[20px]'} text-[14px] text-[#ffffff] p-4 h-[50px]`}
          >
            <div>{t('listOffers.offerNumber')}</div>
            <div className="px-2">:</div>
            <div>#{offer.id}</div>
          </div>
        </div>
        <div
          className="grid lg:grid-cols-2 2xl:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 max600:grid-cols-1 gap-x-2 w-[90%] mr-auto ml-auto mt-5"
          onClick={onClick}
        >
          {!offer?.car ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img className="w-[20px] h-[20px]" src={offer?.car?.image} />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr ? offer?.car?.brand : offer?.car?.brandEn}
                </div>
              </div>
            </div>
          )}
          {!offer?.city ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div>
                  <img src={location} className="w-[20px] h-[20px]" />
                </div>
                <div className="text-[#0000000] text-[12px] font-400 px-2">
                  {languageAr ? offer?.city?.city : offer?.city?.cityEn}
                </div>
              </div>
            </div>
          )}
          {!offer?.color ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF]">
              <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                <div className="w-[20px] h-[20px]">
                  <CarColor
                    color={
                      offer?.color?.hexCode == '#FFFFFF'
                        ? '#808080'
                        : offer?.color?.hexCode
                    }
                  />{' '}
                </div>
                <div
                  style={{
                    color:
                      offer?.color?.hexCode == '#FFFFFF'
                        ? '#808080'
                        : offer?.color?.hexCode,
                  }}
                  className="text-[12px] font-400 px-2"
                >
                  {languageAr ? offer?.color?.nameAr : offer?.color?.nameEn}
                </div>
              </div>
            </div>
          )}
          {/* <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">

                       <div className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center h-[100%]">
                          <div><img src={grayCar}/></div>
                          <div className="text-[#0000000] text-[12px] font-400 px-2">14 CC</div>
                      </div>
                      </div> */}
          {!offer?.odo ? null : (
            <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-2">
              <div className="flex flex-row bg-[#ffffff] rounded-[12px] p-2 h-[100%]">
                <div>
                  <img src={kilometers} className="w-[20px] h-[20px]" />
                </div>
                <div className="flex flex-row items-center px-2">
                  <div className="text-[#000000] text-[13px] font-400">
                    {offer?.odo}
                  </div>
                  <div className="text-[#BBBBBB] text-[10px] font-400 px-2">
                    {t('listOrders.km')}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex flex-row items-center justify-center text-[11px] font-700 text-[#3E0292] underline mt-2">
            {t('listOffers.showMore')}
          </div>
        </div>
        <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-5 w-[90%] mr-auto ml-auto">
          <div className="flex flex-row bg-[#00CEBC] rounded-[12px] p-2 ">
            <div>
              <img src={moneyWhite} className="w-[20px] h-[20px]" />
            </div>
            <div className="flex flex-row items-center px-2">
              <div className="text-[12px] font-500 text-[#ffffff]">
                {t('listOffers.price')}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">:</div>
              <div className="text-[12px] font-500 text-[#ffffff]">
                {offer?.price}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">
                {t('listOffers.Rial')}
              </div>
            </div>
            {/* <div className="text-[12px] font-500 text-[#00CEBC] px-2">السعر : 500.125 / 1,000.000 ريال سعودى</div> */}
          </div>
        </div>
        <div className="p-1 rounded-[12px] bg-[#E6EDFF] mt-3 mb-3 w-[90%] mr-auto ml-auto">
          <div className="flex flex-row bg-[#3E0292] rounded-[12px] p-2">
            <div>
              <img src={moneyWhite} />
            </div>
            <div className="flex flex-row items-center px-2">
              <div className="text-[12px] font-500 text-[#ffffff]">
                {t('listOffers.commission')}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">:</div>
              <div className="text-[12px] font-500 text-[#ffffff]">
                {offer?.commission}
              </div>
              <div className="text-[12px] font-500 text-[#ffffff] px-2">
                {t('listOffers.Rial')}
              </div>
            </div>
            {/* <div className="text-[12px] font-500 text-[#00CEBC] px-2">السعر : 500.125 / 1,000.000 ريال سعودى</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MCardSavedOffer;
