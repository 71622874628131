import { useContext, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { arrowLeftOrder } from '../../../assets/icons';
import { FilterOrderContext } from '../../../context/FilterOrderContext';
import { statueses, statuesesOwner, types } from '../../../helpers/helper';
import Button from '../../buttons/Button';
import GInput from '../../inputs/GInput';

const Type = ({ back, from }) => {
  const { t } = useTranslation();
  const filterOrderContext = useContext(FilterOrderContext);
  console.log(filterOrderContext);
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { type: [] },
  });
  const [selectedTypes, setSelectedTypes] = useState([]);

  useEffect(() => {
    setSelectedTypes(filterOrderContext.types);
  }, [filterOrderContext.types]);

  const handleTypeChange = (type) => {
    const currentTypes = getValues('types') || [];

    const updatedTypes = currentTypes.some(
      (selected) => selected.value === type.value
    )
      ? currentTypes.filter((i) => i.value !== type.value)
      : [...currentTypes, type];
    setSelectedTypes(updatedTypes);

    setValue('types', updatedTypes);
    filterOrderContext.setTypes(updatedTypes);
  };

  const onSubmit = (data) => {
    filterOrderContext.setTypes(data.types, back());
  };
  return (
    <div>
      <img
        src={arrowLeftOrder}
        className="my-2 cursor-pointer"
        onClick={back}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <GInput
          title={t('filterModal.selectType')}
          items={types}
          type={'type'}
          selectedItems={selectedTypes}
          handleCheckboxChange={handleTypeChange}
          register={register}
        />
        <div className="grid grid-cols-2 gap-x-2 mt-10">
          <Button
            type="submit"
            dark={false}
            className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]"
            title={t('filterModal.confirm')}
          />
          <Button
            type="submit"
            dark={false}
            className="bg-[#FF4342] border-[#FF4342] rounded-[10px]"
            title={t('filterModal.cancel')}
          />
        </div>
      </form>
    </div>
  );
};

export default Type;
