import { FilterOrderProvider } from '../../../../context/FilterOrderContext';
import List from './List';
const Index = () => {
  return (
    <FilterOrderProvider>
      <List />
    </FilterOrderProvider>
  );
};

export default Index;
