import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CardOrder, InputSearch } from '../../../../components';
import { getFavouriteOrders } from '../../../../services/apis/order';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'flowbite-react';
import { userRole } from '../../../../helpers/helper';

const Favourites = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef(null);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const searchAction = () => {
    fetchOrders(true);
  };

  // if (loading) {
  //     return <div className="grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 gap-y-4 my-5 w-[90%] mr-auto ml-auto">
  //         <CardSkeleton number={3} height={"300px"} />
  //     </div>;
  // }

  // if (!orders || orders.length === 0) {
  //     return <NotFoundOrders/>
  // }

  const fetchOrders = async (reset = false) => {
    setLoading(true);

    try {
      let data = {
        page: currentPage,
        size: 3,
        searchText: searchText,
      };

      const response = await getFavouriteOrders(data);
      console.log(response);

      const newOrders = response.content;
      setOrders((prevOrders) =>
        reset ? newOrders : [...prevOrders, ...newOrders]
      );
      setHasMore(newOrders.length > 0);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    fetchOrders();
  }, [currentPage]);

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          showSearch={true}
          showFilter={false}
          showAdd={false}
          search={() => searchAction()}
        />
        <div
          className={`grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 ${userRole == 3 ? 'gap-y-4' : 'gap-y-16'} my-5`}
        >
          {orders.map((order, index) => (
            <div ref={index === orders.length - 1 ? lastItemRef : null}>
              <CardOrder
                order={order}
                key={order.id}
                goOffers={() => navigate(`/orders/${order.id}/offers`)}
                goStatistics={() => navigate(`/orders/${order.id}/statistics`)}
                onClick={() => navigate(`/orders/${order.id}`)}
              />
            </div>
          ))}
        </div>
        {loading && (
          <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
            <Spinner
              color="success"
              aria-label="Extra large spinner example"
              size="xl"
            />
          </div>
        )}
        {!hasMore && (
          <p className="mt-20 text-center text-[#3E0292]">
            {t('myOrders.loadingAllOrders')}
          </p>
        )}
      </div>
    </div>
  );
};

export default Favourites;
