import { FilterOrderProvider } from '../../../context/FilterOrderContext';
import MediationOrders from './MediationOrders';
const IndexMediation = () => {
  return (
    <FilterOrderProvider>
      <MediationOrders />
    </FilterOrderProvider>
  );
};

export default IndexMediation;
