import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import { Header, Footer } from './layout/index';
import { ToastContainer } from 'react-toastify';
import { routes } from './routers/Routes';
import { mRoutes } from './routers/MRoutes';
import 'react-toastify/dist/ReactToastify.css';
import { FilterProvider } from './context/FilterContext';
import { NotificationProvider } from './context/NotificationContext';
import { UserProvider } from './context/UserContext';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import NotFoundPage from './routers/NotFoundPage';
import { userRole } from './helpers/helper';
import ScrollToTop from './components/shared/ScrollToTop';

function App() {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const title = i18n.language === 'ar' ? 'مشراي' : 'Mshrai';
    document.title = title;
  }, [i18n.language]);

  const noHeaderFooterRoutes = [
    '/login',
    '/signUp',
    '/forgetPass',
    '/verifyOtp',
  ];

  const shouldHideHeaderFooter = noHeaderFooterRoutes.includes(
    location.pathname
  );

  return (
    <div>
      <ScrollToTop />
      {!shouldHideHeaderFooter && (
        <NotificationProvider>
          <Header />
        </NotificationProvider>
      )}
      <div className={shouldHideHeaderFooter ? '' : 'mt-0'}>
        <UserProvider>
          <FilterProvider>
            {userRole == 3 ? (
              <Routes>
                {mRoutes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            ) : (
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            )}
          </FilterProvider>
        </UserProvider>
      </div>
      {!shouldHideHeaderFooter && <Footer />}
      <ToastContainer />
    </div>
  );
}

export default function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}
