import { Spinner } from 'flowbite-react';

const ButtonIcon = ({
  className,
  title,
  dark,
  onClick,
  type = 'button',
  icon,
  titleClass,
  loading = false,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      type={type}
      className={`${dark == true ? `bg-[#3E0292] border border-[#3E0292] rounded-[14px] px-5 py-2` : className} border rounded-[14px] px-5 py-2 flex flex-row items-center justify-center`}
    >
      <div
        className={`px-2 font-[Noto Sans Arabic] ${titleClass ? titleClass : 'text-[15px] text-white'}`}
      >
        <div className="flex flex-row items-center justify-around">
          <div className="flex items-center justify-center">
            <img src={icon} />
          </div>
          <div className="mx-3 font-500">{title}</div>
          {loading ? (
            <Spinner color="success" aria-label="Default status example" />
          ) : null}
        </div>
      </div>
    </button>
  );
};

export default ButtonIcon;
