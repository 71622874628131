import { Formik, Field, Form } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { search } from '../../assets/icons';
import { FilterContext } from '../../context/FilterContext';
import { languageAr, statueses } from '../../helpers/helper';
import { getBrands, getCities, getModels } from '../../services/apis/home';
import { Dropdown } from './../index';
import i18n from './../../languages/i18n';
const Filter = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const filterContext = useContext(FilterContext);
  const [brands, setBrands] = useState([]);
  const [models, setModels] = useState([]);
  const [cities, setCities] = useState([]);
  useEffect(() => {
    getAllBrands();
    getAllModels();
    getAllCities();
  }, []);

  const getAllBrands = async () => {
    const response = await getBrands('');
    let newBrands = [];
    response.forEach((element) => {
      newBrands.push({
        value: element.id,
        label: languageAr ? element.nameAr : element.nameEn,
      });
    });
    setBrands(newBrands, getAllCities());
  };

  const getAllModels = async () => {
    const response = await getModels('', 78);
    let newModels = [];
    response.forEach((element) => {
      newModels.push({
        value: element.id,
        label: languageAr ? element.nameAr : element.nameEn,
      });
    });
    setModels(newModels);
  };

  const getAllCities = async () => {
    const response = await getCities('');
    let newCities = [];
    response.forEach((element) => {
      newCities.push({
        value: element.id,
        label: languageAr ? element.nameAr : element.nameEn,
      });
    });
    setCities(newCities);
  };
  return (
    <div className="bg-[#ffffff] rounded-[70px] max600:rounded-[50px] w-[80%] mr-auto ml-auto shadow-lg p-2">
      <Formik
        initialValues={{ brandIds: [], modelIds: [], cityIds: [], status: [] }}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);

          const updatedValues = {
            ...values,
            brandIds: Array.isArray(values.brandIds)
              ? values.brandIds
              : [values.brandIds],
          };

          filterContext.setData(updatedValues);
          navigate('/orders');
          //  return true
          //  setTimeout(() => {
          //    alert(JSON.stringify(values, null, 2));
          //    setSubmitting(false);
          //  }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          //  <form onSubmit={handleSubmit}>
          <Form>
            <div dir={i18n.languages == 'ar' ? 'ltr' : 'rtl'}>
              <div className="grid 2xl:grid-cols-5 xl:grid-cols-5 lg:grid-cols-5 md:grid-cols-5 sm:grid-cols-1 rounded-[70px] max600:rounded-[50px]">
                <div className="flex justify-start max600:justify-center">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="rounded-full bg-[#3E0292] flex justify-center items-center w-[73px] h-[73px]"
                  >
                    <img src={search} />
                  </button>
                </div>
                {/* <Dropdown placeholder={t("home.filter.status")} options={statueses} handleChange={handleChange} value={values.status}/> */}
                <Field
                  name="status"
                  component={Dropdown}
                  options={statueses}
                  isMultiple={true}
                  placeholder={t('home.filter.status')}
                />
                {/* <Dropdown placeholder={t("home.filter.price")}/> */}
                <Field
                  name="cityIds"
                  component={Dropdown}
                  options={cities}
                  isMultiple={true}
                  placeholder={t('home.filter.city')}
                />
                {/* <Dropdown placeholder={t("home.filter.city")} options={cities}  handleChange={handleChange} value={values.city}/> */}
                {/* <Dropdown placeholder={t("home.filter.model")} options={models} handleChange={handleChange} value={values.model}/> */}
                <Field
                  name="modelIds"
                  component={Dropdown}
                  options={models}
                  isMultiple={true}
                  placeholder={t('home.filter.model')}
                />
                {/* <Dropdown placeholder={t("home.filter.type")} options={brands} handleChange={handleChange} value={values.brand}/> */}
                <Field
                  name="brandIds"
                  component={Dropdown}
                  options={brands}
                  isMultiple={false}
                  placeholder={t('home.filter.type')}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Filter;
