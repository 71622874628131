import {
  contactUs,
  email,
  facebook,
  gmail,
  instagram,
  phone,
  twitter,
  userMail,
} from '../../../assets/icons';
import { Button, Input, ServicesCard, Textarea } from '../../../components';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AddContactUs } from '../../../services/apis/contactUs';
import { useState } from 'react';
import {
  toastConfig,
  validateEmail,
  validateSaudiPhone,
} from '../../../helpers/helper';

const ContactUs = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const addContactUs = async (values) => {
    setLoading(true);

    let data = {
      fullName: values.firstName + '  ' + values.lastName,
      phoneNumber: '966' + values.phone.slice(1),
      email: values.email,
      message: values.description,
      type: 1,
    };
    try {
      const response = await AddContactUs(data);
      console.log('Success:', response);
      toast.success(t('تم ارسال بنجاح'), toastConfig, setLoading(false));
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        } else if (error.response.status == 500) {
          toast.error(
            error.response.data.message,
            toastConfig,
            setLoading(false)
          );
        }
      }
    }
  };
  return (
    <div>
      <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
        <div className="px-16 py-10 max600:order-1">
          <div className="flex justify-center">
            <img src={contactUs} />
          </div>
          <div className="text-[16px] text-[#303030] py-10">
            {t('contactUs.contactWithEmail')}
          </div>
          <div className="flex flex-row items-center justify-around mr-auto ml-auto lg:w-[50%] md:w-[50%] sm:[100%] sm:order-3 lg:order-1 xl:order-1 md:order-1 2xl:order-1">
            <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center">
              <img src={gmail} />
            </div>
            <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center">
              <img src={twitter} />
            </div>
            <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center mx-3">
              <img src={instagram} />
            </div>
            <div className="bg-[#3E0292] w-[35px] h-[35px] rounded-full flex items-center justify-center">
              <img src={facebook} />
            </div>
          </div>
        </div>
        <div className="bg-gradient-to-l from-[#FFFFFF] to-[#D7E2FF] rounded-lg max600:order-2">
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              phone: '',
              description: '',
            }}
            validate={(values) => {
              const errors = {};
              // if (!values.firstName) {
              //   errors.firstName = t("contactUsForm.firstName");
              // }
              // if (!values.lastName) {
              //   errors.lastName = t("contactUsForm.lastName");
              // }
              // if (!values.email) {
              //   errors.email = t("contactUsForm.email");
              // }
              // if (!validateEmail(values.email)) {
              //   errors.email = t("contactUsForm.emailFormat");
              // }
              if (!values.phone) {
                errors.phone = t('contactUsForm.phone');
              }
              if (!validateSaudiPhone(values.phone)) {
                errors.phone = t('contactUsForm.phoneFormat');
              }
              // if (!values.description) {
              //   errors.description = t("contactUsForm.message");
              // }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log(values);
              addContactUs(values);
              // setTimeout(() => {
              //   alert(JSON.stringify(values, null, 2));
              //   setSubmitting(false);
              // }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} className={'px-16 py-10'}>
                <div className="grid 2xl:grid-cols-2 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                  <div className="max600:mt-5 max600:mr-0 mx-2 order-2 max600:order-2">
                    <Input
                      name="lastName"
                      label={t('contactUs.label.lastName')}
                      type={'text'}
                      icon={userMail}
                      placeholder={t('contactUs.placeholder.lastName')}
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    <div className="text-[#ff0000] text-[12px] mt-1">
                      {errors.lastName && touched.lastName && errors.lastName}
                    </div>
                  </div>
                  <div className="max600:order-1 order-1">
                    <Input
                      name="firstName"
                      label={t('contactUs.label.firstName')}
                      type={'text'}
                      icon={userMail}
                      placeholder={t('contactUs.placeholder.firstName')}
                      value={values.firstName}
                      onChange={handleChange}
                    />
                    <div className="text-[#ff0000] text-[12px] mt-1">
                      {errors.firstName &&
                        touched.firstName &&
                        errors.firstName}
                    </div>
                  </div>
                </div>
                <div className="my-5">
                  <Input
                    name="email"
                    label={t('contactUs.label.email')}
                    type={'email'}
                    icon={email}
                    placeholder={t('contactUs.placeholder.email')}
                    value={values.email}
                    onChange={handleChange}
                  />
                  <div className="text-[#ff0000] text-[12px] mt-1">
                    {errors.email && touched.email && errors.email}
                  </div>
                </div>
                <div>
                  <Input
                    name="phone"
                    label={t('contactUs.label.phone')}
                    type={'text'}
                    icon={phone}
                    placeholder={t('contactUs.placeholder.phone')}
                    value={values.phone}
                    onChange={handleChange}
                  />
                  <div className="text-[#ff0000] text-[12px] mt-1">
                    {errors.phone && touched.phone && errors.phone}
                  </div>
                </div>
                <div className="mt-5">
                  <Textarea
                    name="description"
                    placeholder={t('contactUs.label.message')}
                    rows={6}
                    onChange={handleChange}
                    value={values.description}
                  />
                  <div className="text-[#ff0000] text-[12px] mt-1">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </div>
                </div>
                <div className="flex justify-center mt-5">
                  <Button
                    type="submit"
                    title={t('contactUs.send')}
                    loading={loading}
                    dark={false}
                    className="bg-[#00CEBC] border border-[#00CEBC] rounded-[14px] text-[15px] text-white px-10 py-3 w-[50%] shadow-lg text-center"
                    // disabled={isSubmitting}
                  />
                  {/* {t('contactUs.send')}
                  </button> */}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ServicesCard />
    </div>
  );
};

export default ContactUs;
