import { useContext, useState } from 'react';
import { CompleteOfferContext } from '../../context/CompleteOfferContext';
import Mediator from './parts/Mediator';
import { CardSkeleton } from './../index';

const ChooseMediator = ({ offers, loadingOffers,from }) => {
  const completeOfferContext = useContext(CompleteOfferContext);
  const handleChange = (id, offer, mediator) => {
    console.log(id);
    completeOfferContext.setMediatorId(id);
    completeOfferContext.setMediator(mediator);
    completeOfferContext.setOffer(offer);
  };
  return (
    <div>
      {loadingOffers ? (
        <CardSkeleton number={offers.length} height={'100px'} />
      ) : (
        <>
          {offers.map((offer, index) => (
            <Mediator
              key={index}
              offer={offer}
              from={from}
              selected={
                offer.medInfo.id == completeOfferContext.mediatorId
                  ? true
                  : false
              }
              handleChange={() =>
                handleChange(offer.medInfo.id, offer, offer.medInfo)
              }
              mediatorId={completeOfferContext.mediatorId}
              mediator={completeOfferContext.mediator}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default ChooseMediator;
