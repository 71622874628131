import Environment from '../Environment';
import mainService from '../mainService';

export function getOrders(data) {
  return mainService
    .get(Environment.ownerOrders, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getOffers(data, sellMediationId) {
  return mainService
    .get(Environment.offerOwnerOrders + '/' + sellMediationId + '/offers', data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getOfferDetails(data, offerId) {
  return mainService
    .get(Environment.offerOwnerOrders + '/' + offerId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getOrderDetails(data, orderId) {
  return mainService
    .get(Environment.orderOwner + '/' + orderId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function addOrderCar(data) {
  return mainService
    .post(Environment.ownerOrders, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function updateOrderCar(data, orderId) {
  return mainService
    .put(Environment.orderOwner + '/' + orderId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function deleteOrderCar(data, orderId) {
  return mainService
    .delete(Environment.orderOwner + '/' + orderId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function updateStatusOffer(data, offerId) {
  return mainService
    .patch(Environment.offerOwnerOrders + '/' + offerId + '/status', data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getStatusConfirmationOrder(data, orderId) {
  return mainService
    .get(Environment.statusOrderOwner + '/' + orderId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getFilterOrdersOwner(data, queryParams) {
  return mainService
    .post(Environment.getFilterOrdersOwner, data, {
      params: queryParams,
    })
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function addOfferMediator(data) {
  return mainService
    .post(Environment.addOfferMediator, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function editOfferMediator(data,offerId) {
  return mainService
    .put(Environment.addOfferMediator+offerId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function closeOrderCarOwner(data, orderId) {
  return mainService
    .patch(Environment.ownerOrders + orderId + '/status', data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function confirmationCarOwnerOffer(data) {
  return mainService
    .post(Environment.confirmOwnerOffer, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getCarOwnerOfferWithRequestForCompleteRequest(data, requestId) {
  return mainService
    .get(`${Environment.offerOwnerOrders + '/' + requestId + '/offers'}`, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function getConfirmationRequestOwner(data, confirmationId) {
  return mainService
    .get(Environment.getConfirmationRequestCarOwner + '/' + confirmationId, data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export function updateConfirmationRequestOwner(data, confirmationId) {
  return mainService
    .patch(Environment.getConfirmationRequestCarOwner + '/' + confirmationId + "/status", data)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}


export function paidConfirmationRequestOwner(data, confirmationId) {
  return mainService
    .post(
      Environment.getConfirmationRequestCarOwner + '/' + confirmationId + '/pay',
      data
    )
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}


export function addOfferToSaved(data, orderId) {
  return mainService
    .post(
      Environment.orderOwner + '/' + orderId + '/addToSavedOffers',
      data
    )
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}