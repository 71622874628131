import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  chatWhite,
  checkOffer,
  location,
  reject,
  rejected,
  slash,
  star,
  stopUser,
  timesOffer,
  userMail,
} from '../../assets/icons';
import {
  Button,
  ButtonIcon,
  CardSkeleton,
  Comments,
  Input,
  MConfirmation,
} from '../../components';
import {
  defaultLettersCircle,
  languageAr,
  logoutUser,
  toastConfig,
  userRole,
} from '../../helpers/helper';
import {
  blockUser,
  closeAccount,
  getMediators,
  getUserData,
  getUserInfo,
  unBlockUser,
} from '../../services/apis/user';
import { initiateChat } from '../../services/apis/chat';


const Profile = () => {
  const { userId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const [openStopModal, setOpenStopModal] = useState(false);
  const [openUnblockModal, setOpenUnblockModal] = useState(false);
  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [mediators, setMediators] = useState([]);
  const [blocked, setBlocked] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const [reasonClose, setReasonClose] = useState('');
  const handleUserInfo = async () => {
    const response = await getUserInfo();

    setUserInfo(response);
  };

  useEffect(() => {
    handleUserInfo();
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    setLoading(true);
    let data = {
      params: {
        userId: userId,
      },
    };
    const response = await getUserData(data);
    // if(userRole == 3){
    setUserData(response, setLoading(false));
    // }else{
    //   setUserData(response, getAllMediators());
    // }
  };

  const actionStopUser = async () => {
    setLoadingConfirm(true);
    const response = await blockUser('', userId);
    toast.success(
      t('profileInfo.blockMessage'),
      toastConfig,
      setLoadingConfirm(false, setOpenStopModal(false, getUser()))
    );
  };

  const actionUnblockUser = async () => {
    setLoadingConfirm(true);
    const response = await unBlockUser('', userId);
    console.log(response);
    toast.success(
      t('profileInfo.unBlockMessage'),
      toastConfig,
      setLoadingConfirm(false, setOpenUnblockModal(false, getUser()))
    );
  };

  const getAllMediators = async () => {
    const response = await getMediators('');
    response.data.items.forEach((mediator) => {
      if (mediator.mediator.userId == userId) {
        setBlocked(true);
      } else {
        setBlocked(false);
      }
    });
    setMediators(response.data, setLoading(false));
  };

  const deleteAccount = async () => {
    setLoadingConfirm(true);
    if (reasonClose == '') {
      toast.error(
        t('profileInfo.reasonRequired'),
        toastConfig,
        setLoadingConfirm(false, setOpenDeleteAccount(false))
      );
    } else {
      try {
        let data = {
          closingReason: reasonClose,
        };
        const response = await closeAccount(data);
        console.log(response);
        toast.success(
          t('profileInfo.deleteAccountMessage'),
          toastConfig,
          setLoadingConfirm(false, setOpenDeleteAccount(false))
        );
        logoutUser();
        window.location.href = '/';
      } catch (error) {
        toast.error(
          error.response.data,
          toastConfig,
          setLoadingConfirm(false, setOpenDeleteAccount(false))
        );
      }
    }
  };
  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-10">
      <div className="w-[90%] mr-auto ml-auto">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center p-2 m-[10px] rounded-[28px] bg-[#00CEBC] cursor-pointer">
            <div className="rounded-full p-1 bg-[#73D3CB]">
              {userData?.profileImg == null ? (
                defaultLettersCircle(userData?.fullName)
              ) : (
                <img
                  className="w-[36px] h-[36px] rounded-full"
                  src={userData?.profileImg}
                />
              )}
              
            </div>
            <div className="flex flex-col mx-3">
              <div className="flex flex-row items-center text-[#ffffff] text-[15px] font-700">
                <div>
                  {userData?.userRole?.roleId == 3
                    ? t('listOffers.mediator')
                    : t('listOffers.buyer')}
                </div>
                <div className="px-2">:</div>
                <div>{userData?.fullName}</div>
              </div>
              <div className="flex flex-row items-center">
                <div>
                  <img src={star} />
                </div>
                <div className="text-[#ffffff] text-[15px] font-700">
                  {userData?.mediatorRes?.medAvgRating}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            {userData?.userRole?.roleId == 3 && (
              <ButtonIcon
                dark={false}
                className="bg-[#00CEBC] shadow-md"
                title={t('profileInfo.conversation')}
                icon={chatWhite}

                onClick={async () => {
                  let conversationId = offer.conversation;
  
                  // If no conversation exists, create one
                  if (!conversationId) {
                    try {
                      const body = {
                        offerId: offer.offerId,
                        buyerId: userInfo.userId,
                        mediatorId: offer.medInfo?.id,
                      };
                      const response = await initiateChat(body);
                      conversationId = response?.conversationId;
                    } catch (error) {
                      console.error('Error initiating chat:', error);
                      return; // Exit if chat creation fails
                    }
                  }
  
                  const chatData = {
                    selectedOffer: {
                      offerId: offer.offerId,
                      conversationId: conversationId,
                      receiverInfo: {
                        id: offer.medInfo?.id,
                        fullName: offer.medInfo?.fullName,
                        profileImg: offer.medInfo?.profileImg,
                      },
                      lastMessage: null,
                      unreadCount: 0,
                      lastMessageTime: new Date().toISOString(),
                    },
                    autoSelectChat: true,
                    fromOfferDetails: true,
                  };
  
                  navigate(`/chats`, {
                    state: chatData,
                    replace: true,
                  });
                }}
              />
            )}
            {userInfo?.userId == userData?.userId && (
              <ButtonIcon
                dark={false}
                className="bg-[#00CEBC] shadow-md"
                title={t('profileInfo.editProfile')}
                icon={''}
                onClick={() => navigate(`/profile`)}
              />
            )}
            {userData?.userId == userInfo?.userId &&
              userData?.userRole?.roleId == 3 && (
                <ButtonIcon
                  dark={false}
                  className="bg-[#3E0292] shadow-md"
                  title={t('profileInfo.ordersFavourite')}
                  icon={''}
                  onClick={() => navigate('/orders/favourites')}
                />
              )}
            {userInfo?.userId == userData?.userId && (
              <ButtonIcon
                dark={false}
                className="bg-[#ff0000] shadow-md"
                title={t('profileInfo.removeAccount')}
                icon={''}
                onClick={() => setOpenDeleteAccount(true)}
              />
            )}
            {userData?.userRole?.roleId == 3 && (
              <>
                {blocked == false ? (
                  <ButtonIcon
                    dark={false}
                    className="bg-[#ff0000] shadow-md mx-3"
                    title={t('profileInfo.block')}
                    icon={slash}
                    onClick={() => setOpenStopModal(true)}
                  />
                ) : (
                  <ButtonIcon
                    dark={false}
                    className="bg-[#3E0292] shadow-md"
                    title={t('profileInfo.unBlock')}
                    icon={slash}
                    onClick={() => setOpenUnblockModal(true)}
                  />
                )}
              </>
            )}
          </div>
        </div>
        {/* <div className="flex flex-col justify-center items-center">
              <div className="rounded-full">{!userData.profileImg ? defaultLettersCircle(userData?.fullName,"w-[100px]","h-[100px]","text-[25px]") : <img src={userData.profileImg} className="w-[100px] h-[100px] rounded-full"/>}</div>
              <div className="text-[18px] text-[#3E0292] font-700 mt-2">{userData?.fullName}</div>
          </div> */}
        {!userData?.mediatorRes?.medDescription ? null : (
          <>
            {loading ? (
              <CardSkeleton number={1} height={'300px'} />
            ) : (
              <div
                className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto mt-5 p-5"
                style={{ direction: languageAr ? 'rtl' : 'ltr' }}
              >
                <div className="text-[15px] font-700 text-[#3E0292]">
                  {t('profileInfo.aboutMediator')}
                </div>
                <div className="text-[#303030] text-[13px] font-400 mt-3">
                  {userData?.mediatorRes?.medDescription}
                </div>
              </div>
            )}
          </>
        )}

        {userData?.userId == userInfo?.userId &&
          userData?.userRole?.roleId == 2 && (
            <>
              {loading ? (
                <CardSkeleton number={1} height={'300px'} />
              ) : (
                <div
                  className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto mt-5 p-5"
                  style={{ direction: languageAr ? 'rtl' : 'ltr' }}
                >
                  <div className="text-[15px] font-700 text-[#3E0292]">
                    {t('profileInfo.aboutBuyer')}
                  </div>
                  <div className="p-3 mt-5">
                    <div className="flex flex-row items-center">
                      <div className="text-[14px] font-semibold text-[#3E0292]">
                        {t('profile.phone')}
                      </div>
                      <div className="mx-2">:</div>
                      <div className="text-[14px] text-[#3E0292]">
                        {userData.phone}
                      </div>
                    </div>
                    <div className="flex flex-row items-center my-3">
                      <div className="text-[14px] font-semibold text-[#3E0292]">
                        {t('profile.email')}
                      </div>
                      <div className="mx-2">:</div>
                      <div className="text-[14px] text-[#3E0292] mx-3">
                        {userData.email}
                      </div>
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="text-[14px] font-semibold text-[#3E0292]">
                        {t('profile.idNumber')}
                      </div>
                      <div className="mx-2">:</div>
                      <div className="text-[14px] text-[#3E0292] mx-3">
                        {userData.nationalId}
                      </div>
                    </div>
                  </div>
                  {/* <div className="text-[#303030] text-[13px] font-400 mt-3">
                  {userData?.mediatorRes?.medDescription}
                </div> */}
                </div>
              )}
            </>
          )}

        {userData?.userRole?.roleId == 2 &&
          userData?.userId != userInfo.userId && (
            <>
              {userData?.mediatorRes == null && (
                <>
                  {loading ? (
                    <CardSkeleton number={1} height={'300px'} />
                  ) : (
                    <div
                      className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto mt-5 p-5 cursor-pointer"
                      style={{ direction: languageAr ? 'rtl' : 'ltr' }}
                      onClick={() =>
                        navigate('/orders', {
                          state: { userId: userData?.userId },
                        })
                      }
                    >
                      <div className="flex flex-row items-center">
                        <div className="text-[15px] font-400 text-[#444444] underline">
                          {t('profileInfo.countRequests')}
                        </div>
                        <div className="text-[15px] font-700 text-[#3E0292] mx-5">
                          {userData?.buyerRes?.countOfOpenRequests}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}

        {!userData?.mediatorRes?.medInterests ||
        userData?.mediatorRes?.medInterests?.interestsRes.length == 0 ? null : (
          <>
            {loading ? (
              <CardSkeleton number={1} height={'300px'} />
            ) : (
              <div
                className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto mt-5 p-5"
                style={{ direction: languageAr ? 'rtl' : 'ltr' }}
              >
                <div className="text-[15px] font-700 text-[#3E0292]">
                  {t('profileInfo.interests')}
                </div>
                <div className="grid lg:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-2 gap-x-2 gap-y-2 mx-2 mt-3">
                  {userData?.mediatorRes?.medInterests?.interestsRes.map(
                    (item, index) => (
                      <div
                        key={index}
                        className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                      >
                        <div>
                          <img
                            src={item.brand.image}
                            className="w-[20px] h-[20px]"
                          />
                        </div>
                        <div className="text-[#0000000] text-[12px] font-400 px-2">
                          {languageAr
                            ? item?.brand?.nameAr
                            : item?.brand?.nameEn}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
        {userData?.userRole?.roleId == 3 && (
          <>
            {userData?.mediatorRes?.medServiceArea?.citiesRes.length ==
            0 ? null : (
              <>
                {loading ? (
                  <CardSkeleton number={1} height={'300px'} />
                ) : (
                  <div
                    className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto mt-5 p-5"
                    style={{ direction: languageAr ? 'rtl' : 'ltr' }}
                  >
                    <div className="text-[15px] font-700 text-[#3E0292]">
                      {t('profileInfo.cities')}
                    </div>
                    <div className="grid lg:grid-cols-4 2xl:grid-cols-4 xl:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-2 gap-x-2 gap-y-2 mx-2 mt-3">
                      {userData?.mediatorRes?.medServiceArea?.citiesRes.map(
                        (item, index) => (
                          <div
                            key={index}
                            className="bg-[#ffffff] rounded-[12px] p-2 flex flex-row items-center"
                          >
                            {/* <div><img src={item.brand.image} className="w-[20px] h-[20px]"/></div> */}
                            <div>
                              <img
                                src={location}
                                className="w-[20px] h-[20px]"
                              />
                            </div>
                            <div className="text-[#0000000] text-[12px] font-400 px-2">
                              {languageAr ? item?.nameAr : item?.nameEn}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        {userData?.userRole?.roleId == 3 && (
          <>
            {loading ? (
              <CardSkeleton number={1} height={'300px'} />
            ) : (
              <div
                className="w-[100%] bg-[#D7E2FF] rounded-[20px] mr-auto ml-auto mt-5"
                style={{ direction: languageAr ? 'rtl' : 'ltr' }}
              >
                <Comments mediator={userData?.mediatorRes} />
              </div>
            )}
          </>
        )}
      </div>

      <MConfirmation
        loading={loadingConfirm}
        openModal={openStopModal}
        showFooter={true}
        setOpenModal={setOpenStopModal}
        title={t('profileInfo.questionBlock')}
        content={
          <div className="flex justify-center items-center">
            <img src={stopUser} />
          </div>
        }
        confirm={actionStopUser}
      />
      <MConfirmation
        loading={loadingConfirm}
        openModal={openUnblockModal}
        showFooter={true}
        setOpenModal={setOpenUnblockModal}
        title={t('profileInfo.questionUnblock')}
        content={
          <div className="flex justify-center items-center">
            <img src={stopUser} />
          </div>
        }
        confirm={actionUnblockUser}
      />
      <MConfirmation
        loading={loadingConfirm}
        openModal={openDeleteAccount}
        showFooter={true}
        setOpenModal={setOpenDeleteAccount}
        title={t('profileInfo.questionDeleteAccount')}
        content={
          <div className="flex flex-col">
            <div className="text-[#444444] font-500 text-[15px]">
              {t('profileInfo.infoDeleteAccount')}
            </div>
            <div className="text-blue-300 text-[15px] font-400 underline my-5">
              {t('profileInfo.howRetriveAccount')}
            </div>
            <Input
              label={t('profileInfo.reasonCancel')}
              placeholder={t('profileInfo.writeReasonCancel')}
              onChange={(e) => setReasonClose(e.target.value)}
              value={reasonClose}
            />
          </div>
        }
        confirm={deleteAccount}
      />
    </div>
  );
};

export default Profile;
