import GInput from '../../inputs/GInput';
import { arrowLeftOrder } from '../../../assets/icons';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import Button from '../../buttons/Button';
import { FilterOrderContext } from '../../../context/FilterOrderContext';
import { getCities } from '../../../services/apis/home';
import { languageAr } from '../../../helpers/helper';
import { useTranslation } from 'react-i18next';
import { getColors } from '../../../services/apis/offer';
const items = ['مكة', 'المدينة'];
const Color = ({ back }) => {
  const { t } = useTranslation();
  const filterOrderContext = useContext(FilterOrderContext);
  const { register, handleSubmit, setValue, getValues } = useForm({
    defaultValues: { colorIds: [] },
  });

  const [selectedColors, setSelectedColors] = useState([]);
  const [colors, setColors] = useState([]);

  const handleColorChange = (color) => {
    const currentColors = getValues('colors') || [];

    const updatedColors = currentColors.some(
      (selected) => selected.value === color.value
    )
      ? currentColors.filter((i) => i.value !== color.value)
      : [...currentColors, color];

      
  const isColor13Selected = updatedColors.some(c => c.value === 13);

  
  const updatedCheckboxes = updatedColors.map(c => {
    if (isColor13Selected && c.value !== 13) {
      return { ...c, disabled: true }; 
    }
    return { ...c, disabled: false };
  });
    setSelectedColors(updatedColors);
    filterOrderContext.setColors(updatedColors);

    setValue('colors', updatedColors);
  };

  useEffect(() => {
    getAllColors();
  }, []);

  useEffect(() => {
    setSelectedColors(filterOrderContext.colors);
  }, [filterOrderContext.colors]);

  const getAllColors = async () => {
    const response = await getColors('');
    let newColors = [];
    response?.data?.items?.forEach((element) => {
      newColors.push({
        value: element.id,
        label: languageAr ? element.nameAr : element.nameEn,
      });
    });
    setColors(newColors);
  };

  const onSubmit = (data) => {
    filterOrderContext.setColors(data.colors, back());
  };
  return (
    <div>
      <img
        src={arrowLeftOrder}
        className="my-2 cursor-pointer"
        onClick={back}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="p-4">
        <GInput
          title={t('filterModal.selectColor')}
          items={colors}
          type={'colorIds'}
          selectedItems={selectedColors}
          handleCheckboxChange={handleColorChange}
          register={register}
        />
        <div className="grid grid-cols-2 gap-x-2 mt-10">
          <Button
            type="submit"
            dark={false}
            className="bg-[#00CEBC] border-[#00CEBC] rounded-[10px]"
            title={t('filterModal.confirm')}
          />
          <Button
            type="submit"
            dark={false}
            className="bg-[#FF4342] border-[#FF4342] rounded-[10px]"
            title={t('filterModal.cancel')}
          />
        </div>
      </form>
    </div>
  );
};

export default Color;
