import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mshraiLogoM } from '../../../assets/icons';
import { getNotifications } from '../../../services/apis/notification';
import { handleNotificationAction } from '../../../utils/notificationUtils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { languageAr, toastConfig } from '../../../helpers/helper';
import {
  ConfirmRequest,
  MConfirmation,
  MediatorRate,
  PaidRequest,
} from '../../../components';
import {
  getConfirmationRequest,
  paidConfirmationRequest,
  rateMediator,
  updateConfirmationRequest,
} from '../../../services/apis/order';
import { toast } from 'react-toastify';

const List = () => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totalElements, setTotalElements] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [confirmationRequest, setConfirmationRequest] = useState(null);
  const [confirmationId, setConfirmationId] = useState(null);
  const [openConfirmRequestModal, setOpenConfirmRequestModal] = useState(false);
  const [openPaidRequestModal, setOpenPaidRequestModal] = useState(false);
  const [hideActionModal, setHideActionModal] = useState(false);
  const [openRateMediatorModal, setOpenRateMediatorModal] = useState(false);
  const [valueConfirm, setValueConfirm] = useState('');
  const [valueComment, setValueComment] = useState('');
  const [valueCommentRate, setValueCommentRate] = useState('');
  const [valueStarRate, setValueStarRate] = useState('');
  const [valueRate, setValueRate] = useState('');
  const [showMessageSuccess, setShowMessageSuccess] = useState(false);

  useEffect(() => {
    const initializeNotifications = async () => {
      setLoading(true);
      setNotifications([]);
      setCurrentPage(0);
      await getAllNotifications();
    };
    
    initializeNotifications();
  }, []);

  const getAllNotifications = async () => {
    try {
      let data = { params: { page: currentPage, size: 6 } };
      const response = await getNotifications(data);
      
      const newNotifications = response.data.page.content;
      
      setNotifications(prev => [...prev, ...newNotifications]);
      setTotalPages(response.data.page.totalPages);
      setTotalElements(response.data.page.totalElements);
      setHasMore(currentPage + 1 < response.data.page.totalPages);
      setCurrentPage(prev => prev + 1);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setLoading(false);
    }
  };


  const navigate = useNavigate();
  const userRole = localStorage.getItem('userRole');
  const [userId, setUserId] = useState(localStorage.getItem('userId'));

  useEffect(() => {
    if (!userId) {
      setUserId(localStorage.getItem('userId'));
    }
  }, []);

  const action = (notification) => {
    handleNotificationAction({
      notification,
      navigate,
      userRole,
      userId,
      callbacks: {
        onSetConfirmationId: setConfirmationId,
        onSetOpenConfirmRequestModal: setOpenConfirmRequestModal,
        onSetHideActionModal: setHideActionModal,
        onSetOpenPaidRequestModal: setOpenPaidRequestModal,
        onGetConfirmationRequestData: getConfirmationRequestData
      }
    });
  };

  const getConfirmationRequestData = async (confirmationId) => {
    const response = await getConfirmationRequest('', confirmationId);
    console.log(response);
    response.data.medInfo = response.data.otherUserInfo;
    response.data.medInfo.avgRating = response.data.otherUserInfo.medAvgRating;
    response.data.cars = [];
    response.data.confirmationCars.forEach((car) => {
      response.data.cars.push(car.offerCar);
    });

    setConfirmationRequest(response.data);
  };

 

  const handleValueConfirm = (value) => {
    setValueConfirm(value);
  };
  const handleValueComment = (value) => {
    setValueComment(value);
  };

  const handleValueCommentRate = (value) => {
    setValueCommentRate(value);
  };
  const handleValueStarRate = (value) => {
    setValueStarRate(value);
  };
  const handleValueRate = (value) => {
    setValueRate(value);
  };
  const showMessageSuccessRateMediator = (value) => {
    setShowMessageSuccess(value);
  };

  const confirmRequest = async () => {
    if (valueConfirm == 2 && !valueComment) {
      toast.error(t('completeRequest.requiredComment'), toastConfig);
    } else {
      let data = {
        confirmationStatus: valueConfirm,
        rejectComment: valueComment,
      };
      try {
        const response = await updateConfirmationRequest(data, confirmationId);
        setOpenConfirmRequestModal(false);
        console.log('Success:', response);
        toast.success(
          t('completeRequest.messageSuccessConfirmed'),
          toastConfig
        );
      } catch (error) {
        if (error.response) {
          if (error.response.status == 400) {
            toast.error(error.response.data.message, toastConfig);
          } else if (error.response.status == 500) {
            toast.error(error.response.data.message, toastConfig);
          } else if (error.response.status == 401) {
            toast.error(error.response.data.message, toastConfig);
          }
        }
      }
    }
  };

  const paidRequest = () => {
    if (confirmationRequest.totalCommission == 0) {
      setOpenRateMediatorModal(true);
    } else {
      paidRequestAction();
    }
  };

  const paidRequestAction = async () => {
    let data = {
      params: {
        confirmationId: confirmationId,
      },
    };
    try {
      const response = await paidConfirmationRequest(data, confirmationId);
      console.log('Success:', response);

      toast.success(t('paidRequest.paySuccess'), toastConfig);
      setOpenPaidRequestModal(false);
      setOpenRateMediatorModal(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };

  const ratedMediator = async () => {
    let data = {
      mediatorId: confirmationRequest.medInfo.id,
      offerId: confirmationRequest.offerRes.id,
      comment:
        valueRate == 1
          ? t('paidRequest.acceptable')
          : valueRate == 2
            ? t('paidRequest.good')
            : valueRate == 3
              ? t('paidRequest.veryGood')
              : t('paidRequest.excellent') + '  : ' + valueCommentRate,
      rating: valueStarRate,
    };
    try {
      const response = await rateMediator(data);
      console.log('Success:', response);

      toast.success(t('paidRequest.ratingSuccess'), toastConfig);
      setShowMessageSuccess(true);
    } catch (error) {
      if (error.response) {
        if (error.response.status == 400) {
          toast.error(error.response.data.message, toastConfig);
        } else if (error.response.status == 500) {
          toast.error(error.response.data.message, toastConfig);
        }
      }
    }
  };


  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF]">
      <div className="w-[90%] mr-auto ml-auto">
        <div className="text-[30px] text-[#3E0292] text-center">
          {t('notifications.title')}
        </div>
        <InfiniteScroll
          dataLength={notifications.length}
          next={getAllNotifications}
          hasMore={hasMore}
          loader={
            <div className="flex justify-center bg-transparent h-[80px] items-center">
              <Spinner
                color="success"
                aria-label="Extra large spinner example"
                size="xl"
              />
            </div>
          }
          endMessage={
            <p className="my-10 text-center">{t('notifications.showAll')}</p>
          }
        >
          <ul className="space-y-2 p-5 relative">
            {notifications.map((notification, index) => (
              <li
                key={notification.id}
                className="bg-blue-200 p-2 rounded-lg flex items-center justify-between cursor-pointer mt-3"
                onClick={() => action(notification)}
              >
                <div className="flex flex-row">
                  <div>
                    <img
                      src={mshraiLogoM}
                      className="w-[50px] h-[50px] rounded-full"
                    />
                  </div>
                  <div className="mx-3">
                    <div className="text-[#000000] text-[13px] font-400">
                      {languageAr
                        ? notification.messageAr
                        : notification.messageEn}
                    </div>
                    <div className="text-[10px] text-[#7B7B7B] font-500">
                      {notification.createdAt}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </InfiniteScroll>
      </div>
      <MConfirmation
        loading={loading}
        openModal={openConfirmRequestModal}
        showFooter={hideActionModal ? false : true}
        setOpenModal={setOpenConfirmRequestModal}
        title={t('completeRequest.completeRequest')}
        content={
          <ConfirmRequest
            hideActionModal={hideActionModal}
            onValueChangeComment={handleValueComment}
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={confirmRequest}
      />
      <MConfirmation
        loading={loading}
        openModal={openPaidRequestModal}
        setOpenModal={setOpenPaidRequestModal}
        showFooter={true}
        titleButton={
          confirmationRequest?.totalCommission > 0
            ? t('paidRequest.payment')
            : t('paidRequest.rating')
        }
        singleButton={true}
        content={
          <PaidRequest
            onValueChange={handleValueConfirm}
            offer={confirmationRequest}
          />
        }
        confirm={paidRequest}
      />
      <MConfirmation
        loading={loading}
        openModal={openRateMediatorModal}
        showFooter={true}
        setOpenModal={setOpenRateMediatorModal}
        title={t('paidRequest.ratingMediator')}
        content={
          <MediatorRate
            onValueChangeCommentRate={handleValueCommentRate}
            onValueChangeStarRate={handleValueStarRate}
            onValueChangeRate={handleValueRate}
            showMessageSuccess={showMessageSuccess}
            setShowMessageSuccess={showMessageSuccessRateMediator}
          />
        }
        titleButton={t('paidRequest.rating')}
        singleButton={true}
        confirm={ratedMediator}
      />
    </div>
  );
};

export default List;
