import axios from 'axios';

const ApiRequest = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

ApiRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.response.status);
    if (error.response.status === 401) {
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default ApiRequest;
