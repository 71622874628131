import { Pagination } from 'flowbite-react';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CardOrder,
  InputSearch,
  NotFoundOrders,
  CardSkeleton,
  Button,
  ButtonIcon,
  MConfirmation,
  FilterOrder,
} from '../../../../components';
import { FilterContext } from '../../../../context/FilterContext';
import {
  getBuyOrders,
  getFilterOrders,
  getOrders,
} from '../../../../services/apis/order';
import Environment from '../../../../services/Environment';
import { get } from '../../../../services/Request';
import { themePagination } from '../../../../helpers/contents';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'flowbite-react';
import { plusWhite } from './../../../../assets/icons/index';
import {
  FilterOrderContext,
  FilterOrderProvider,
} from '../../../../context/FilterOrderContext';
import { userRole } from '../../../../helpers/helper';
import CompleteProfileModal from '../../../../components/modals/CompleteProfileModal';
import { userToken } from '../../../../helpers/helper';
import { UserContext } from '../../../../context/UserContext';
import { getUserData } from '../../../../services/apis/user';

const List = () => {
  const filterContext = useContext(FilterContext);
  const filterOrderContext = useContext(FilterOrderContext);
  const [userInfo, setUserInfo] = useState(null);

  const { userData, setUserData } = useContext(UserContext);

  useEffect(() => {
    const getUser = async () => {
      const response = await getUserData();
      setUserInfo(response);
      setUserData(response);
    };

    getUser();
  }, []);

  let filterContextCondition =
    filterContext?.data?.brandIds?.length == 0 &&
    filterContext?.data?.modelIds?.length == 0 &&
    filterContext?.data?.status?.length == 0 &&
    filterContext?.data?.cityIds?.length == 0;
  let filterOrderContextCondition =
    filterOrderContext?.brands?.length == 0 &&
    filterOrderContext?.models?.length == 0 &&
    filterOrderContext?.statuses?.length == 0 &&
    filterOrderContext?.cities?.length == 0 &&
    filterOrderContext?.types?.length == 0 &&
    !filterOrderContext?.priceFrom &&
    !filterOrderContext?.priceTo;
  const { t } = useTranslation();
  const location = useLocation();
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([
    { value: '', label: t('offerCycle.all') },
    { value: 'today', label: t('offerCycle.today') },
    { value: 'yesterday', label: t('offerCycle.yestarday') },
    { value: 'this_week', label: t('offerCycle.thisWeek') },
    { value: 'this_month', label: t('offerCycle.thisMonth') },
    { value: 'old_months', label: t('offerCycle.lastMonths') },
  ]);
  const [posts, setPosts] = useState([]);
  const [dateOption, setDateOption] = useState(location?.state?.dateOption);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [totalElements, setTotalElements] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);
  const observerRef = useRef(null);
  const prevFilter = useRef(filterOrderContext);
  const prevFilterHome = useRef(filterContext);
  const [pendingNavigation, setPendingNavigation] = useState('');

  const hasFilterChanged = useCallback(() => {
    return (
      JSON.stringify(prevFilter.current) !== JSON.stringify(filterOrderContext)
    );
  }, [filterOrderContext]);

  const hasFilterHomeChanged = useCallback(() => {
    return (
      JSON.stringify(prevFilterHome.current) !== JSON.stringify(filterContext)
    );
  }, [filterContext]);

  const navigate = useNavigate();

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const searchAction = () => {
    getBuyRequests(currentPage === 0);
  };

  const fetchOrders = async (reset = false) => {
    setLoading(true);
    if (location?.state?.status) {
      console.log(location?.state?.status);
      filterContext.data.status[0] = location?.state?.status;
    }
    try {
      let body = {};
      if (location?.state || !filterContextCondition) {
        body = filterContext.data;
      } else if (!filterOrderContextCondition) {
        body = {
          brandsId: filterOrderContext.brands.map((brand) => brand.value),
          modelIds: filterOrderContext.models.map((model) => model.value),
          status: filterOrderContext.statuses.map((status) => status.value),
          cityIds: filterOrderContext.cities.map((city) => city.value),
          priceFrom: filterOrderContext.priceFrom,
          priceTo: filterOrderContext.priceTo,
          communicationIds:filterOrderContext.types.map((type) => type.value)
        };
      } else {
        body = {
          brandsId: [],
          modelIds: [],
          status: [],
          cityIds: [],
          priceFrom: null,
          priceTo: null,
          communicationIds:[]
        };
      }

      let data = {
        params: {
          page: currentPage,
          size: 3,
        },
        body: body,
      };

      if (location?.state?.userId) {
        data.body.buyerId = location?.state?.userId;
      }

      const response = await getFilterOrders(data.body, data.params);

      const newOrders = response.data.page.content;
      setOrders((prevPosts) =>
        reset ? newOrders : [...prevPosts, ...newOrders]
      );
      setHasMore(newOrders.length > 0);
      setOpenFilter(false);
      prevFilter.current = filterOrderContext;
      prevFilterHome.current = filterContext;
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const getBuyRequests = async (reset = false) => {
    setLoading(true);
    try {
      let data = {
        params: {
          page: reset ? 0 : currentPage,
          size: 3,
          searchText: searchText,
          dateOption: location?.state?.dateOption
            ? location?.state?.dateOption
            : dateOption,
        },
      };

      if (location?.state?.filter) {
        data.params.filter = location?.state?.filter;
      }

      const response = await getBuyOrders(data);

      const newOrders = response.data.page.content;
      setOrders((prevPosts) =>
        reset ? newOrders : [...prevPosts, ...newOrders]
      );
      setHasMore(newOrders.length > 0);
      // setOpenFilter(false)
      // prevFilter.current = filterOrderContext;
      // prevFilterHome.current = filterContext;
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setLoading(false);
    }
  };

  const lastItemRef = useCallback(
    (node) => {
      if (loading) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (
      userRole == 2 &&
      (!location?.state?.dateOption ||
        !location?.state?.filter ||
        !dateOption ||
        !searchText)
    ) {
      if (
        location?.state?.userId ||
        filterContext.data ||
        filterOrderContext.brands.length != 0 ||
        filterOrderContext.brands.length != 0 ||
        filterOrderContext.statuses.length != 0 ||
        filterOrderContext.cities.length != 0 ||
        filterOrderContext.priceFrom ||
        filterOrderContext.priceTo ||
        filterOrderContext.types.length != 0 ||
        location?.state?.status
      ) {
        if (hasFilterChanged()) {
          fetchOrders(true);
        } else if (hasFilterHomeChanged()) {
          fetchOrders(true);
        } else if (hasMore && !loading) {
          fetchOrders();
        }
      }
    }
    if (
      userRole == 3 &&
      (location?.state?.dateOption ||
        location?.state?.filter ||
        dateOption ||
        searchText)
    ) {
      getBuyRequests(currentPage === 0);
    } else {
      if (
        location?.state?.userId ||
        filterContext.data ||
        filterOrderContext.brands.length != 0 ||
        filterOrderContext.brands.length != 0 ||
        filterOrderContext.statuses.length != 0 ||
        filterOrderContext.cities.length != 0 ||
        filterOrderContext.priceFrom ||
        filterOrderContext.priceTo ||
        filterOrderContext.types.length != 0 ||
        location?.state?.status
      ) {
        if (hasFilterChanged()) {
          fetchOrders(true);
        } else if (hasFilterHomeChanged()) {
          fetchOrders(true);
        } else if (hasMore && !loading) {
          fetchOrders();
        }
      }
    }
  }, [currentPage, dateOption ? dateOption : null]);

  const handleDateOptionChange = (value) => {
    setDateOption(value);
    setCurrentPage(0);
    setOrders([]);
  };

  return (
    <div className="bg-gradient-to-b from-[#FFFFFF] to-[#D7E2FF] p-5">
      <div className="w-[90%] mr-auto ml-auto">
        <div className='relative'>
        <InputSearch
          onChange={handleSearch}
          value={searchText}
          action={() => setOpenFilter(!openFilter)}
          showAdd={userRole == 3 ? false : true}
          showSearch={true}
          showFilter={true}
          add={() => {
            if (!userData?.fullName) {
              setShowProfileModal(true);
              setPendingNavigation('/orders/add');
            } else {
              navigate('/orders/add');
            }
          }}
          search={() => searchAction()}
        />
        {openFilter && (
        <div className='absolute top-15 left-0 bg-white p-3 rounded-[12px] shadow-lg w-[400px] max600:w-full'>
          <div className='p-3 text-center text-[15px] text-[#3E0292] font-bold'>{t('filterModal.filterWith')}</div>
            <FilterOrder
              from="normal"
              close={() => setOpenFilter(false)}
              confirm={fetchOrders}
              hideStatus={userRole == 3 ? true : false}
              hideType={userRole == 3 ? false : true}
            />
        </div>
        )}
        </div>
        {userRole == 3 && (
          <div className="flex flex-row items-center mt-3">
            <div className="text-[#3E0292] text-[15px] font-500">
              {t('myOrders.sortBy')}
            </div>
            <div className="grid 2xl:grid-cols-6 xl:grid-cols-6 lg:grid-cols-5 md:grid-cols-3 sm:grid-cols-2 max600:grid-cols-1  gap-2 mx-3">
              {dates.map((date, index) => (
                <div
                  key={index}
                  className={`${
                    dateOption == date.value
                      ? 'bg-[#3E0292] text-white border border-[#3E0292]'
                      : 'bg-[#E6EDFF] text-[#3E0292]'
                  } rounded-[12px] p-2 text-center font-500 text-[15px] cursor-pointer shadow-md`}
                  onClick={() => handleDateOptionChange(date.value)}
                >
                  {date.label}
                </div>
              ))}
            </div>
          </div>
        )}

        <div
          className={`grid md:grid-cols-2 2xl:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 sm:grid-cols-1 max600:grid-cols-1 gap-x-4 ${
            userRole == 3 ? 'gap-y-4' : 'gap-y-16'
          } my-5`}
        >
          {orders.map((order, index) => (
            <div ref={index === orders.length - 1 ? lastItemRef : null}>
              <CardOrder
                order={order}
                key={order.id}
                goOffers={() => navigate(`/orders/${order.id}/offers`)}
                goStatistics={() => navigate(`/orders/${order.id}/statistics`)}
                onClick={() => navigate(`/orders/${order.id}`)}
              />
            </div>
          ))}
        </div>
        {loading && (
          <div className="flex justify-center bg-[#D7E2FF69] h-[100px] items-center">
            <Spinner
              color="success"
              aria-label="Extra large spinner example"
              size="xl"
            />
          </div>
        )}
        {!hasMore && (
          <p className="mt-20 text-center text-[#3E0292]">
            {t('myOrders.loadingAllOrders')}
          </p>
        )}
        {/* <MConfirmation
          loading={loading}
          openModal={openFilter}
          showFooter={false}
          setOpenModal={setOpenFilter}
          title={t('filterModal.filterWith')}
          content={
            <FilterOrder
              from="normal"
              close={() => setOpenFilter(false)}
              confirm={fetchOrders}
              hideStatus={userRole == 3 ? true : false}
              hideType={userRole == 3 ? false : true}
            />
          }
          singleButton={false}
          confirm={fetchOrders}
        /> */}

        <CompleteProfileModal
          isOpen={showProfileModal}
          onClose={() => setShowProfileModal(false)}
          onConfirm={() => {
            setShowProfileModal(false);
            navigate('/profile');
          }}
        />
      </div>
    </div>
  );
};

export default List;
